import { BACKEND_CONTENT_TYPES } from '~api/content/types';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

export const useFooter = () => {
  const { localized } = useTranslation();
  const { isLaptop, isMobileOrTablet } = useMedia();
  const { menuContents } = useAppSelector((state) => state.userUIState);

  return {
    contentMenus: menuContents[BACKEND_CONTENT_TYPES.FOOTER],
    isMobileOrTablet,
    isLaptop,
    localized,
  };
};
