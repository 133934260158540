import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BannerGroup, ContentMenus, MenuContents } from '~api/content/types';

export interface UserUIState {
  isBalanceVisible: boolean;
  isKeyboardVisible: boolean;
  betslipFooterHeight: number;
  bannerData: Record<string, BannerGroup>;
  menuContents: Record<string, ContentMenus>;
  personalProfileUpdateError: string | null;
}

const initialState: UserUIState = {
  isBalanceVisible: true,
  isKeyboardVisible: false,
  betslipFooterHeight: 0,
  bannerData: {},
  menuContents: {},
  personalProfileUpdateError: null,
};

export const userUISlice = createSlice({
  name: 'userUI',
  initialState,
  reducers: {
    setBalanceVisibility: (state, action: PayloadAction<boolean>) => {
      state.isBalanceVisible = action.payload;
    },
    setKeyboardVisibility: (state, action: PayloadAction<boolean>) => {
      state.isKeyboardVisible = action.payload;
    },
    setBetslipFooterHeight: (state, action: PayloadAction<number>) => {
      state.betslipFooterHeight = action.payload;
    },
    setBannerData: (
      state,
      action: PayloadAction<Record<string, BannerGroup>>,
    ) => {
      state.bannerData = {
        ...state.bannerData,
        ...action.payload,
      };
    },
    setMenuContents: (state, action: PayloadAction<MenuContents>) => {
      action.payload.forEach(({ name, columns }) => {
        state.menuContents[name] = columns;
      });
    },
    setPersonalProfileUpdateError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.personalProfileUpdateError = action.payload;
    },
  },
});

export const {
  setBalanceVisibility,
  setKeyboardVisibility,
  setMenuContents,
  setBannerData,
  setPersonalProfileUpdateError,
} = userUISlice.actions;

export default userUISlice.reducer;
