import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'stitches.config';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { MobileTab } from '~components/molecules/MobileTab';
import { Header, MobileHeaderContainer } from '~components/organisms/Header';
import { MobileContentContainer } from '~components/organisms/MobileContentContainer';
import { MobileFooter } from '~components/organisms/MobileFooter';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useShare } from '~hooks/useShare';
import { AppRoutes } from '~routes';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { getParamFromQuery } from '~utils/url';

const MobileLayoutContainer = styled(Box, {
  height: '100%',
});

export const MobileLayout = () => {
  const { name } = useQueryParams();
  const { search } = useLocation();

  useShare();
  const { removeQueryParams } = useRouterQuery();
  const dispatch = useAppDispatch();
  const { launchUrl } = useAppSelector((state) => state.games);
  const [, secondSegment] = location.pathname.split('/');
  const verifyEmailToken = getParamFromQuery(
    search,
    QUERY_PARAMS.VERIFY_EMAIL_TOKEN,
  );

  useEffect(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken]);

  useEffect(() => {
    removeQueryParams([QUERY_PARAMS.NEXT_HOUR]);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  if (secondSegment && !secondSegment.length) {
    return (
      <>
        <Header noBottomBorder />
        <AppRoutes />
        <MobileFooter />
      </>
    );
  }

  return (
    <>
      <MobileLayoutContainer>
        <MobileHeaderContainer>
          {!launchUrl && <Header noBottomBorder />}
        </MobileHeaderContainer>
        <MobileContentContainer noOverflow={!!launchUrl}>
          <AppRoutes />
        </MobileContentContainer>
        {!launchUrl && <MobileTab />}
      </MobileLayoutContainer>
    </>
  );
};
