import React, { forwardRef, MouseEvent } from 'react';
import * as DialogUI from '@radix-ui/react-dialog';
import { styled } from 'stitches.config';

import { CloseIcon } from '~icons';
import { zIndex } from '~utils/zIndex';

const IconButton = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  position: 'absolute',
  boxSizing: 'border-box',
  top: '$1',
  right: '$1',
  p: '$2',
  fontFamily: 'inherit',
  fontSize: '$xxs',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$grayMedium',
  cursor: 'pointer',
  zIndex: 1,
  '&:hover path': {
    stroke: '$white',
  },
  '@xs_sm': {
    top: '$3',
    right: '$2',
    zIndex: zIndex.composition.modal.fixed + 1,
    '& svg': {
      width: '$5',
      height: '$5',
    },
  },
});

const DialogOverlay = styled(DialogUI.Overlay, {
  backgroundColor: 'rgba($black, .7)',
  position: 'fixed',
  zIndex: zIndex.modalBackdrop,
  inset: 0,
  backdropFilter: 'blur(4px)',
  variants: {
    top: {
      true: {
        zIndex: zIndex.modal + 1,
      },
    },
  },
});

export const DialogTrigger = DialogUI.Trigger;

export const DialogClose = styled(DialogUI.Close, {
  variants: {
    isNotFullScreen: {
      true: {
        '@xs_sm': {
          display: 'none',
        },
      },
    },
  },
});

const DialogStyledContent = styled(DialogUI.Content, {
  display: 'block',
  position: 'absolute',
  margin: '0 auto',
  left: 0,

  maxWidth: 'fit-content',
  height: 'initial',
  zIndex: zIndex.modal,
  backgroundColor: '$dialogBgColor',
  border: '1px solid $dialogBorderColor',
  borderRadius: '$10',
  pointerEvents: 'none',
  '&:focus': { outline: 'none' },
  '@md_lg_xl': {
    margin: 0,
  },
  '@xs_sm': {
    position: 'fixed',
    inset: 0,
    maxWidth: '100vw',
    maxHeight: '100vh',
    width: '100vw',
    height: '100vh',
    borderRadius: 0,
    border: 'none',
    margin: 0,
  },
  variants: {
    verticalPosition: {
      start: {
        top: 0,
      },
      center: {
        '@md_lg_xl': {
          width: '100%',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
      end: {
        bottom: 0,
      },
    },
    top: {
      true: {
        zIndex: zIndex.modal + 2,
      },
    },
    isNotFullScreen: {
      true: {
        '@xs_sm': {
          position: 'fixed',
          top: '46px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          maxWidth: 'calc(100vw - 32px)',
          width: 'calc(100vw - 32px)',
          height: 'max-content',
          borderRadius: '$10',
          border: 'none',
          margin: 0,
        },
      },
    },
  },
  defaultVariants: {
    verticalPosition: 'center',
  },
});

type VerticalPositionProps = React.ComponentProps<
  typeof DialogStyledContent
>['verticalPosition'];

interface DialogContentProps {
  children: React.ReactNode;
  hasOverlay?: boolean;
  id?: string;
  title?: string;
  verticalPosition?: VerticalPositionProps;
  isNotAbleToClose?: boolean;
  isNotFullScreen?: boolean;
  top?: boolean;
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (
    {
      id,
      children,
      hasOverlay,
      verticalPosition,
      isNotAbleToClose,
      isNotFullScreen,
      top,
    }: DialogContentProps,
    forwardRef,
  ) => (
    <DialogUI.Portal>
      {hasOverlay && <DialogOverlay top={top} />}
      <DialogStyledContent
        id={id}
        ref={forwardRef}
        verticalPosition={verticalPosition}
        isNotFullScreen={isNotFullScreen}
        top={top}
      >
        {isNotAbleToClose ? null : (
          <DialogClose
            asChild
            isNotFullScreen={isNotFullScreen}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
            }}
          >
            <IconButton aria-label="Close">
              <CloseIcon />
            </IconButton>
          </DialogClose>
        )}
        {children}
      </DialogStyledContent>
    </DialogUI.Portal>
  ),
);

interface DialogProps {
  open?: boolean;
  children?: React.ReactNode;
  toggleDialog?: (isOpen: boolean) => void;
}

export const Dialog = ({ open, toggleDialog, children }: DialogProps) => {
  const opOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      document.body.style.cssText = 'position:static !important';
    }

    if (toggleDialog) {
      toggleDialog(isOpen);
    }
  };

  return (
    <DialogUI.Root onOpenChange={opOpenChange} open={open} modal>
      {children}
    </DialogUI.Root>
  );
};

Dialog.displayName = 'Dialog';
