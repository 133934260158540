import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';

export interface ModalsState {
  content: DIALOGS | null;
  isOpen: boolean;
}

const initialState: ModalsState = {
  content: null,
  isOpen: false,
};

export const globalDialogsSlice = createSlice({
  name: 'globalDialogs',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<DIALOGS>) => {
      state.content = action.payload;
      state.isOpen = true;
    },
    closeDialog: (state) => {
      state.content = null;
      state.isOpen = false;
    },
    toggleDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
      if (!action.payload) state.content = null;
    },
  },
});

export const { openDialog, closeDialog, toggleDialog } =
  globalDialogsSlice.actions;

export default globalDialogsSlice.reducer;
