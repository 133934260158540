import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';

export const SignUp = () => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();

  return (
    <Button
      css={{
        p: '$2',
        width: '160px',
        '@xs': {
          p: '$1 0',
          width: '$13',
          borderRadius: '$4',
        },
      }}
      onClick={() => dispatch(openDialog(DIALOGS.SIGN_UP))}
    >
      <Text
        level={isMobileOrTablet ? '12-20' : '18-24'}
        color="buttonTextColor"
        fontWeight="bold"
        textAlign="center"
      >
        {localized('buttons.register')}
      </Text>
    </Button>
  );
};
