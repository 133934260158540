import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { Select } from '~components/atoms/Select';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { useTransactionHistoryFilter } from '../hooks/useTransactionHistoryFilter';

export const TransactionHistoryFilter = () => {
  const { localized } = useTranslation();
  const {
    transactionStatus,
    transactionType,
    range,
    statusesOptions,
    typesOptions,
    minimumAge,
    onRangeChange,
    onTransactionStatusChange,
    onTransactionTypeChange,
    onReset,
    onApply,
  } = useTransactionHistoryFilter();

  return (
    <Box flexRow justifyContentBetween alignCenter>
      <Box flexRow gap={2}>
        <Box flexRow gap={2}>
          <DateRangePicker
            isFutureDatesDisabled
            legalAge={minimumAge}
            onOkRange={(dates) => onRangeChange(dates)}
            value={range}
          />
        </Box>
        <Select
          placeholder={localized('transactionHistory.transactionTypeLabel')}
          value={transactionType?.toString() || ''}
          isCustomContentWidth
          contentWidth={100}
          onChange={(value) => onTransactionTypeChange(value as string)}
          options={typesOptions}
          css={{
            borderRadius: '$8',
            backgroundColor: '$blueDark',
          }}
        />
        <Select
          placeholder={localized('transactionHistory.transactionStatusLabel')}
          isCustomContentWidth
          contentWidth={100}
          value={transactionStatus?.toString() || ''}
          onChange={(value) => onTransactionStatusChange(value as string)}
          options={statusesOptions}
          css={{
            borderRadius: '$8',
            backgroundColor: '$blueDark',
          }}
        />
      </Box>
      <Box flexRow gap={2}>
        <Button
          variant="secondary"
          size="small"
          css={{
            width: '100px',
            height: '32px',
            backgroundColor: '$historyFiltersResetButtonDesktopBgColor',
          }}
          onClick={onReset}
        >
          <Text level="14-16" fontWeight="bold" textAlign="center">
            {localized('transactionHistory.button.reset')}
          </Text>
        </Button>
        <Button
          size="small"
          css={{ width: '100px', height: '32px' }}
          onClick={onApply}
        >
          <Text
            level="14-16"
            fontWeight="bold"
            textAlign="center"
            color="buttonTextColor"
          >
            {localized('transactionHistory.button.show')}
          </Text>
        </Button>
      </Box>
    </Box>
  );
};
