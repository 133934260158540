import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Games } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';
import {
  GameCard,
  GameCardVariant,
} from '~components/molecules/Games/GameCard';
import { useMedia } from '~hooks/useMedia';
import { getSkeletonPlaceholders } from '~utils/arrayHelpers';
import {
  getDesktopColumnSpan,
  getMobileColumnSpan,
  getTabletColumnSpan,
} from '~utils/games';

import { StyledGameWrapperSkeleton } from './styled.components';

interface GamesGridProps {
  isLoading?: boolean;
  games: Games;
  tabletLayout?: boolean;
  mobileLayout?: boolean;
  laptopLayout?: boolean;
  desktopLayout?: boolean;
  largeDesktopLayout?: boolean;
  isFromSearch?: boolean;
  gameCardVariant?: GameCardVariant;
  withScrollBatch?: boolean;
}

export const GamesGrid = ({
  games,
  isLoading,
  largeDesktopLayout,
  desktopLayout,
  laptopLayout,
  mobileLayout,
  tabletLayout,
  isFromSearch,
  gameCardVariant,
  withScrollBatch,
}: GamesGridProps) => {
  const {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isLargeDesktop,
    isMobileOrTablet,
  } = useMedia();
  const batchSize = isMobileOrTablet ? 20 : 50;

  const [renderedGames, setRenderedGames] = useState<Games>(
    withScrollBatch ? [] : games,
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const renderedGamesLimit = useRef(0);

  useEffect(() => {
    if (!withScrollBatch) return;

    renderNextBatch();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [withScrollBatch]);

  const renderNextBatch = useCallback(() => {
    const gamesLength = games.length;

    if (renderedGamesLimit.current === gamesLength) return;

    const renderedGamesNewLimit = renderedGamesLimit.current + batchSize;

    if (renderedGamesNewLimit >= gamesLength) {
      setRenderedGames(games);
      renderedGamesLimit.current = gamesLength;

      return;
    }

    setRenderedGames(games.slice(0, renderedGamesNewLimit));
    renderedGamesLimit.current = renderedGamesNewLimit;
  }, [games, renderedGames.length]);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const windowHeight = window.innerHeight;
    const bottomPosition = containerRef.current.getBoundingClientRect().bottom;

    if (bottomPosition <= windowHeight) {
      renderNextBatch();
    }
  };

  const getColumnSpan = (index: number) => {
    if (mobileLayout) return getMobileColumnSpan(index);
    if (laptopLayout) return getDesktopColumnSpan(index);
    if (tabletLayout) return getTabletColumnSpan(index);
    if (desktopLayout || largeDesktopLayout) return getDesktopColumnSpan(index);
    if (isMobile) return getMobileColumnSpan(index);
    if (isTablet) return getTabletColumnSpan(index);
    if (isLaptop) return getTabletColumnSpan(index);
    if (isDesktop || isLargeDesktop) return getDesktopColumnSpan(index);

    return 1;
  };

  const getGridTemplateColumns = () => {
    if (mobileLayout) return 'repeat(2, minmax(104px, 1fr))';
    if (tabletLayout) return 'repeat(4, minmax(136px, 1fr))';
    if (laptopLayout) return 'repeat(7, minmax(120px, 1fr))';
    if (desktopLayout) return 'repeat(7, minmax(128px, 1fr))';
    if (largeDesktopLayout) return 'repeat(7, minmax(130px, 1fr))';
    if (isMobile) return 'repeat(3, minmax(104px, 1fr))';
    if (isTablet) return 'repeat(5, minmax(136px, 1fr))';
    if (isLaptop) return 'repeat(5, minmax(136px, 1fr))';
    if (isDesktop) return 'repeat(8, minmax(128px, 1fr))';
    if (isLargeDesktop) return 'repeat(8, minmax(130px, 1fr))';

    return '';
  };

  const partialGamesList = useMemo(
    () =>
      renderedGames.map((game, index) => (
        <GameCard
          key={game.id}
          variant={gameCardVariant}
          game={game}
          colSpan={getColumnSpan(index)}
          isFromSearch={isFromSearch}
        />
      )),
    [renderedGames, gameCardVariant, getColumnSpan, isFromSearch],
  );

  const skeletonPlaceholders = useMemo(() => {
    return getSkeletonPlaceholders(56).map((_, index) => (
      <StyledGameWrapperSkeleton key={index} data-col={getColumnSpan(index)}>
        <Skeleton
          css={{
            width: '100%',
            height: '100%',
          }}
        />
      </StyledGameWrapperSkeleton>
    ));
  }, [getSkeletonPlaceholders, getColumnSpan]);

  return (
    <Box
      ref={containerRef}
      css={{
        display: 'grid',
        gridTemplateColumns: getGridTemplateColumns(),
        gap: '$2',
      }}
    >
      {isLoading ? skeletonPlaceholders : partialGamesList}
    </Box>
  );
};
