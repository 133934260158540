import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authApi } from '~api/auth/authApi';
import { bonusApi } from '~api/bonus/bonusApi';
import { contentApi } from '~api/content/contentApi';
import { emailApi } from '~api/email/emailApi';
import { flutterwaveApi } from '~api/flutterwave/flutterwaveApi';
import { gamesApi } from '~api/games/gamesApi';
import { marketApi } from '~api/market/marketApi';
import { partnerApi } from '~api/partner/partnerApi';
import { paystackApi } from '~api/paystack/paystackApi';
import { relworxApi } from '~api/relworx/relworxApi';
import { transactionApi } from '~api/transaction/transactionApi';
import { userApi } from '~api/user/userApi';
import bonusesReducer from '~store/slices/bonusesSlice';
import breadcrumbReducer from '~store/slices/breadcrumbSlice';
import gamesReducer from '~store/slices/gamesSlice';
import globalDialogReducer from '~store/slices/globalDialogSlice';
import historyReducer from '~store/slices/historySlice';
import mobileReducer from '~store/slices/mobileSlice';
import paymentAccountsReducer from '~store/slices/paymentAccountsSlice';
import paymentsReducer from '~store/slices/paymentsSlice';
import personalDetailsReducer from '~store/slices/personalDetailsSlice';
import settingsReducer from '~store/slices/settingsSlice';
import signalRSocketsReducer from '~store/slices/signalRSocketsSlice';
import transactionHistoryReducer from '~store/slices/transactionHistorySlice';
import userReducer from '~store/slices/userSlice';
import userUIReducer from '~store/slices/userUISlice';

import SocketMiddleware from './middlewares/socketMiddleware';

const userUIPersistConfig = {
  key: 'userUI',
  storage,
};

const persistedUserUIReducer = persistReducer(
  userUIPersistConfig,
  userUIReducer,
);

const persistedPaymentsReducer = persistReducer(
  { key: 'payments', storage },
  paymentsReducer,
);

const persistedGamesReducer = persistReducer(
  { key: 'games', storage },
  gamesReducer,
);

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [marketApi.reducerPath]: marketApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [bonusApi.reducerPath]: bonusApi.reducer,
    [paystackApi.reducerPath]: paystackApi.reducer,
    [flutterwaveApi.reducerPath]: flutterwaveApi.reducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [relworxApi.reducerPath]: relworxApi.reducer,
    [gamesApi.reducerPath]: gamesApi.reducer,

    userUIState: persistedUserUIReducer,
    payments: persistedPaymentsReducer,
    breadcrumb: breadcrumbReducer,
    mobileState: mobileReducer,
    userState: userReducer,
    signalRSockets: signalRSocketsReducer,
    globalDialog: globalDialogReducer,
    personalDetails: personalDetailsReducer,
    paymentAccounts: paymentAccountsReducer,
    transactionHistory: transactionHistoryReducer,
    bonuses: bonusesReducer,
    history: historyReducer,
    settings: settingsReducer,
    games: persistedGamesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      transactionApi.middleware,
      authApi.middleware,
      userApi.middleware,
      marketApi.middleware,
      emailApi.middleware,
      contentApi.middleware,
      bonusApi.middleware,
      paystackApi.middleware,
      flutterwaveApi.middleware,
      partnerApi.middleware,
      relworxApi.middleware,
      gamesApi.middleware,
      SocketMiddleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
