import React, { useRef, useState } from 'react';

import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { useMedia } from '~hooks/useMedia';
import { useOuterClick } from '~hooks/useOuterClick';
import { useTranslation } from '~hooks/useTranslation';
import { CloseIcon, CopyToClipboardIcon, GreenCheckIcon } from '~icons';

export const BetslipBalancesPopover = ({
  betSlipCodes,
}: {
  betSlipCodes: number[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { copiedVal, isCopied, handleCopy } = useCopyToClipboard();
  const popoverRef = useRef(null);

  useOuterClick(popoverRef, () => {
    setIsOpen(false);
  });

  return (
    <Box flexRow>
      <Popover isOpen={isOpen}>
        <PopoverContent
          ref={popoverRef}
          noArrow={isMobileOrTablet}
          side="bottom"
          sideOffset={isMobileOrTablet ? 0 : 16}
          align="center"
          tone="secondary"
          width={isMobileOrTablet ? '100vw' : 300}
          css={{
            mr: '14px',
            p: '$4',
            '@xs_sm': {
              mr: 'unset',
              background: 'rgba(10, 15, 20, 0.80)',
              backdropFilter: 'blur(4px)',
              borderRadius: '$12',
            },
          }}
        >
          <Box
            flexCol
            css={{
              '@xs_sm': {
                p: '$4',
                background: '$grayDark',
                borderRadius: '$8',
                position: 'relative',
              },
            }}
          >
            <Box flexCol gap={3}>
              <Box
                flexRow
                css={{
                  position: 'absolute',
                  top: '$3',
                  right: '$7',
                  color: '$grayMedium',
                }}
                onClick={() => setIsOpen(false)}
              >
                <CloseIcon />
              </Box>

              <Text level="14-20" textTransform="uppercase">
                {localized('bonuses.betsIds')}
              </Text>
              <Box flexCol gap={1} fullWidth>
                {betSlipCodes.map((betSlipCode) => (
                  <Box
                    key={betSlipCode.toString()}
                    flexRow
                    justifyContentBetween
                    alignCenter
                    css={{
                      p: '$2 $3',
                      borderRadius: '$6',
                      background: '$grayMain',
                    }}
                  >
                    <Text level="14-20">{betSlipCode}</Text>
                    <Box
                      css={{
                        display: 'flex',
                        cursor: 'pointer',
                        color: '$grayMedium',
                        width: '$3',
                      }}
                      onClick={() => {
                        handleCopy(betSlipCode.toString());
                      }}
                    >
                      {isCopied && copiedVal === betSlipCode.toString() ? (
                        <GreenCheckIcon />
                      ) : (
                        <CopyToClipboardIcon />
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </PopoverContent>
        <PopoverTrigger asChild>
          <Box
            onClick={() => setIsOpen(!isOpen)}
            flexRow
            alignCenter
            justifyCenter
            css={{
              cursor: 'pointer',
              width: '$4',
              height: '$4',
              background: isOpen ? '$white' : '$grayMedium',
              borderRadius: '50%',
              lineHeight: 0,
            }}
          >
            <Text level="13-16" color="grayDark">
              {betSlipCodes.length}
            </Text>
          </Box>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};
