import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useLaunchGameDemo,
  useLaunchGameReal,
} from '~api/games/gamesMutations';
import { LaunchGamePayload } from '~api/games/types';
import { useAppDispatch, useAppSelector } from '~store';
import { setIsDemo, setLaunchUrl } from '~store/slices/gamesSlice';
import { customEvent } from '~utils/customEvent';
import { isUUID } from '~utils/uuid';

export const useLaunchGame = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { launchUrl, isDemo, isFromSearch } = useAppSelector(
    (state) => state.games,
  );
  const { isUserLoggedIn, isUserProfileLoaded } = useAppSelector(
    (state) => state.userState,
  );
  const { launchGameDemoMutation } = useLaunchGameDemo();
  const { launchGameRealMutation } = useLaunchGameReal();

  const launchGame = async (
    payload: LaunchGamePayload,
    isDemoProp?: boolean,
  ) => {
    try {
      let url;

      console.log('123');
      if (!isDemoProp || !isDemo) {
        const { launchUrl } = await launchGameRealMutation(payload).unwrap();

        url = launchUrl;
      } else {
        const { launchUrl } = await launchGameDemoMutation(payload).unwrap();

        url = launchUrl;
      }

      if (url.length) {
        dispatch(setLaunchUrl(url));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const locationPath = location.pathname;
    const gameQuery = locationPath.split('/').pop();
    const payload: LaunchGamePayload = {
      depositUrl: window.location.origin + '/#deposit',
      homeUrl: window.location.origin,
      isFromSearch,
    };

    if (!gameQuery || !isUserProfileLoaded) return;

    if (isUUID(gameQuery)) {
      payload.gameId = gameQuery;
    } else {
      dispatch(setIsDemo(!isUserLoggedIn));
      payload.gameUrlPath = gameQuery;
    }

    launchGame(payload, true);
  }, [isUserLoggedIn, isUserProfileLoaded, isDemo, isFromSearch]);

  useEffect(() => {
    const handleLogout = () => {
      window.location.href = window.location.origin;
    };

    customEvent.on('logout', handleLogout);

    return () => {
      customEvent.off('logout', handleLogout);
    };
  }, []);

  return {
    launchUrl,
  };
};
