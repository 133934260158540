import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { StyledFilterButton } from '~components/molecules/GamesFilters/styled.components';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { FilteredIcon, FilterIcon, ProvidersIcon, SearchIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setFilteredGames,
  setFilterType,
  setSelectedCategoryId,
} from '~store/slices/gamesSlice';
import { openDialog } from '~store/slices/globalDialogSlice';
import { FILTER_TYPE } from '~types/games';

export const FilterButtons = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobileOrTablet, isLaptop } = useMedia();
  const { filteredGames } = useAppSelector((state) => state.games);
  const isFiltered = filteredGames.length > 0;

  const handleFilterClick = (key: FILTER_TYPE) => {
    if (isFiltered) {
      dispatch(setSelectedCategoryId(null));
      dispatch(setFilteredGames([]));
    } else {
      dispatch(openDialog(DIALOGS.GAMES_FILTERS));
      dispatch(setFilterType(key));
    }
  };

  return (
    <Box
      flexCol={!isMobileOrTablet && !isLaptop}
      flexRow={isLaptop || isMobileOrTablet}
      alignCenter
      justifyCenter
      gap={4}
      fullWidth
      css={{
        minWidth: 0,
        '@md': {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          pt: '$4',
        },
        '@sm': {
          pt: '$4',
        },
      }}
    >
      <StyledFilterButton
        css={{
          px: '$4',
          width: '100%',
        }}
        onClick={() => handleFilterClick(FILTER_TYPE.SEARCH)}
      >
        <SearchIcon />
        <Text
          css={{
            overflowX: 'auto !important',
          }}
          level="16-28"
        >
          {localized(`casino.${FILTER_TYPE.SEARCH}`)}
        </Text>
      </StyledFilterButton>
      <Box
        fullWidth
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '$4',
        }}
      >
        <StyledFilterButton
          isActive={isFiltered}
          onClick={() => handleFilterClick(FILTER_TYPE.FILTERS)}
        >
          {isFiltered ? <FilteredIcon /> : <FilterIcon />}
          <Text level="16-28">
            {localized(
              !isFiltered
                ? `casino.${FILTER_TYPE.FILTERS}`
                : 'casino.clearFilter',
            )}
          </Text>
        </StyledFilterButton>
        <StyledFilterButton
          onClick={() => handleFilterClick(FILTER_TYPE.PROVIDERS)}
        >
          <ProvidersIcon />
          <Text level="16-28">
            {localized(`casino.${FILTER_TYPE.PROVIDERS}`)}
          </Text>
        </StyledFilterButton>
      </Box>
    </Box>
  );
};
