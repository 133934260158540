import React from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { CategoriesList } from '~components/molecules/GamesFilters/CategoriesList';
import { FilterButtons } from '~components/molecules/GamesFilters/FilterButtons';
import { GamesFiltersMobile } from '~components/molecules/GamesFilters/GamesFiltersMobile';
import { useMedia } from '~hooks/useMedia';

export const GamesFilters = () => {
  const { isDesktop, isLargeDesktop, isMobile, isLaptop, isTablet } =
    useMedia();

  if (isMobile) return <GamesFiltersMobile />;

  return (
    <Box
      fullWidth
      flexRow
      alignCenter
      justifyCenter
      css={{
        background: '$grayDark',
        borderRadius: '$10',
        p: '$3',
      }}
    >
      <Box
        flexRow={isDesktop || isLargeDesktop}
        flexCol={!isDesktop && !isLargeDesktop}
        alignCenter
        fullWidth
        justifyContentBetween
        css={{
          background: '$blueDark',
          borderRadius: '$8',
          gap: '30px',
          px: '30px',
          '@md': {
            flexDirection: 'column-reverse',
            gap: '0',
          },
          '@sm': {
            flexDirection: 'column-reverse',
            gap: '0',
          },
          '@lg': {
            px: '50px',
            gap: '50px',
          },
          '@xl': {
            px: '50px',
            gap: '50px',
          },
        }}
      >
        <CategoriesList />
        {(isLaptop || isTablet) && (
          <Separator
            verticalSpace={0}
            css={{
              mt: '20px',
              background: '$grayDarker',
              width: 'calc(100% + 60px)',
              ml: '-1px',
              mb: '1rem',
            }}
          />
        )}
        {(isDesktop || isLargeDesktop) && (
          <Box
            css={{
              width: '1px',
              height: '184px',
              borderRight: '1px solid $grayDarker',
            }}
          />
        )}
        <FilterButtons />
      </Box>
    </Box>
  );
};
