import { Fragment } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useBreadcrumbEmptyCallback } from '~hooks/useBreadcrumbEmptyCallback';
import { ArrowLeftIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import { BreadcrumbItem } from '~types/breadcrumb';

interface BreadcrumbProps {
  maxItemsDisplayed?: number;
  separator?: string;
  onBackPress?: () => void;
}

export const Breadcrumb = ({
  maxItemsDisplayed = 2,
  separator = '/',
  onBackPress,
}: BreadcrumbProps) => {
  const dispatch = useAppDispatch();
  const { handleEmptyBreadcrumbClick } = useBreadcrumbEmptyCallback();
  const fullBreadcrumbs = useAppSelector((state) => state.breadcrumb.trail);
  const breadcrumbs = fullBreadcrumbs.slice(-maxItemsDisplayed);
  const lastElementIndex = breadcrumbs.length - 1;

  const handleBreadcrumbClick = (breadcrumb: BreadcrumbItem, index: number) => {
    const { forceCallback, onClickCallback = () => {} } = breadcrumb;

    if (forceCallback) {
      onClickCallback();

      return;
    }

    if (index < lastElementIndex) {
      const newTrail = fullBreadcrumbs.slice(
        0,
        fullBreadcrumbs.length - (lastElementIndex - index),
      );

      dispatch(setBreadcrumbTrail(newTrail));

      if (onClickCallback) {
        onClickCallback();
      } else {
        handleEmptyBreadcrumbClick();
      }
    }

    onBackPress && onBackPress();
  };

  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <Box
      flexRow
      alignCenter
      gap={2}
      css={{
        color: '$grayMedium',
        backgroundColor: '$blackOpacity8',
        backdropFilter: 'blur(4px)',
        p: '$4',
      }}
    >
      <ArrowLeftIcon
        onClick={() => handleBreadcrumbClick(breadcrumbs[0]!, 0)}
      />
      {breadcrumbs.map(({ icon: Icon, ...breadcrumb }, index) => (
        <Fragment key={breadcrumb.label}>
          {Icon ? (
            <Box
              css={{ height: '20px', svg: { width: 'auto', height: '100%' } }}
            >
              <Icon onClick={() => handleBreadcrumbClick(breadcrumb, index)} />
            </Box>
          ) : (
            <>
              <Text
                as="span"
                color={index === lastElementIndex ? 'white' : 'grayMedium'}
                level="14-20"
                textTransform="uppercase"
                onClick={() => handleBreadcrumbClick(breadcrumb, index)}
                ellipsis
              >
                {breadcrumb.label}
              </Text>
              {index < lastElementIndex && (
                <Text as="span" level="14-20">
                  {separator}
                </Text>
              )}
            </>
          )}
        </Fragment>
      ))}
    </Box>
  );
};
