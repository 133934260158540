import React, { forwardRef } from 'react';
import * as ScrollAreaUI from '@radix-ui/react-scroll-area';
import { styled } from 'stitches.config';

import { useMedia } from '~hooks/useMedia';

import { Box } from './Box';

const SCROLLBAR_SIZE = 4;

const ScrollAreaRoot = styled(ScrollAreaUI.Root, {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const ScrollAreaViewport = styled(ScrollAreaUI.Viewport, {
  width: '100%',
  height: '100%',
  '& > div': {
    display: 'block !important',
  },
});

const ScrollAreaScrollbar = styled(ScrollAreaUI.Scrollbar, {
  display: 'flex',
  userSelect: 'none',
  touchAction: 'none',
  p: 0,
  background: 'transparent',
  transition: 'background 160ms ease-out',
  boxSizing: 'unset',
  zIndex: 2,
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },
  '&.noScrollbar': {
    display: 'none',
  },
});

const ScrollAreaThumb = styled(ScrollAreaUI.Thumb, {
  flex: 1,
  background: '$grayMedium', // scrollbar thumb color
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: 'relative',
  '&:before': {
    // commented out content due to overlapping with an accordion chevron
    // content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 44,
    minHeight: 44,
  },
  zIndex: 1,
});

const ScrollAreaCorner = styled(ScrollAreaUI.Corner, {
  background: 'transparent', // the corner square where horizontal and vertical scrollbars intersect
});

interface ScrollAreaProps {
  children: React.ReactNode;
  noScrollbar?: boolean;
  orientation?: 'vertical' | 'horizontal';
  id?: string;
  onScroll?: (val: number) => void;
}

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
  ({ children, onScroll, id, noScrollbar = false, ...props }, ref) => {
    const { isMobileOrTablet } = useMedia();

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
      const target = e.target as HTMLElement;

      if (onScroll) {
        onScroll(target.scrollTop);
      }
    };

    return (
      <>
        {isMobileOrTablet ? (
          <Box id={id}>{children}</Box>
        ) : (
          <ScrollAreaRoot {...props}>
            <ScrollAreaViewport onScroll={handleScroll} ref={ref} id={id}>
              <Box>{children}</Box>
            </ScrollAreaViewport>
            <ScrollAreaScrollbar
              orientation="vertical"
              className={noScrollbar ? 'noScrollbar' : ''}
            >
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            <ScrollAreaScrollbar
              orientation="horizontal"
              className={noScrollbar ? 'noScrollbar' : ''}
            >
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            <ScrollAreaCorner />
          </ScrollAreaRoot>
        )}
      </>
    );
  },
);
