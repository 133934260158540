import { useEffect } from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { QUERY_PARAMS, REF_CODE_KEY } from '~constants/common';
import { useHash } from '~hooks/useHash';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';

export const useShare = () => {
  const dispatch = useAppDispatch();
  const { removeQueryParams } = useRouterQuery();
  const { ref } = useQueryParams();

  const hash = useHash();

  useEffect(() => {
    const [hashVal, refVal] = hash.split('?');

    if (hashVal === '#register') {
      dispatch(openDialog(DIALOGS.SIGN_UP));
    }

    if (refVal && refVal.length) {
      const ref = refVal.split('=')[1];

      localStorage.setItem(REF_CODE_KEY, ref as string);

      removeQueryParams([QUERY_PARAMS.REF], true);
    }
  }, [hash]);

  useEffect(() => {
    if (ref && ref.length) {
      localStorage.setItem(REF_CODE_KEY, ref);
      removeQueryParams([QUERY_PARAMS.REF]);
    }
  }, [ref]);
};
