import { useEffect, useMemo, useState } from 'react';

import { BonusQueryParams } from '~api/bonus/types';
import { RangeValue } from '~components/atoms/DateRangePicker';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import {
  formatDateToEndOfDayISOString,
  formatDateToStartOfDayISOString,
} from '~utils/formatDateTime';
import { conditionalAdd } from '~utils/objectHelpers';

import { getLocalizedBonusHistoryStatusOptions } from '../../constants';
import { useBonuses } from '../../useBonuses';

export const useBonusHistoryFilters = () => {
  const { localized } = useTranslation();
  const { minimumAge } = useAppSelector((state) => state.settings);
  const { loadBonuses } = useBonuses();

  const statusesOptionsMemo = useMemo(
    () => getLocalizedBonusHistoryStatusOptions(localized),
    [],
  );
  const [statusValue, setStatusValue] = useState<string>(
    statusesOptionsMemo[0]!.value,
  );
  const [selectedDates, setSelectedDates] = useState<RangeValue | null>(null);
  const [activeParamsNumber, setActiveParamsNumber] = useState(0);
  const dateFrom = selectedDates && selectedDates[0];
  const dateTo = selectedDates && selectedDates[1];

  const handleReset = () => {
    setStatusValue(statusesOptionsMemo[0]!.value);
    setSelectedDates(null);
    loadBonuses({});
    setActiveParamsNumber(0);
  };

  const handleUpdateHistory = () => {
    const params: BonusQueryParams = {};
    const parsedStatus = parseInt(statusValue);

    conditionalAdd(
      params,
      'Status',
      parsedStatus > 0 ? parsedStatus : undefined,
    );

    if (selectedDates && dateFrom && dateTo) {
      const dateFromISO = formatDateToStartOfDayISOString(dateFrom);
      const dateToISO = formatDateToEndOfDayISOString(dateTo);

      conditionalAdd(params, 'DateFrom', dateFromISO);
      conditionalAdd(params, 'DateTo', dateToISO);
    }

    setActiveParamsNumber(
      // DateTo is not included in the number of active params because it is second part of one range params pair
      Object.keys(params).length - ('DateTo' in params ? 1 : 0),
    );

    loadBonuses({ queryParams: params });
  };

  useEffect(() => {
    loadBonuses({});
  }, []);

  return {
    activeParamsNumber,
    selectedDates,
    statusesOptions: statusesOptionsMemo,
    statusValue,
    minimumAge,
    handleReset,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  };
};
