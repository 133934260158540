import { useContext, useEffect, useRef, useState } from 'react';

import { UserSessionsResponse } from '~api/user/types';
import { useTerminateOtherSessions } from '~api/user/userMutations';
import { useLazyGetUserSessions } from '~api/user/userQueries';
import {
  SharedRefContext,
  SharedRefContextProps,
} from '~contexts/SharedRefContext';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';

const SESSIONS_PAGE_LIMIT = 20;
const PAGE_STEP = 1;

export const useUserSessions = () => {
  const { localized } = useTranslation();
  const { lazyGetUserSessionsQuery } = useLazyGetUserSessions();
  const [userSessionsData, setUserSessionsData] =
    useState<UserSessionsResponse | null>(null);
  const { activeSessions, previousSessions } = userSessionsData || {};
  const [page, setPage] = useState(PAGE_STEP);
  const [hasMoreSessions, setHasMoreSessions] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { terminateOtherSessionsMutation } = useTerminateOtherSessions();
  const context = useContext(SharedRefContext) as SharedRefContextProps;
  const { isMobileOrTablet } = useMedia();

  const isScrolledToBottom = (element: HTMLDivElement) => {
    return element.scrollHeight - element.scrollTop <= element.clientHeight + 5;
  };

  const loadSessions = async () => {
    try {
      const { data } = await lazyGetUserSessionsQuery({
        limit: SESSIONS_PAGE_LIMIT,
        page,
      });

      if (data) {
        if (
          !data.previousSessions ||
          data.previousSessions.length < SESSIONS_PAGE_LIMIT ||
          !data.activeSessions ||
          data.activeSessions.length < SESSIONS_PAGE_LIMIT
        ) {
          setHasMoreSessions(false);
        }

        setUserSessionsData((prevData) => {
          if (!prevData) return data;

          return {
            activeSessions: [
              ...(prevData.activeSessions || []),
              ...(data.activeSessions || []),
            ],
            previousSessions: [
              ...(prevData.previousSessions || []),
              ...(data.previousSessions || []),
            ],
          };
        });
      }
    } catch (error) {
      console.error('Unable to load sessions', error);
    }
  };

  const handleTerminateOtherSessions = async () => {
    try {
      await terminateOtherSessionsMutation();
      setUserSessionsData(null);
      await loadSessions();
    } catch (error) {
      console.error('Unable to terminate other sessions', error);
    }
  };

  useEffect(() => {
    const handleDirectScroll = (event: Event) => {
      const element = event.target as HTMLDivElement;

      if (isScrolledToBottom(element)) {
        setPage((prevPage) => prevPage + PAGE_STEP);
      }
    };

    let current: HTMLDivElement | null = null;

    if (isMobileOrTablet) {
      const { ref: dialogMobileContentWrapperRef } = context;

      current = dialogMobileContentWrapperRef.current;
    } else {
      current = scrollRef.current;
    }

    if (current) {
      current.addEventListener('scroll', handleDirectScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleDirectScroll);
      }
    };
  }, [setPage]);

  useEffect(() => {
    if (hasMoreSessions) {
      loadSessions();
    }
  }, [page]);

  return {
    localized,
    activeSessions,
    previousSessions,
    handleTerminateOtherSessions,
    scrollRef,
  };
};
