import {
  BetRadarGameProviderIcon,
  DarwinGameProviderIcon,
  EvolutionGameProviderIcon,
  EvoplayGameProvider,
  EzugiGameProviderIcon,
  NetEntGameProviderIcon,
  RedTigerGameProviderIcon,
  SpribeGameProviderIcon,
  WazdanGameProviderIcon,
} from '~icons/providers';
import { ProviderMapObject } from '~types/providers';

export enum GAME_PROVIDERS {
  Spribe = 1, // aviator
  BetRadar = 2, // virtual sports
  Ezugi = 4,
  NetEnt = 5,
  RedTiger = 6,
  Evolution = 7,
  EvoPlay = 8,
  Darwin = 9,
  SoftConstruct = 10,
  Endorphina = 11,
  Playson = 12,
  Wazdan = 13,
  Habanero = 14,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const PROVIDERS_MAP: Record<GAME_PROVIDERS, ProviderMapObject> = {
  [GAME_PROVIDERS.Spribe]: {
    id: GAME_PROVIDERS.Spribe,
    name: 'Spribe',
    icon: SpribeGameProviderIcon,
    iconPath: '/providers/spribe.svg',
  },
  [GAME_PROVIDERS.BetRadar]: {
    id: GAME_PROVIDERS.BetRadar,
    name: 'BetRadar',
    icon: BetRadarGameProviderIcon,
    iconPath: '/providers/betradar.svg',
  },
  [GAME_PROVIDERS.Ezugi]: {
    id: GAME_PROVIDERS.Ezugi,
    name: 'Ezugi',
    icon: EzugiGameProviderIcon,
    iconPath: '/providers/ezugi.svg',
  },
  [GAME_PROVIDERS.NetEnt]: {
    id: GAME_PROVIDERS.NetEnt,
    name: 'NetEnt',
    icon: NetEntGameProviderIcon,
    iconPath: '/providers/netent.svg',
  },
  [GAME_PROVIDERS.RedTiger]: {
    id: GAME_PROVIDERS.RedTiger,
    name: 'Red Tiger',
    icon: RedTigerGameProviderIcon,
    iconPath: '/providers/red-tiger.svg',
  },
  [GAME_PROVIDERS.Evolution]: {
    id: GAME_PROVIDERS.Evolution,
    name: 'Evolution Gaming',
    icon: EvolutionGameProviderIcon,
    iconPath: '/providers/evolution-gaming.svg',
  },
  [GAME_PROVIDERS.EvoPlay]: {
    id: GAME_PROVIDERS.EvoPlay,
    name: 'Evoplay',
    icon: EvoplayGameProvider,
    iconPath: '/providers/evoplay.svg',
  },
  [GAME_PROVIDERS.Darwin]: {
    id: GAME_PROVIDERS.Darwin,
    name: 'Darwin',
    icon: DarwinGameProviderIcon,
    iconPath: '/providers/darwin.svg',
  },
  [GAME_PROVIDERS.Wazdan]: {
    id: GAME_PROVIDERS.Wazdan,
    name: 'Wazdan',
    icon: WazdanGameProviderIcon,
    iconPath: '/providers/wazdan.svg',
  },
};
