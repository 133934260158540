import { MENU_ICON_TYPES } from '~components/organisms/Header/useHeaderMenu';

export const getIsMenuItemActive = (
  item: MENU_ICON_TYPES,
  url: string,
): boolean => {
  switch (item) {
    case MENU_ICON_TYPES.Inplay:
    case MENU_ICON_TYPES.Sports: {
      return window.location.href.includes(url.split('?')[1] || '');
    }

    case MENU_ICON_TYPES.Aviator: {
      const aviatorGamePath = url.split('/').pop();

      return window.location.pathname.includes(aviatorGamePath || '');
    }

    case MENU_ICON_TYPES.Casino: {
      return ['/', '/casino'].includes(window.location.pathname);
    }

    default: {
      return window.location.href.includes(url);
    }
  }
};
