import { useTranslation as useI18nTranslation } from 'react-i18next';
import { TOptions } from 'i18next';

export const useTranslation = () => {
  const { t } = useI18nTranslation();
  const localized = (key: string, args?: object) => t(key, args as TOptions);
  const localizedError = (key: string, args?: object) =>
    t(key, { ns: 'error', ...args });

  return { t, localized, localizedError };
};
