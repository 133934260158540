import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useVerifyMessagerAuth } from '~api/auth/authMutations';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { GreenCheckIcon, RedCrossIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { login } from '~store/slices/userSlice';
import { setAuthCookiesData } from '~utils/cookies';

export const VerifyMessangerPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const { pathname } = location;
  const { verifyMessagerAuthMutation } = useVerifyMessagerAuth();
  const pathnameArr = pathname.split('/');
  const confirmationId = pathnameArr[pathnameArr.length - 1];

  useEffect(() => {
    const handleVerifyMessangerAuth = async () => {
      try {
        if (!confirmationId) return;
        const response = await verifyMessagerAuthMutation({
          confirmationId,
        }).unwrap();
        const { token, refreshToken, expiration } = response;

        setAuthCookiesData({ token, refreshToken, expiration });
        dispatch(login());
      } catch (e) {
        setIsSuccess(false);
        setIsLoading(false);
        console.log(e);
      }
    };

    handleVerifyMessangerAuth();
  }, [confirmationId]);

  useEffect(() => {
    navigate('/');
  }, [isUserLoggedIn]);

  if (isLoading) {
    return (
      <Box
        flexCol
        fullWidth
        gap={3}
        alignCenter
        justifyCenter
        css={{
          my: '40px',
        }}
      >
        <Loader
          css={{
            color: 'white',
            '& > svg': {
              width: '40px',
              height: '40px',
            },
          }}
        />
        <Text
          level="14-24"
          textTransform="uppercase"
          textAlign="center"
          css={{
            maxWidth: '419px',
          }}
        >
          {localized('auth.pending')}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      flexCol
      fullWidth
      gap={4}
      alignCenter
      justifyCenter
      css={{
        my: '40px',
      }}
    >
      {!isSuccess ? (
        <RedCrossIcon width={40} height={40} />
      ) : (
        <GreenCheckIcon width={40} height={40} />
      )}
      <Text
        level="14-24"
        textTransform="uppercase"
        textAlign="center"
        css={{
          maxWidth: '419px',
        }}
      >
        {localized(`auth.${isSuccess ? 'success' : 'error'}`)}
      </Text>
    </Box>
  );
};
