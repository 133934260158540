import React, { memo, ReactNode, useMemo } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Logo } from '~components/atoms/Logo/Logo';
import { PureIconButton } from '~components/atoms/PureIconButton';
import { Separator } from '~components/atoms/Separator';
import { TimeDisplay } from '~components/atoms/TimeDisplay';
import { SignIn } from '~components/molecules/Authentication/components/sign-in/SignIn';
import { SignUp } from '~components/molecules/Authentication/components/sign-up/SignUp';
import { UserProfileBar } from '~components/molecules/UserProfile/components/UserProfileBar/UserProfileBar';
import { HeaderMenu } from '~components/organisms/Header/HeaderMenu';
import { CURRENCY_CODES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { SettingsIcon } from '~icons';
import { useAppSelector } from '~store';

const StyledHeader = styled('header', {
  maxWidth: '1920px',
  width: '100%',
  fontFamily: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridArea: 'header',
  backgroundColor: '$headerBgColor',
  p: '10px $5',
  '@xs_sm': {
    p: '10px $4',
    borderBottom: '1px solid $headerBottomBorder',
  },
  variants: {
    noBottomBorder: {
      true: {
        borderBottom: 'none',
      },
    },
    inModal: {
      true: {
        backgroundColor: '$modalHeaderBgColor',
        '@xs_sm': {
          p: '10px  52px 10px $4 !important',
          borderBottom: '1px solid $headerBottomBorder',
        },
      },
    },
  },
});

interface HeaderProps {
  children?: ReactNode;
  showSettings?: boolean;
  noBottomBorder?: boolean;
  inModal?: boolean;
}

export const Header = memo(
  ({
    children,
    showSettings = false,
    noBottomBorder = false,
    inModal = false,
  }: HeaderProps) => {
    const { currency } = useAppSelector((state) => state.settings);
    const isUganda = useMemo(() => currency === CURRENCY_CODES.UGX, [currency]);
    const { isUserLoggedIn } = useAppSelector((state) => state.userState);
    const { isMobileOrTablet, isLaptop } = useMedia();

    const logoCss = useMemo(
      () => ({
        width: 'unset',
        height: 'unset',
        '@md_lg_xl': {
          width: !isUganda ? '216px' : '264px',
          height: '64px',
        },
        '@xs_sm': {
          width: '92px',
          height: '26px',
        },
        '@xxs': {
          width: '56px',
          height: '16px',
        },
      }),
      [isUganda],
    );

    return (
      <Box
        css={{
          backgroundColor: '$headerBgColor',
        }}
        flexRow
        justifyCenter
        fullWidth
      >
        <StyledHeader noBottomBorder={noBottomBorder} inModal={inModal}>
          <Box
            flexCol
            fullWidth
            css={{
              gap: isLaptop ? '10px' : '$3',
            }}
          >
            <Box flexRow justifyContentBetween>
              <Box
                flexRow
                css={{
                  gap: '60px',
                }}
                alignCenter
                justifyContentStart
              >
                <Logo type="header" css={logoCss} />
              </Box>
              {!isUserLoggedIn ? (
                <Box flexRow alignCenter gap={2}>
                  <SignIn />
                  <SignUp />
                  {!isMobileOrTablet && <TimeDisplay />}
                </Box>
              ) : (
                <UserProfileBar />
              )}
              {showSettings && (
                <>
                  {isMobileOrTablet && (
                    <PureIconButton
                      css={{
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '$grayMedium',
                        p: '$2',
                        top: '$3',
                        right: '$2',
                      }}
                    >
                      <SettingsIcon width={20} height={20} />
                    </PureIconButton>
                  )}
                </>
              )}
              {children}
            </Box>
            {!isMobileOrTablet && (
              <>
                <Separator
                  css={{ backgroundColor: '$gray' }}
                  verticalSpace={0}
                  shrinkOut={5}
                />
                <HeaderMenu />
              </>
            )}
          </Box>
        </StyledHeader>
      </Box>
    );
  },
);
