import { useState } from 'react';
import copy from 'copy-to-clipboard';

export interface ShareData {
  title?: string;
  text?: string;
  url?: string;
}

export const useCopyToClipboard = () => {
  const [copiedVal, setCoppiedVal] = useState<string | undefined>(undefined);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (value: string | undefined) => {
    if (!value) return;
    setCoppiedVal(value);
    copy(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleCopyId = (id: string) => (e: React.MouseEvent) => {
    handleCopy(id);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleShare = async (shareData: ShareData) => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      console.log('Web Share API not supported');
    }
  };

  return { copiedVal, isCopied, handleCopy, handleShare, handleCopyId };
};
