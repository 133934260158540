import React from 'react';

import { TabsList } from '~components/atoms/Tabs';
import { useTranslation } from '~hooks/useTranslation';

import { USER_PROFILE_TABS } from '../UserProfileDialog';

export const userProfileTabs = [
  USER_PROFILE_TABS.PERSONAL_DETAILS,
  USER_PROFILE_TABS.ACCOUNT_SETTINGS,
  USER_PROFILE_TABS.RECENT_SESSIONS,
];

export const UserProfileTabList = () => {
  const { localized } = useTranslation();

  return (
    <TabsList
      tabs={userProfileTabs}
      ariaLabel={localized('userProfile.personalProfileInfo.ariaLabel')}
      isCapitalized
    />
  );
};
