import { Route, Routes } from 'react-router-dom';

import { NotFoundPage } from '~components/atoms/NotFoundPage';
import { VerifyMessangerPage } from '~components/VerifyMessangerPage';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { CasinoPage } from '~pages/CasinoPage';
import { GamePage } from '~pages/GamePage';
import { InfoPage } from '~pages/InfoPage';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTE_TAB_NAMES.BASE} element={<CasinoPage />} />
      <Route path={ROUTE_TAB_NAMES.GAMES} element={<CasinoPage />} />
      <Route path={ROUTE_TAB_NAMES.LIVE} element={<CasinoPage isLive />} />
      <Route path={ROUTE_TAB_NAMES.GAME} element={<GamePage />} />
      <Route
        path={ROUTE_TAB_NAMES.VERIFY_MESSANGER}
        element={<VerifyMessangerPage />}
      />
      <Route
        path={ROUTE_TAB_NAMES.VIRTUAL}
        element={<CasinoPage isVirtual />}
      />
      <Route path={ROUTE_TAB_NAMES.INFO} element={<InfoPage />} />
      <Route path={ROUTE_TAB_NAMES.NOT_FOUND} element={<NotFoundPage />} />
      <Route
        path={ROUTE_TAB_NAMES.VERIFY_MESSANGER}
        element={<VerifyMessangerPage />}
      />
    </Routes>
  );
};
