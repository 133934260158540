import { Box } from '~components/atoms/Box';
import { StyledTabsContent, StyledTabsRoot } from '~components/atoms/Tabs';
import { RecentSessionsContent } from '~components/molecules/UserProfile/components/RecentSessionsContent/RecentSessionsContent';
import { useAppDispatch, useAppSelector } from '~store';
import { setAddingNew } from '~store/slices/paymentsSlice';
import { setContentTab } from '~store/slices/personalDetailsSlice';
import { ContentsMap } from '~types/general';

import { AccountSettingsContent } from '../AccountSettingsContent';
import { PersonalDetailsContent } from '../PersonalDetailsContent';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_TABS,
} from '../UserProfileDialog/constants';
import { UserProfileTabList } from '../UserProfileTabList';

const tabsContentMap: ContentsMap = {
  [USER_PROFILE_TABS.PERSONAL_DETAILS]: <PersonalDetailsContent />,
  [USER_PROFILE_TABS.ACCOUNT_SETTINGS]: <AccountSettingsContent />,
  [USER_PROFILE_TABS.RECENT_SESSIONS]: <RecentSessionsContent />,
};

export const UserPersonalProfileWrapper = () => {
  const dispatch = useAppDispatch();
  const { contentTab = USER_PROFILE_TABS.PERSONAL_DETAILS } = useAppSelector(
    (state) => state.personalDetails,
  );

  const getTabComponent = () => {
    const content = tabsContentMap[contentTab as USER_PAYMENT_TABS];

    return (
      <StyledTabsContent value={contentTab as USER_PROFILE_TABS}>
        {content}
      </StyledTabsContent>
    );
  };

  return (
    <StyledTabsRoot
      onValueChange={(value) => {
        dispatch(setContentTab(value as USER_PAYMENT_TABS));
        dispatch(setAddingNew(false));
      }}
      value={contentTab as string}
      fullHeight
    >
      <Box flexCol gap={2} fullHeight>
        <UserProfileTabList />
        {getTabComponent()}
      </Box>
    </StyledTabsRoot>
  );
};
