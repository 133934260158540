import React, { useEffect, useRef, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { ADD_PAYMENT_ACCOUNT_FORM_STATUSES } from '~constants/payments';
import { useTranslation } from '~hooks/useTranslation';
import { AlertIcon, ClockIcon, GreenCheckIcon, RedCrossIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import {
  setAddingNewPaymentMethodFormStatus,
  setTransactionStatus,
} from '~store/slices/paymentsSlice';
import { TRANSACTION_STATUS } from '~types/transactions';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const PaymentDone = () => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);

  const {
    paymentMethodAmount,
    transactionStatus,
    transactionErrorStatusCode,
    updatedTransactionId,
    transactionId,
  } = useAppSelector((state) => state.payments);
  const [status, setStatus] = useState<TRANSACTION_STATUS>(
    TRANSACTION_STATUS.PENDING,
  );
  const { paymentSettings: paymentPartnerSettings } = useAppSelector(
    (state) => state.settings,
  );
  const intervalRef = useRef<NodeJS.Timeout>();

  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const dispatch = useAppDispatch();

  const isWithdrawalRequest =
    contentTab !== USER_PAYMENT_TABS.DEPOSIT &&
    +paymentMethodAmount > paymentPartnerSettings.withdrawalLimitMax;

  useEffect(() => {
    if (transactionStatus && updatedTransactionId === transactionId) {
      setStatus(transactionStatus);
    }

    if (transactionId === 'notProcessed' || !transactionId?.length) {
      setStatus(TRANSACTION_STATUS.FAILED);
    }
  }, [updatedTransactionId, transactionId, transactionStatus]);

  const getIcon = () => {
    if (isWithdrawalRequest) {
      return (
        <Box css={{ color: '$white' }}>
          <AlertIcon width={40} height={40} />
        </Box>
      );
    }

    switch (status) {
      case TRANSACTION_STATUS.SUCCESS:
        return <GreenCheckIcon width={40} height={40} />;
      case TRANSACTION_STATUS.PENDING:
        return (
          <Box css={{ color: '$yellow' }}>
            <ClockIcon width={40} height={40} />
          </Box>
        );
      case TRANSACTION_STATUS.FAILED:
        return <RedCrossIcon width={40} height={40} />;
      default:
        return null;
    }
  };

  const getTitle = () => {
    if (isWithdrawalRequest) {
      return 'withdrawalLimits.titleWithdrawalRequest';
    }

    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      switch (status) {
        case TRANSACTION_STATUS.SUCCESS:
          return 'cardDepositSuccess.title';
        case TRANSACTION_STATUS.PENDING:
          return 'depositPending.title';
        case TRANSACTION_STATUS.FAILED:
          return 'cardDepositError.title';
        default:
          return '';
      }
    } else {
      if (transactionErrorStatusCode) {
        switch (transactionErrorStatusCode) {
          case 520:
            return 'withdrawError.title520';
          case 521:
            return 'withdrawError.title521';
        }
      }

      switch (status) {
        case TRANSACTION_STATUS.SUCCESS:
          return 'withdrawSuccess.title';
        case TRANSACTION_STATUS.PENDING:
          return 'withdrawalPending.title';
        case TRANSACTION_STATUS.FAILED:
          return 'withdrawError.title';
        default:
          return '';
      }
    }
  };

  const getDescription = () => {
    if (isWithdrawalRequest) {
      return 'withdrawalLimits.descriptionWithdrawalRequest';
    }

    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      switch (status) {
        case TRANSACTION_STATUS.SUCCESS:
          return 'cardDepositSuccess.description';
        case TRANSACTION_STATUS.PENDING:
          return 'depositPending.description';
        case TRANSACTION_STATUS.FAILED:
          return 'cardDepositError.description';
        default:
          return '';
      }
    } else {
      if (transactionErrorStatusCode) {
        switch (transactionErrorStatusCode) {
          case 520:
            return 'withdrawError.description520';
          case 521:
            return 'withdrawError.description521';
          case 522:
            return 'withdrawError.description522';
        }
      }

      switch (status) {
        case TRANSACTION_STATUS.SUCCESS:
          return 'withdrawSuccess.description';
        case TRANSACTION_STATUS.PENDING:
          return 'withdrawalPending.description';
        case TRANSACTION_STATUS.FAILED:
          return 'withdrawError.description';
        default:
          return '';
      }
    }
  };

  const onContinue = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }

    dispatch(setTransactionStatus(TRANSACTION_STATUS.PENDING));
    dispatch(
      setAddingNewPaymentMethodFormStatus(
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
      ),
    );

    if (status === TRANSACTION_STATUS.SUCCESS) {
      dispatch(closeDialog());
    }
  };

  return (
    <Box flexCol justifyCenter alignCenter fullWidth gap={4}>
      {getIcon()}
      <Text
        css={{
          fontSize: '$17',
          lineHeight: '$24',
        }}
        textTransform="uppercase"
      >
        {localized(getTitle())}
      </Text>
      <Text textAlign="center" level="14-20" css={{ marginTop: '-$2' }}>
        {localized(getDescription(), {
          code: CURRENCY_SYMBOLS[currency],
          amount: commaSeparatedNumber(+paymentMethodAmount),
        })}
      </Text>
      <Button variant="secondary" fullWidth onClick={onContinue}>
        {localized('buttons.continue')}
      </Button>
    </Box>
  );
};
