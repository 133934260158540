import {
  BalanceManagementIcon,
  BetHistoryIcon,
  BonusesIcon,
  UserProfileIcon,
} from '~icons';
import { SVGElement } from '~types/general';

import { userBonusesTabs } from '../components/UserBonusesTabs';
import { userPaymentsTabs } from '../components/UserPaymentsTabs';
import { USER_PROFILE_NAVIGATION_TABS } from '../components/UserProfileDialog/constants';
import { userProfileTabs } from '../components/UserProfileTabList';

export interface NavigationItem {
  icon: SVGElement;
  title: string;
  value: string;
  subItems?: NavigationItem[];
  onClick?: () => void;
}

export const navigationItems: NavigationItem[] = [
  {
    icon: UserProfileIcon,
    title: 'userProfile.menuTitles.personalProfile',
    value: USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE,
  },
  {
    icon: BalanceManagementIcon,
    title: 'userProfile.menuTitles.balanceManagement',
    value: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
  },
  {
    icon: BetHistoryIcon,
    title: 'userProfile.menuTitles.transactions',
    value: USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY,
  },
  {
    icon: BonusesIcon,
    title: 'userProfile.menuTitles.bonuses',
    value: USER_PROFILE_NAVIGATION_TABS.BONUSES,
  },

  // Temporary hidden
  // {
  //   icon: MessagesIcon,
  //   title: 'userProfile.menuTitles.messages',
  //   value: USER_PROFILE_NAVIGATION_TABS.MESSAGES,
  // },
];

export const userProfileMobileMenuMap = {
  [USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE]: userProfileTabs,
  [USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT]: userPaymentsTabs,
  [USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY]: null,
  [USER_PROFILE_NAVIGATION_TABS.BONUSES]: userBonusesTabs,
};
