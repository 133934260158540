import { useChangeDefaultBalance } from '~api/user/userMutations';
import { BalanceInfo } from '~components/atoms/BalanceInfo';
import { Box } from '~components/atoms/Box';
import {
  RadioGroupRoot,
  StyledRadioGroupItem,
  StyledRadioGroupItemIndicator,
} from '~components/atoms/RadioGroup';
import { useTranslation } from '~hooks/useTranslation';
import {
  setDefaultBalance,
  USER_DEFAULT_BALANCE,
} from '~store/slices/userSlice';
import { useAppDispatch, useAppSelector } from '~store/store';

import {
  StyledBalanceInfoText,
  StyledBalanceInfoWrapper,
} from './styled.components';

export const UserProfileSidebarBalances = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { defaultBalance, profile } = useAppSelector(
    (state) => state.userState,
  );
  const { changeDefaultBalanceMutation } = useChangeDefaultBalance();
  const { balance, freeBetBalance } = profile || {
    balance: 0,
    freeBetBalance: 0,
  };

  const onDefaultBalanceChange = async (value: string) => {
    const newTypeValue = parseInt(value) as USER_DEFAULT_BALANCE;

    dispatch(setDefaultBalance(newTypeValue));

    try {
      await changeDefaultBalanceMutation(newTypeValue).unwrap();
    } catch (error) {
      console.error('Error during changing preferred balance type', error);
    }
  };

  return (
    <RadioGroupRoot
      value={defaultBalance.toString()}
      onValueChange={onDefaultBalanceChange}
    >
      <Box flexCol gap={2}>
        <StyledBalanceInfoWrapper>
          <Box flexRow gap={3} alignCenter>
            <StyledRadioGroupItem
              className="RadioGroupItem"
              value={USER_DEFAULT_BALANCE.MAIN.toString()}
            >
              <StyledRadioGroupItemIndicator />
            </StyledRadioGroupItem>
            <StyledBalanceInfoText>
              {localized('userProfile.personalProfileInfo.mainBalance')}
            </StyledBalanceInfoText>
          </Box>
          <BalanceInfo balance={balance} size="small" textAlign="right" />
        </StyledBalanceInfoWrapper>
        <StyledBalanceInfoWrapper>
          <Box flexRow gap={3} alignCenter>
            <StyledRadioGroupItem
              className="RadioGroupItem"
              value={USER_DEFAULT_BALANCE.BONUS.toString()}
            >
              <StyledRadioGroupItemIndicator />
            </StyledRadioGroupItem>
            <StyledBalanceInfoText>
              {localized('userProfile.personalProfileInfo.bonusBalance')}
            </StyledBalanceInfoText>
          </Box>
          <BalanceInfo
            balance={freeBetBalance}
            size="small"
            textAlign="right"
            isBonusBalance
          />
        </StyledBalanceInfoWrapper>
      </Box>
    </RadioGroupRoot>
  );
};
