import React from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { BalanceInfo } from '~components/atoms/BalanceInfo';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { TimeDisplay } from '~components/atoms/TimeDisplay';
import { UserBalancesNavigationDropDown } from '~components/molecules/UserProfile/components/UserBalancesNavigationDropDown';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { DepositIcon, UserProfileIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';

import {
  StyledChevron,
  styledDepositButtonCSS,
  StyledUserProfileBarContainer,
  StyledUserProfileInfoContainer,
} from '../UserProfileBar';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '../UserProfileDialog/constants';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '../UserProfileDialogMobile';
import { UserProfileNavigationDropDown } from '../UserProfileNavigationDropDown';

export const UserProfileBar = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobileOrTablet, isSmallMobile, isLaptop } = useMedia();
  const { profile } = useAppSelector((state) => state.userState);
  const { balance } = profile || {};

  const handleUserProfileOpen = () => {
    dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
  };

  return (
    <>
      <Box flexRow gap={5} alignCenter>
        {!!profile && (
          <Box flexRow gap={2}>
            <StyledUserProfileBarContainer>
              <Button
                fullWidth
                css={{
                  ...styledDepositButtonCSS,
                  width: isLaptop ? '136px' : styledDepositButtonCSS.width,
                  '@xxs': {
                    width: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: '$1',
                  },
                }}
                onClick={() => {
                  if (!isMobileOrTablet) {
                    dispatch(openDialog(DIALOGS.USER_PROFILE));
                    dispatch(
                      setTabsState({
                        mainTab:
                          USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
                        contentTab: USER_PAYMENT_TABS.DEPOSIT,
                      }),
                    );
                  } else {
                    dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
                    dispatch(
                      setMobileUserProfileDialogState(
                        USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
                      ),
                    );
                    dispatch(
                      setMobileUserProfileDialogActiveContent(
                        USER_PAYMENT_TABS.DEPOSIT,
                      ),
                    );
                  }
                }}
              >
                {isSmallMobile ? (
                  <DepositIcon width={24} height={24} />
                ) : (
                  localized('userProfile.personalProfileInfo.deposit')
                )}
              </Button>
              {isMobileOrTablet ? (
                <StyledUserProfileInfoContainer
                  as="span"
                  onClick={handleUserProfileOpen}
                >
                  <Box flexRow css={{ color: '$white' }}>
                    <UserProfileIcon width={16} height={16} />
                  </Box>
                  <BalanceInfo balance={balance} />
                </StyledUserProfileInfoContainer>
              ) : (
                <UserBalancesNavigationDropDown>
                  <StyledUserProfileInfoContainer as="span">
                    <BalanceInfo balance={balance} />
                    <Box flexRow css={{ width: '$3', height: '7px' }}>
                      <StyledChevron className="chevron" />
                    </Box>
                  </StyledUserProfileInfoContainer>
                </UserBalancesNavigationDropDown>
              )}
            </StyledUserProfileBarContainer>
            {!isMobileOrTablet && (
              <StyledUserProfileBarContainer>
                <UserProfileNavigationDropDown>
                  <StyledUserProfileInfoContainer as="span">
                    <Box flexRow css={{ color: '$white' }}>
                      <UserProfileIcon width={24} height={24} />
                    </Box>
                    <Box flexRow css={{ width: '$3', height: '7px' }}>
                      <StyledChevron className="chevron" />
                    </Box>
                  </StyledUserProfileInfoContainer>
                </UserProfileNavigationDropDown>
              </StyledUserProfileBarContainer>
            )}
          </Box>
        )}
        {!isMobileOrTablet && <TimeDisplay />}
      </Box>
    </>
  );
};
