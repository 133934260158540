import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';

import { UserSession } from './UserSession';
import { useUserSessions } from './useUserSessions';

const SESSION_HEIGHT = '301px';

export const UserSessions = () => {
  const {
    localized,
    activeSessions,
    previousSessions,
    handleTerminateOtherSessions,
    scrollRef,
  } = useUserSessions();

  return (
    <Box gap={3} css={{ display: 'grid', gridTemplateColumns: '1fr 1px 1fr' }}>
      <Box flexCol gap={2}>
        <Text
          level="12-20"
          fontWeight="medium"
          textTransform="uppercase"
          color="grayMedium"
          css={{ pl: '$3' }}
        >
          {localized(
            'userProfile.personalProfileInfo.accountSettings.recentSessions.activeSessions',
          )}
        </Text>
        <Box css={{ height: SESSION_HEIGHT }}>
          <ScrollArea>
            <Box flexCol gap={2}>
              <Button
                size="small"
                variant="secondary"
                fullWidth
                css={{ height: '32px', borderRadius: '$6' }}
                onClick={handleTerminateOtherSessions}
              >
                <Text level="14-20" fontWeight="medium" textAlign="center">
                  {localized(
                    'userProfile.personalProfileInfo.accountSettings.recentSessions.endAllSessions',
                  )}
                </Text>
              </Button>
              {activeSessions?.map((session, index) => (
                <UserSession key={index} session={session} />
              ))}
            </Box>
          </ScrollArea>
        </Box>
      </Box>
      <Box
        css={{
          height: `calc(100% + (2 * $2))`,
          width: '1px',
          backgroundColor: '$gray',
          marginTop: '-$2',
        }}
      />
      <Box flexCol gap={2}>
        <Text
          level="12-20"
          fontWeight="medium"
          textTransform="uppercase"
          color="grayMedium"
          css={{ pl: '$3' }}
        >
          {localized(
            'userProfile.personalProfileInfo.accountSettings.recentSessions.sessionsHistory',
          )}
        </Text>
        <Box css={{ height: SESSION_HEIGHT }}>
          <ScrollArea ref={scrollRef}>
            <Box flexCol gap={2}>
              {previousSessions?.map((session, index) => (
                <UserSession key={index} session={session} />
              ))}
            </Box>
          </ScrollArea>
        </Box>
      </Box>
    </Box>
  );
};
