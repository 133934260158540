import { styled } from 'stitches.config';

import { DOCUMENT_STATUS } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { ellipsis } from '~utils/cssUtils';

export const StyledTable = styled('table', {
  width: '100%',
  height: '100%',
  borderSpacing: 0,
});

export const StyledCell = styled('td', {
  border: 'none',
  maxWidth: '200px',
  p: 0,
  '@xs_sm': {
    maxWidth: '180px',
  },
});

export const StyledCellContent = styled(Box, {
  color: '$white',
  fontSize: '$14',
  lineHeight: '$24',
  height: '32px',
  justifyContent: 'space-between',
  background: '$blueDark',
  alignItems: 'center',
  border: 'none',
  p: '$1 $3',
  mb: '$2',
  ...ellipsis,
});

export const StyledPlaceholderCell = styled(StyledCell, {
  color: '$grayMedium',
  fontSize: '$15',
  lineHeight: '$24',
  p: '$4 0',
  background: 'transparent',
  textAlign: 'center',
  justifyContent: 'center',
  ...ellipsis,
});

export const StyledHeaderCell = styled('th', {
  color: '$grayMedium',
  fontSize: '$12',
  fontWeight: '$normal',
  lineHeight: '$20',
  textAlign: 'start',
  p: '$2 $3',
});

export const StyledTableHeader = styled('thead', {
  backgroundColor: '$grayDark',
  position: 'sticky',
  top: '-1px',
  zIndex: 1,
});

export const StyledDocumentStatusValue = styled(Text, {
  display: 'inline',
  fontSize: '$14 !important',
  lineHeight: '$24 !important',
  color: '$white !important',
  variants: {
    status: {
      [DOCUMENT_STATUS.VERIFIED]: {
        color: '$greenLight !important',
      },
      [DOCUMENT_STATUS.PENDING]: {
        color: '$grayMedium !important',
      },
      [DOCUMENT_STATUS.NOT_VERIFIED]: {
        color: '$red !important',
      },
    },
  },
});
