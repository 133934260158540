import React, { useEffect, useRef, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { Keyboard, KEYBOARD_TYPES } from '~components/atoms/Keyboard';
import { useNumberInput } from '~components/atoms/NumberInput/useNumberInput';
import { PureIconButton } from '~components/atoms/PureIconButton';
import { useMedia } from '~hooks/useMedia';
import { MinusIcon, PlusIcon } from '~icons';
import { useAppDispatch } from '~store';
import { setKeyboardVisibility } from '~store/slices/userUISlice';

const sharedActionsCss = {
  width: '$8',
  height: '$8',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

interface NumberInputProps {
  value: string;
  onChange: (value: string) => void;
  withoutActions?: boolean;
  fullWidth?: boolean;
  additionalKeyboard?: number[];
  textColor?: string;
}

export const NumberInput = ({
  onChange: onSuccess,
  fullWidth = false,
  value: initialValue,
  withoutActions = false,
  additionalKeyboard,
  textColor,
}: NumberInputProps) => {
  const { isMobileOrTablet } = useMedia();
  const numberInputRef = useRef<HTMLDivElement>();
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const { value, onIncrement, onDecrement, onChange } = useNumberInput({
    onSuccess,
    initialValue,
  });

  const dispatch = useAppDispatch();

  const onClick = () => {
    if (numberInputRef.current && isMobileOrTablet) {
      numberInputRef.current.scrollIntoView({ block: 'center' });
    }
  };

  const handleSelect = () => {
    if (isMobileOrTablet) {
      dispatch(setKeyboardVisibility(true));
      setIsKeyboardOpen(true);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setKeyboardVisibility(false));
    };
  }, []);

  return (
    <Box
      ref={numberInputRef}
      flexRow
      alignCenter
      fullWidth={fullWidth}
      css={{
        backgroundColor: '$gray',
        borderRadius: '$10',
        '@xs_sm': {
          height: '32px',
          width: fullWidth ? '100%' : '236px',
          flexGrow: 1,
        },
      }}
    >
      {!withoutActions && (
        <PureIconButton
          onClick={onDecrement}
          css={{
            ...sharedActionsCss,
            paddingRight: '$4',
            marginLeft: '$3',
            '&:before': {
              content: '',
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              width: '1px',
              height: '$4',
              backgroundColor: '$gray1',
            },
            '&:hover > svg': {
              color: '$yellow',
            },
            '@xs_sm': {
              height: '32px',
            },
          }}
        >
          <MinusIcon />
        </PureIconButton>
      )}

      <button
        onClick={onClick}
        style={{
          padding: 0,
          border: 'none',
          borderRadius: 'inherit',
          width: '100%',
          background: 'transparent',
        }}
      >
        <Input
          textCentered
          value={value === '' ? 0 : value}
          onChange={onChange}
          onSelect={handleSelect}
          readOnly={isMobileOrTablet}
          css={{
            color: textColor || '$white',
            backgroundColor: '$gray',
            fontWeight: '$medium',
            '@xs_sm': {
              height: '32px',
            },
          }}
        />
      </button>
      <Keyboard
        text={value}
        onChange={onChange}
        isOpen={isKeyboardOpen}
        setIsOpen={setIsKeyboardOpen}
        type={KEYBOARD_TYPES.NUMBER}
        additionalKeyboard={additionalKeyboard}
      />
      {!withoutActions && (
        <PureIconButton
          css={{
            ...sharedActionsCss,
            paddingLeft: '$4',
            marginRight: '$3',
            '&:before': {
              content: '',
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              width: '1px',
              height: '$4',
              backgroundColor: '$gray1',
            },
            '&:hover > svg': {
              color: '$yellow',
            },
            '@xs_sm': {
              height: '32px',
            },
          }}
          onClick={onIncrement}
        >
          <PlusIcon />
        </PureIconButton>
      )}
    </Box>
  );
};
