import React from 'react';

import { CountryName } from '~constants/flag';
import { getAlpha2Code } from '~utils/getFlagByCountryName';

interface FlagProps {
  countryName: CountryName;
  code?: string | null | undefined;
  size?: 'sm' | 'md' | 'lg';
}

export const Flag = ({ countryName, code, size = 'md' }: FlagProps) => {
  const countryCode = code ? code : getAlpha2Code(countryName);

  if (!countryCode) {
    return null;
  }

  return (
    <div
      className={`fflag fflag-${countryCode} ff-${size} ff-round`}
      title={countryName}
    />
  );
};
