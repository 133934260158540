import { useLazyGetProfileBonus } from '~api/bonus/bonusQueries';
import { ProfileBonusPayload } from '~api/bonus/types';
import { useAppDispatch } from '~store';
import { setBonuses, setIsBonusesLoading } from '~store/slices/bonusesSlice';

export const useBonuses = () => {
  const dispatch = useAppDispatch();
  const { lazyGetProfileBonusQuery } = useLazyGetProfileBonus();

  const loadBonuses = async (params: ProfileBonusPayload) => {
    try {
      dispatch(setIsBonusesLoading(true));
      const bonuses = await lazyGetProfileBonusQuery(params).unwrap();

      dispatch(setBonuses(bonuses));
    } catch (error) {
      console.error('Unable to load bonuses', error);
    } finally {
      dispatch(setIsBonusesLoading(false));
    }
  };

  return {
    loadBonuses,
  };
};
