import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';

import { AbsoluteDialogs } from '~components/atoms/AbsoluteDialogs';
import { DesktopLayout } from '~components/atoms/layouts/DesktopLayout';
import { MobileLayout } from '~components/atoms/layouts/MobileLayout';
import { useMedia } from '~hooks/useMedia';
import { useOnAppInit } from '~hooks/useOnAppInit';

registerSW({ immediate: true });

const intervalMS = 1000 * 60 * 15;

function App() {
  useOnAppInit();
  const { isMobileOrTablet } = useMedia();
  const isMobile = navigator.userAgent.indexOf('Mobi') > -1;

  useEffect(() => {
    const path = window.location.pathname;

    if (path.startsWith('//')) {
      window.location.href = '/notFound';
    }

    registerSW({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onRegistered(r) {
        r &&
          setInterval(() => {
            r.update();
          }, intervalMS);
      },
    });
  }, []);

  return (
    <Router>
      {isMobileOrTablet || isMobile ? <MobileLayout /> : <DesktopLayout />}
      <AbsoluteDialogs />
    </Router>
  );
}

export default App;
