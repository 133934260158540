import React, { createElement } from 'react';
import { Colors, colors, CSS, styled, VariantProps } from 'stitches.config';

import { ellipsis } from '~utils/cssUtils';
import {
  generateLevels,
  generateLevelsPxToRem,
} from '~utils/stitchesFontUtils';

type ParagraphProps = {
  as?: React.ElementType;
  children: React.ReactNode;
};

export const Paragraph = ({
  as = 'p',
  ...rest
}: ParagraphProps & JSX.IntrinsicElements['p']) => {
  return createElement(as, {
    ...rest,
  });
};

export const Text = styled(Paragraph, {
  margin: 0,
  p: 0,
  fontFamily: 'Poppins',
  variants: {
    color: Object.keys(colors).reduce(
      (colorVariants, colorKey) => {
        const colorVariantKey = colorKey as keyof Colors;

        colorVariants[colorVariantKey] = {
          color: `$${colorKey}`,
        };

        return colorVariants;
      },
      {} as Record<keyof Colors, CSS>,
    ),
    fontWeight: {
      normal: {
        fontWeight: '$normal',
      },
      medium: {
        fontWeight: '$medium',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
    textAlign: {
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
      left: {
        textAlign: 'left',
      },
    },
    inline: {
      true: {
        display: 'inline',
      },
    },
    lineThrough: {
      true: {
        textDecoration: 'line-through',
      },
    },
    underline: {
      true: {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    textTransform: {
      uppercase: {
        textTransform: 'uppercase',
      },
      capitalize: {
        textTransform: 'capitalize',
      },
      lowercase: {
        textTransform: 'lowercase',
      },
      none: {
        textTransform: 'none',
      },
    },
    noWrap: {
      true: {
        whiteSpace: 'nowrap',
      },
    },
    level: {
      ...{ ...generateLevels(), ...generateLevelsPxToRem() },
    },
    ellipsis: {
      true: {
        ...ellipsis,
      },
    },
  },
  defaultVariants: {
    color: 'white',
    textAlign: 'left',
    fontWeight: 'normal',
    level: '18-24',
  },
});

type errorType = 'inputError' | 'centeredError';

interface ErrorMessageProps {
  message: string;
  shown?: boolean;
  type?: errorType;
}

export const ErrorMessage = ({
  message,
  shown = true,
  type = 'inputError',
}: ErrorMessageProps) => {
  const defaultStyles = { color: 'red', level: 'xxs-4', fontWeight: 'normal' };
  const types: Record<string, VariantProps<typeof Text>> = {
    inputError: { ...(defaultStyles as VariantProps<typeof Text>) },
    centeredError: {
      ...(defaultStyles as VariantProps<typeof Text>),
      textAlign: 'center',
      level: 'xxxxs-1',
    },
  };

  return shown ? <Text {...types[type]}>{message}</Text> : null;
};
