import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'stitches.config';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Footer } from '~components/organisms/Footer';
import { Header } from '~components/organisms/Header';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useShare } from '~hooks/useShare';
import { AppRoutes } from '~routes';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { getParamFromQuery } from '~utils/url';

const DesktopLayoutContainer = styled(Box, {
  fontFamily: 'inherit',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$gray',
  gap: 0,
  '& > div:nth-child(1)': {
    position: 'sticky',
    top: 0,
    zIndex: 3,
  },
});

export const DesktopLayout = () => {
  const { name } = useQueryParams();
  const { search } = useLocation();

  useShare();
  const dispatch = useAppDispatch();

  const verifyEmailToken = getParamFromQuery(
    search,
    QUERY_PARAMS.VERIFY_EMAIL_TOKEN,
  );

  useEffect(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  return (
    <DesktopLayoutContainer>
      <Header />
      <AppRoutes />
      <Footer isInfoPage />
    </DesktopLayoutContainer>
  );
};
