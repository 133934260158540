import React, { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  iconsMap,
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/Header/useHeaderMenu';
import { useMedia } from '~hooks/useMedia';
import { getIsMenuItemActive } from '~utils/menu';

export type MenuItemProps = {
  name: string;
  url: string;
  icon: string;
  isLaptop: boolean;
  isMenuItemActive: boolean;
};

const MenuItem = memo(
  ({ name, url, icon, isLaptop, isMenuItemActive }: MenuItemProps) => {
    const Icon = iconsMap[icon as MENU_ICON_TYPES];

    return (
      <a
        style={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: isLaptop ? '10px' : '12px',
          color: isMenuItemActive ? '$yellow' : '$white',
        }}
        href={url || ''}
        key={name}
      >
        <Box
          css={{
            mt: '$1',
            color: isMenuItemActive ? '$yellow' : '$white',
          }}
        >
          {Icon && (
            <Icon width={isLaptop ? 20 : 24} height={isLaptop ? 20 : 24} />
          )}
        </Box>
        <Text
          color={isMenuItemActive ? 'yellow' : 'white'}
          css={{
            fontWeight: isMenuItemActive ? '$medium' : '$normal',
          }}
          level={isLaptop ? '14-20' : '18-24'}
        >
          {name}
        </Text>
      </a>
    );
  },
);

export const HeaderMenu = () => {
  const { menu } = useHeaderMenu();
  const { isLaptop } = useMedia();

  return (
    <Box
      flexRow
      css={{
        gap: isLaptop ? '36px' : '24px',
        '& a': {
          cursor: 'pointer',
        },
      }}
    >
      {menu.map(({ name, url = '', icon = '' }) => {
        const isMenuItemActive = getIsMenuItemActive(
          icon as MENU_ICON_TYPES,
          url || '',
        );

        return (
          <MenuItem
            key={name}
            name={name}
            url={url}
            icon={icon}
            isLaptop={isLaptop}
            isMenuItemActive={isMenuItemActive}
          />
        );
      })}
    </Box>
  );
};
