import React, { useEffect } from 'react';
import { GamesSlider } from 'src/components/molecules/GamesSlider';

import { Games } from '~api/games/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { Box } from '~components/atoms/Box';
import { GamesGrid } from '~components/molecules/Games';
import { useGamesGrid } from '~components/molecules/Games/GamesGrid/useGamesGrid';
import { GamesFilters } from '~components/molecules/GamesFilters/GamesFilters';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { useHash } from '~hooks/useHash';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  selectGames,
  selectMostLikedGames,
  selectNotEmptyCategories,
  setIsDemo,
  setIsFromSearch,
  setIsLive,
  setIsVirtual,
  setSelectedCategoryId,
} from '~store/slices/gamesSlice';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';

interface CasinoPageProps {
  isLive?: boolean;
  isVirtual?: boolean;
}

export const CasinoPage = ({ isLive, isVirtual }: CasinoPageProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const hash = useHash();
  const { gamesLoaded } = useGamesGrid();
  const { filteredGames, selectedCategoryId } = useAppSelector(
    (state) => state.games,
  );
  const games = useAppSelector(selectGames);

  const mostLikedGames = useAppSelector(selectMostLikedGames);
  const categories = useAppSelector(selectNotEmptyCategories);

  const { isMobileOrTablet, isLaptop } = useMedia();

  const slidesPerView = isMobileOrTablet ? 2 : isLaptop ? 4 : 5;
  const slideHeight = isMobileOrTablet ? 168 : isLaptop ? 324 : 304;

  let gamesArray: Games = games;

  if (selectedCategoryId) {
    gamesArray = gamesArray.filter((game) =>
      game.categoryIds.includes(selectedCategoryId),
    );
  }

  if (filteredGames.length > 0) {
    gamesArray = filteredGames;
  }

  const handleDepositOpen = () => {
    if (!isMobileOrTablet) {
      dispatch(openDialog(DIALOGS.USER_PROFILE));
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.DEPOSIT,
        }),
      );
    } else {
      dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
      dispatch(
        setMobileUserProfileDialogState(
          USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
        ),
      );
      dispatch(
        setMobileUserProfileDialogActiveContent(USER_PAYMENT_TABS.DEPOSIT),
      );
    }
  };

  useEffect(() => {
    dispatch(setIsLive(!!isLive));
    dispatch(setIsVirtual(!!isVirtual));
    dispatch(setIsDemo(false));
    dispatch(setIsFromSearch(false));
  }, [isLive, isVirtual]);

  useEffect(() => {
    if (hash === '#deposit') {
      handleDepositOpen();
      history.pushState(
        '',
        document.title,
        window.location.pathname + window.location.search,
      );
    }
  }, [hash]);

  useEffect(() => {
    const categoriesIncludesSelectedCategoryId = categories.some(
      (category) => category.id === selectedCategoryId,
    );

    if (categories.length > 0 && !categoriesIncludesSelectedCategoryId) {
      dispatch(setSelectedCategoryId(categories[0]!.id));
    }
  }, [games, categories]);

  const bannersItem = isVirtual
    ? [BANNER_GROUP_NAMES.VIRTUAL_CASINO_HEADER]
    : !isLive
    ? [BANNER_GROUP_NAMES.CASINO_HEADER]
    : [BANNER_GROUP_NAMES.LIVE_CASINO_HEADER];

  return (
    <Box flexRow justifyCenter fullWidth fullHeight>
      <Box
        fullWidth
        flexCol
        gap={5}
        css={{
          p: '$4 $5',
          maxWidth: '1920px',
        }}
      >
        <BannerList names={bannersItem} />
        {!isVirtual && (
          <>
            <GamesSlider
              games={mostLikedGames}
              slidesPerView={slidesPerView}
              slideHeight={slideHeight}
              label={localized('casino.mostLiked')}
            />
            <GamesFilters />
          </>
        )}

        <GamesGrid
          games={gamesArray}
          isLoading={!gamesLoaded}
          withScrollBatch
        />
      </Box>
    </Box>
  );
};
