import { useEffect, useState } from 'react';

import { useLazyGetWithdrawalSavedBanks } from '~api/transaction/transactionQueries';
import { ExistingPaymentMethod, SavedBank } from '~api/transaction/types';
import { Box } from '~components/atoms/Box';
import { CardMask } from '~components/atoms/CardMask';
import { FooterAction } from '~components/atoms/Select';
import { Text } from '~components/atoms/Typography';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNew,
  setSelectedPaymentMethod,
} from '~store/slices/paymentsSlice';
import { ContentTabType } from '~store/slices/personalDetailsSlice';
import { isSavedBank } from '~utils/typeguars';

const sortPayments = (a: ExistingPaymentMethod | SavedBank) => {
  return a.isDefault ? -1 : 1;
};

export const useAddBankPayment = () => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const [paymentMethods, setPaymentMethods] = useState<
    (ExistingPaymentMethod | SavedBank)[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { selectedPaymentMethod } = useAppSelector((state) => state.payments);
  const { localized } = useTranslation();
  const { lazyGetWithdrawalSavedBanksQuery } = useLazyGetWithdrawalSavedBanks();

  const handleOpenAddPayment = () => {
    dispatch(setAddingNew(true));
  };

  const handleSelectPaymentMethod = (value: string) => {
    const paymentMethod = paymentMethods?.find((item) => {
      if (!isSavedBank(item)) {
        return item.id === value;
      } else {
        return item.bankId.toString() === value;
      }
    });

    dispatch(
      setSelectedPaymentMethod(
        (paymentMethod || null) as unknown as ExistingPaymentMethod,
      ),
    );
  };

  const selectFooterAction: FooterAction = {
    label: localized('payments.addNewBank'),
    onClick: handleOpenAddPayment,
  };

  const renderOption = (option: ExistingPaymentMethod): JSX.Element => {
    const { bankName, accountNumber } = option;
    const accountLastDigits = accountNumber?.slice(-4);

    return (
      <Box
        flexRow
        justifyCenter
        alignCenter
        gap={1}
        css={isMobileOrTablet ? { paddingLeft: '$2' } : {}}
      >
        <Text level="14-20">{bankName},</Text>
        <CardMask />
        <Text level="14-20">{accountLastDigits}</Text>
      </Box>
    );
  };

  const loadPaymentMethods = async (contentTab: ContentTabType) => {
    try {
      setIsLoading(true);
      let response;

      if (contentTab === USER_PAYMENT_TABS.WITHDRAW) {
        response = await lazyGetWithdrawalSavedBanksQuery().unwrap();
      } else {
        return;
      }

      const sorted = [...response].sort(sortPayments);

      if (sorted && sorted.length) {
        setPaymentMethods(sorted);
        if (sorted[0]) {
          dispatch(setAddingNew(false));
          dispatch(
            setSelectedPaymentMethod(
              (sorted[0] || null) as unknown as ExistingPaymentMethod,
            ),
          );
        }
      } else {
        dispatch(setAddingNew(true));
        dispatch(
          setSelectedPaymentMethod(null as unknown as ExistingPaymentMethod),
        );
      }
    } catch (e) {
      console.error('Not able to load payments: ' + e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadPaymentMethods(contentTab);

    return () => {
      setPaymentMethods([]);
    };
  }, [contentTab]);

  useEffect(() => {}, []);

  return {
    isMobileOrTablet,
    isLoading,
    paymentMethods,
    isDeposit: contentTab === USER_PAYMENT_TABS.DEPOSIT,
    selectFooterAction,
    selectedPaymentMethod: selectedPaymentMethod
      ? !isSavedBank(selectedPaymentMethod)
        ? selectedPaymentMethod?.id
        : selectedPaymentMethod?.bankId.toString()
      : null,
    renderOption,
    localized,
    handleOpenAddPayment,
    handleSelectPaymentMethod,
  };
};
