import './assets/flags/freakflags.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { injectGlobalStyles } from 'stitches.config';

import { ErrorBoundary } from '~services/ErrorBoundary';
import { persistor, store } from '~store';

import i18n from './localization/i18n/config';
import App from './App';

injectGlobalStyles();

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
);
