import React from 'react';

import { AttachFileInput } from '~components/atoms/AttachFileInput';
import { Box } from '~components/atoms/Box';
import {
  StyledUserTabContentSeparator,
  StyledUserTabContentTitle,
  StyledUserTabContentWrapper,
} from '~components/atoms/TabContent';

import { UserVerificationTable } from '../../UserVerificationTable';
import { DocumentTypeSelect, DocumentUploadButton } from '../components';
import { useAccountVerificationContent } from '../hooks/useAccountVerificationContent';

import { AccountVerificationPopover } from './AccountVerificationPopover';

export const AccountVerificationContent = () => {
  const {
    localized,
    documents,
    documentType,
    documentTypeOptions,
    handleFileAttach,
    handleFileUpload,
    handleDocumentTypeChange,
    file,
  } = useAccountVerificationContent();

  return (
    <StyledUserTabContentWrapper>
      <Box>
        <Box flexRow gap={2} alignCenter>
          <StyledUserTabContentTitle>
            {localized(
              'userProfile.personalProfileInfo.accountVerification.header',
            )}
          </StyledUserTabContentTitle>
          <AccountVerificationPopover />
        </Box>
        <StyledUserTabContentSeparator />
        <Box flexRow alignCenter gap={2}>
          <Box
            css={{
              flexGrow: 1,
              flexBasis: 0,
              maxWidth: 'calc((100% - 128px) / 2 - 10px)',
            }}
          >
            <DocumentTypeSelect
              documentType={documentType}
              documentTypeOptions={documentTypeOptions}
              handleDocumentTypeChange={handleDocumentTypeChange}
            />
          </Box>
          <Box
            css={{
              flexGrow: 1,
              flexBasis: 0,
              maxWidth: 'calc((100% - 128px) / 2 - 10px)',
            }}
          >
            <AttachFileInput
              onChange={handleFileAttach}
              value={file}
              placeholder={localized(
                'userProfile.personalProfileInfo.accountVerification.documentFileUpload.placeholder',
              )}
            />
          </Box>
          <DocumentUploadButton
            documentType={documentType}
            file={file}
            handleFileUpload={handleFileUpload}
          />
        </Box>
        <StyledUserTabContentSeparator />
      </Box>
      <Box
        css={{
          height: '116px',
          position: 'relative',
        }}
      >
        <UserVerificationTable data={documents} />
      </Box>
    </StyledUserTabContentWrapper>
  );
};
