import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Separator } from '~components/atoms/Separator';

import { WarrningAndCopywriting } from '../../atoms/WarrningAndCopywriting';

import {
  StyledBottomPlaceholder,
  StyledFooter,
  StyledLinkContentMenuText,
  StyledMenuText,
} from './styled.components';
import { SubscribeEmail } from './SubscribeEmail';
import { useFooter } from './useFooter';

export const Footer = memo(({ isInfoPage }: { isInfoPage?: boolean }) => {
  const { isLaptop, contentMenus } = useFooter();

  return (
    <Box
      flexRow
      justifyCenter
      fullWidth
      fullHeight
      css={{
        display: 'grid',
        backgroundColor: '$blackMain',
      }}
    >
      <StyledFooter>
        <Box flexCol alignCenter fullWidth>
          <Box
            alignCenter
            fullWidth
            css={{
              display: 'flex',
              flexDirection: isInfoPage || isLaptop ? 'column' : 'row',
              gap: '$8',
              '@lg_xl': isInfoPage
                ? {
                    width: '1280px',
                    gap: '$3',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }
                : {},
            }}
          >
            <SubscribeEmail />
            <Box
              flexRow
              fullWidth={!isLaptop}
              css={{
                justifyContent: isLaptop ? 'space-between' : 'space-evenly',
                alignSelf: isLaptop ? 'stretch' : 'unset',
                pt: isLaptop ? '$9' : 0,
              }}
            >
              {contentMenus ? (
                contentMenus.map(({ name, items }) => (
                  <Box flexCol gap={4} key={name}>
                    <StyledMenuText>{name}</StyledMenuText>
                    <Box flexCol gap={4}>
                      {items.map(({ name, url }) => (
                        <NavigationLink to={url} key={name}>
                          <StyledLinkContentMenuText as="span">
                            {name}
                          </StyledLinkContentMenuText>
                        </NavigationLink>
                      ))}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box />
              )}
            </Box>
          </Box>
          <Separator
            shrinkOut={32}
            verticalSpace={0}
            css={{ marginTop: '60px' }}
          />
          <Box flexRow fullWidth css={{ py: '$5' }}>
            {/* a temporary block, it will be populated by backend */}
            <StyledBottomPlaceholder>
              <WarrningAndCopywriting />
            </StyledBottomPlaceholder>
          </Box>
        </Box>
      </StyledFooter>
    </Box>
  );
});
