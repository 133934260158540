import { useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { ScrollArea } from '~components/atoms/ScrollArea';
import {
  StyledUserTabContentSeparator,
  StyledUserTabContentWrapper,
} from '~components/atoms/TabContent';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

import { getLocalizedBonusHistoryColumns } from '../../constants';
import { BonusHistoryFilters } from '../BonusHistoryFilters';
import { BonusHistoryItem } from '../BonusHistoryItem';

const BONUSES_HEIGHT = '416px';

export const GAP_HISTORY_COLUMNS = 4;

export const BonusHistory = () => {
  const { localized } = useTranslation();
  const { bonuses, isBonusesLoading } = useAppSelector(
    (state) => state.bonuses,
  );
  const historyColumnsMemo = useMemo(
    () => getLocalizedBonusHistoryColumns(localized),
    [],
  );

  return (
    <Box flexCol gap={3}>
      <StyledUserTabContentWrapper>
        <BonusHistoryFilters />
        <StyledUserTabContentSeparator />
        {isBonusesLoading ? (
          <Box flexCol alignCenter gap={3} css={{ py: '32px' }}>
            <Loader css={{ color: '$grayMedium' }} />
            <Text color="grayMedium" level="20-32" fontWeight="medium">
              {localized('bonuses.loading')}
            </Text>
          </Box>
        ) : !bonuses.length ? (
          <Box flexCol alignCenter justifyCenter css={{ py: '41px' }}>
            <Text color="grayMedium" level="20-32" fontWeight="medium">
              {localized('bonuses.noBonusToShow')}
            </Text>
            <Text color="grayMedium" level="14-20">
              {localized('bonuses.pleaseFirstSelectPeriod')}
            </Text>
          </Box>
        ) : (
          <>
            <Box flexRow gap={GAP_HISTORY_COLUMNS} css={{ pl: '$3' }}>
              {historyColumnsMemo.map(({ name, width }) => {
                return (
                  <Text
                    key={name}
                    as="span"
                    level="12-20"
                    color="grayMedium"
                    textTransform="capitalize"
                    css={{ minWidth: width }}
                  >
                    {name}
                  </Text>
                );
              })}
            </Box>
            <StyledUserTabContentSeparator />
            <Box css={{ height: BONUSES_HEIGHT, pb: '$1' }}>
              <ScrollArea>
                <Box flexCol gap={2}>
                  {bonuses.map((bonus) => (
                    <BonusHistoryItem
                      key={bonus.id}
                      bonus={bonus}
                      historyColumns={historyColumnsMemo}
                    />
                  ))}
                </Box>
              </ScrollArea>
            </Box>
          </>
        )}
      </StyledUserTabContentWrapper>
    </Box>
  );
};
