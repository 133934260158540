import { useState } from 'react';

import { useDeactivateUser } from '~api/user/userMutations';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Loader } from '~components/atoms/Loader';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { CURRENCY_CODES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { DeactivateAccount } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog, openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';
import { logout } from '~store/slices/userSlice';

interface DeactivateAccountModalContentProps {
  onClose: () => void;
}

export const DeactivateAccountModalContent = ({
  onClose,
}: DeactivateAccountModalContentProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.userState);
  const { balance = 0 } = profile || {};
  const { isMobileOrTablet } = useMedia();
  const { deactivateUserMutation, deactivateUserIsLoading } =
    useDeactivateUser();
  const { currency } = useAppSelector((state) => state.settings);
  const [isDeactivated, setIsDeactivated] = useState(false);

  const handleWithdrawClick = () => {
    if (!isMobileOrTablet) {
      dispatch(openDialog(DIALOGS.USER_PROFILE));
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.WITHDRAW,
        }),
      );
    } else {
      dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
      dispatch(
        setMobileUserProfileDialogState(
          USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
        ),
      );
      dispatch(
        setMobileUserProfileDialogActiveContent(USER_PAYMENT_TABS.WITHDRAW),
      );
    }
  };

  const handleDeactivateClick = async () => {
    try {
      await deactivateUserMutation();
      setIsDeactivated(true);
    } catch (e) {
      console.error(e);
    }
  };

  const onContinue = () => {
    dispatch(logout());
    onClose();
    dispatch(closeDialog());
  };

  return (
    <Box
      flexCol
      css={{
        p: '$3',
        '@md_lg_xl': {
          width: '492px',
          p: '2rem',
        },
        button: {
          height: '40px !important',
        },
      }}
      gap={3}
    >
      <Box
        alignCenter
        flexCol
        gap={2}
        css={{
          p: '$3',
          '@xs_sm': {
            p: '$4',
            maxWidth: 'unset',
          },
          'span, p': {
            textAlign: 'center',
          },
        }}
      >
        <DeactivateAccount />
        {isDeactivated ? (
          <Text level="14-20" textTransform="uppercase">
            {localized(
              'userProfile.personalProfileInfo.accountSettings.deactivateAccount.accountDeactivated',
            )}
          </Text>
        ) : (
          <>
            <Text level="14-20" textTransform="uppercase" fontWeight="medium">
              {localized(
                'userProfile.personalProfileInfo.accountSettings.deactivateAccount.modalTitle',
              )}
            </Text>
            <Box flexCol>
              <Text level="12-20" textTransform="uppercase">
                {localized(
                  'userProfile.personalProfileInfo.accountSettings.deactivateAccount.modalDescription',
                )}
              </Text>
              <Text level="12-20" textTransform="uppercase">
                {localized(
                  'userProfile.personalProfileInfo.accountSettings.deactivateAccount.modalDescription2',
                )}
              </Text>
            </Box>
          </>
        )}
      </Box>
      {!deactivateUserIsLoading && !isDeactivated && balance ? (
        <Box
          flexCol
          alignCenter
          gap={2}
          css={{
            p: '$2',
            background: '$blueDark',
            borderRadius: '$8',
            color: '$white',
          }}
        >
          {balance ? (
            <Box
              flexCol
              gap={1}
              css={{
                p: '$2',
                textAlign: 'center',
              }}
            >
              <Text level="12-20" textAlign="center">
                {localized(
                  'userProfile.personalProfileInfo.accountSettings.deactivateAccount.remainingBalance',
                )}
              </Text>
              <Text level="12-20" fontWeight="bold" textAlign="center">
                {balance} {CURRENCY_CODES[currency]}.
              </Text>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {balance && !deactivateUserIsLoading && !isDeactivated ? (
        <Button size="large" onClick={handleWithdrawClick}>
          <Text color="buttonTextColor">{localized('buttons.withdraw')}</Text>
        </Button>
      ) : null}
      <Separator
        shrinkOut={3}
        css={{
          background: '$grayDarkMain',
          '@md_lg_xl': {
            margin: '0 -2rem',
            width: 'calc(100% + 4rem)',
          },
        }}
        verticalSpace={0}
      />
      {deactivateUserIsLoading ? (
        <Box
          flexCol
          alignCenter
          justifyCenter
          gap={3}
          css={{
            mb: '$3',
          }}
        >
          <Loader />
          <Text level="12-20">
            {localized(
              'userProfile.personalProfileInfo.accountSettings.deactivateAccount.deactivationProcessing',
            )}
          </Text>
        </Box>
      ) : !isDeactivated ? (
        <>
          <Button variant="secondary" size="large" fullWidth onClick={onClose}>
            <Text color="buttonTextSecondaryColor" level="16-20">
              {localized(
                'userProfile.personalProfileInfo.accountSettings.deactivateAccount.keepAccount',
              )}
            </Text>
          </Button>
          <Button
            size="large"
            variant="red"
            fullWidth
            onClick={handleDeactivateClick}
          >
            <Text color="red" level="16-20">
              {localized(
                'userProfile.personalProfileInfo.accountSettings.deactivateAccount.title',
              )}
            </Text>
          </Button>
        </>
      ) : (
        <Button variant="secondary" size="large" fullWidth onClick={onContinue}>
          <Text color="buttonTextSecondaryColor" level="16-20">
            {localized('buttons.continue')}
          </Text>
        </Button>
      )}
    </Box>
  );
};
