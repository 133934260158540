import type * as Stitches from '@stitches/react';
import { createStitches, createTheme } from '@stitches/react';
// eslint-disable-next-line import/no-unresolved
import { ConfigType } from '@stitches/react/types/config';

import { mediaQueriesAndCombinations } from '~constants/mediaQueries';

import spaceUtils from './src/utils/stitchesSpaceUtils';

export const measurements = {
  0: 0,
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '2.25rem', // 36px
  8: '2.5rem', // 40px
  9: '3rem', // 48px
  10: '3.5rem', // 56px
  11: '4rem', // 64px
  12: '4.5rem', // 72px
  13: '5rem', // 80px
  14: '6rem', // 96px
  15: '7rem', // 112px
};

export const fontSizesArray = [
  8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 24, 25, 28, 32, 34, 48,
];

export const lineHeightsArray = [
  12, 14, 15, 16, 20, 24, 25, 28, 32, 36, 40, 44, 52,
];

export const radiiArray = [2, 3, 4, 6, 8, 10, 12, 16];

const getStyleVariants = (array: number[]) => {
  const resSizes: Record<string, string> = {};

  array.forEach((fontSize) => {
    resSizes[fontSize] = `${fontSize / 16}rem`;
  });

  return resSizes;
};

const stitches = createStitches({
  theme: {
    colors: {
      black: '#0A0F14',
      blackMain: '#0A0F14',
      blackOpacity0: 'rgba(10, 15, 20, 0.0)',
      blackOpacity4: 'rgba(10, 15, 20, 0.4)',
      blackOpacity7: 'rgba(10, 15, 20, 0.7)',
      blackOpacity8: 'rgba(10, 15, 20, 0.8)',
      blackDark: '#11171D',
      blackDarker: '#08090D',
      grayDark: '#181E26',
      grayDarkMain: '#181E26',
      grayDarkMain2: '#181E26',
      grayDarker: '#2C3037', // rename
      gray: '#2C3540',
      grayMain: '#2C3540',
      grayOpacity8: 'rgba(44, 53, 64, 0.8)',
      gray1: '#3C434C', // rename
      gray2: '#454B52', // rename
      grayMedium: '#7A7A7A',
      grayMediumOpacity: 'rgba(122, 122, 122, 0.4)',
      white: '#E5E5E5',
      buttonTextColor: '#E5E5E5',
      buttonTextSecondaryColor: '#E5E5E5',
      whiteDark: '#A7A9AC',
      whiteOpacity4: 'rgba(229, 229, 229, 0.4)',
      yellow: '#F5CA14',
      yellowMedium: '#C9A710',
      yellowLight: '#B3940E',
      yellowDark: '#393414',
      yellowOpacity: 'rgb(245,202,20, 0.4)',
      yellowOpacity2: 'rgba(179, 148, 14, 0.24)',
      greenLight: '#318F1E',
      green: '#048531',
      greenColorIndicator: '#048531',
      greenDeep: '#037003',
      greenOpacity: 'rgba(4, 133, 49, 0.24)',
      greenDark: '#11432A',
      greenDarker: '#083E20',
      red: '#CC302B',
      redColorIndicator: '#CC302B',
      redDark: '#310D10',
      redLowLight: '#CC302B',
      orange: '#E65E15',
      blue: '#2B6BCC',
      blueDark: '#202730',
      inplayMarketEventGroupsBgColor: '#0A0F14',
      inplayMarketsAccordionContentBgColor: '#181E26',
      inplayMarketsContentBorderColor: '#181E26',
      panelBgColor: '#0A0F14',
      mainScoreHomeTeamColor: '#048531',
      mainScoreHomeTeamColorOpacity: 'rgba(4, 133, 49, 0.24)',
      mainScoreAwayTeamColor: '#C9A710',
      mainScoreAwayTeamColorOpacity: 'rgba(179, 148, 14, 0.24)',
      mainScoreBgColorOpacity: 'rgba(10, 15, 20, 0.7)',
      dialogBgColor: '#0A0F14',
      dialogBorderColor: '#181E26',
      htmlBgColor: '#2C3540',
      loadingIconColor: '#E5E5E5',
      oddBgColorHover: '#083E20',
      oddBgColorActive: '#048531',
      oddBgColorInactive: '#181E26',
      oddTextColorActive: '#E5E5E5',
      oddBgColorDisabled: '#2C3540',
      separatorBgColor: '#181E26',
      betslipFooterSeparatorBgColor: '#181E26',
      checkboxBgSelected: '#048531',
      checkboxCheckColor: '#E5E5E5',
      leagueMenuItemBgColor: '#181E26',
      headerBgColor: '#0A0F14',
      modalHeaderBgColor: '#0A0F14',
      headerBottomBorder: '#181E26',
      dialogMobileContentBgColor: '#0A0F14',
      userProfileWidgetBgColor: '#181E26',
      userProfileWidgetBgColorMobile: '#181E26',
      userProfileLogoutButtonBgColor: '#181E26',
      userProfileMobileLogoutButtonBgColor: '#181E26',
      userProfileMobileApplyButtonBgColor: '#2C3540',
      userProfileDialogBgColor: '#0A0F14',
      showMorePanelShadeColor: '#0A0F14',
      showMorePanelShadeColorOpacity: 'rgba(10, 15, 20, 0.0)',
      passwordStrengthWeakColor: '#CC302B',
      passwordStrengthAverageColor: '#E65E15',
      passwordStrengthStrongColor: '#2B6BCC',
      passwordStrengthExcellentColor: '#318F1E',
      betslipHistoryMobileItemBgColor: '#181E26',
      betslipHistoryMobileItemDetailsBgColor: '#0A0F14',
      closeFiltersButtonBgColor: '#2C3540',
      antPickerBgColor: '#202730',
      antPickerMobileBgColor: '#202730',
      selectContentBgColor: '#181E26',
      filtersInputMobileBgColor: '#202730',
      selectContentSeparatorBgColor: '#2C3540',
      userFileUploadCardRowBgColor: '#202730',
      userSessionItemBgColor: '#202730',
      openBetItemBgColor: '#181E26',
      historyFiltersResetButtonDesktopBgColor: '#2C3540',
      dropdownMenuContentBgColor: '#0A0F14',
      dropdownMenuContentBorderColor: '#181E26',
      authSecondaryButtonBgColor: '#2C3540',
      authSecondaryButtonTextColor: '#E5E5E5',
      authTabInactiveBgColor: '#7A7A7A',
      inputVerticalSeparatorColor: '#2C3037',
      authWidgetBgColor: '#181E26',
      numberCodeFieldInputBgColor: '#2C3540',
      getAgainButtonDisabledBgColor: '#04853166',
      getAgainButtonBgColor: 'rgba(4,133,49,0.4)',
      getAgainButtonTextColor: '#E5E5E5',
      getAgainButtonTextDisabledColor: 'rgba(243,243,243,0.4)',
      popoverMainBgColor: '#0A0F14',
      popoverMainBorderColor: '#181E26',
      popoverSecondaryBgColor: '#181E26',
      popoverSecondaryBorderColor: '#2C3540',
      liveEventCardBgColor: '#181E26',
      liveEventCardBgActiveColor: '#11171D',
      success: '#048531',
    },
    space: { ...measurements },
    sizes: { ...measurements },
    fontSizes: {
      nano: '0.5rem', // 8px
      xxxxs: '0.625rem', // 10px
      xxxs: '0.6875rem', // 11px
      xxs: '0.75rem', // 12px
      xs: '0.8125rem', // 13px
      sm: '0.875rem', // 14px
      sm2: '0.9375rem', // 15px
      md: '1rem', // 16px
      md2: '1.125rem', // 18px
      lg: '1.25rem', // 20px
      xl: '1.5rem', // 24px
      xxl: '1.75rem', // 28px
      xxl2: '2rem',
      xxxl: '2.125rem', // 34px
      xxxxl: '3rem', // 48px
      ...getStyleVariants(fontSizesArray),
    },
    fontWeights: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
    lineHeights: {
      // deprecated, use values from lineHeightsArray instead
      1: '0.75rem', // 12px
      2: '1rem', // 16px
      3: '1.25rem', // 20px
      4: '1.5rem', // 24px
      5: '1.75rem', // 28px
      6: '2rem', // 32px
      7: '2.25rem', // 36px
      8: '2.5rem', // 40px
      9: '3.25rem', // 52px
      //
      ...getStyleVariants(lineHeightsArray),
    },
    radii: {
      // deprecated, use values from radiiArray instead
      xxxs: '0.125rem', // 2px
      xxs: '0.25rem', // 4px
      xs: '0.375rem', // 6px
      sm: '0.5rem', // 8px
      md: '0.625rem', // 10px
      lg: '0.75rem', // 12px
      xl: '1rem', // 16px
      //
      round: '50%',
      full: '100%',
      ...getStyleVariants(radiiArray),
    },
    shadows: {
      // boxShadows
      buttonBorderNormal: 'inset 0 0 0 1px transparent',
      buttonBorderHover: 'inset 0 0 0 1px #E5E5E5',
      iconButtonBorderHover: 'inset 0 0 0 1px #3C434C',
      iconButtonBorderActive: 'inset 0 0 0 1px #3C434C',
      removeButtonBorderActive: 'inset 0 0 0 1px #D95448',
      containerBorderActive: 'inset 0 0 0 1px #048531',
      buttonBorderActive: 'inset 0 0 0 2px #E5E5E5',
      buttonBorderInactive: 'inset 0 0 0 1px #7A7A7A',
      inputBorderFocus: 'inset 0 0 0 1px #3C434C',
      progressBarShadow: 'inset 0 0 0 1px #B3940E',
      toggleMarkets: 'inset 0 0 0 1px #08090D',
      checkboxBorderColorNormal: 'inset 0 0 0 1px #7A7A7A',
      checkboxBorderColorHover: 'inset 0 0 0 1px #E5E5E5',
      checkboxBorderColorError: 'inset 0 0 0 1px #CC302B',
      checkboxBorderColorSelectedHover: 'inset 0 0 0 1px #E5E5E5',
      // textShadows
      buttonText: '0px 2px 4px rgba(10, 15, 20, 0.40)',
      generalTextShadow: '0px 2px 4px rgba(10, 15, 20, 0.40)',
      greenShadow: '0 0 0 2px $green',
    },
  },
  media: { ...mediaQueriesAndCombinations },
  utils: {
    ...(spaceUtils() as ConfigType.Utils<{ [p: string]: unknown }> | undefined),
  },
});

export const semaBetTheme = createTheme({
  colors: {
    black: '#072138',
    blackMain: '#003C75',
    blackOpacity0: 'rgba(7, 33, 56, 0.0)',
    blackOpacity4: 'rgba(7, 33, 56, 0.4)',
    blackOpacity7: 'rgba(7, 33, 56, 0.7)',
    blackOpacity8: 'rgba(7, 33, 56, 0.8)',
    blackDark: '#0E2B44',
    blackDarker: '#072138',
    grayDark: '#09243D',
    grayDarkMain: '#003C75',
    grayDarkMain2: '#093054',
    grayDarker: '#09243D',
    gray: '#1D4161',
    grayMain: '#093054',
    grayOpacity8: 'rgba(0, 60, 117, 0.8)',
    gray1: '#295987',
    gray2: '#454B52',
    grayMedium: '#879BAD',
    grayMediumOpacity: 'rgba(122, 122, 122, 0.4)',
    white: '#F3F3F3',
    buttonTextColor: '#09243D',
    whiteDark: '#A7A9AC',
    whiteOpacity4: 'rgba(229, 229, 229, 0.4)',
    yellow: '#F9F503',
    yellowMedium: '#C9A710',
    yellowLight: '#B5B202',
    yellowDark: '#3D3C0A',
    yellowOpacity: 'rgb(245,202,20, 0.4)',
    yellowOpacity2: 'rgba(179, 148, 14, 0.24)',
    greenLight: '#318F1E',
    green: '#F9F503',
    greenColorIndicator: '#0AB300',
    greenDeep: '#037003',
    greenOpacity: 'rgba(4, 133, 49, 0.24)',
    greenDark: '#003C75',
    greenDarker: '#083E20',
    red: '#D95448',
    redColorIndicator: '#D95448',
    redDark: '#47110C',
    redLowLight: '#CC302B',
    orange: '#E65E15',
    blue: '#2B6BCC',
    blueDark: '#093054',
    inplayMarketEventGroupsBgColor: '#09243D',
    inplayMarketsAccordionContentBgColor: '#093054',
    inplayMarketsContentBorderColor: '#093054',
    panelBgColor: '#09243D',
    mainScoreHomeTeamColor: '#0AB300',
    mainScoreHomeTeamColorOpacity: 'rgba(10, 179, 0, .2)',
    mainScoreAwayTeamColor: '#B5B202',
    mainScoreAwayTeamColorOpacity: 'rgba(181, 178, 2, .2)',
    mainScoreBgColorOpacity: 'rgba(4, 16, 27, 0.7)',
    dialogBgColor: '#072138',
    dialogBorderColor: '#093054',
    htmlBgColor: '#1D4161',
    loadingIconColor: '#09243D',
    oddBgColorHover: '#003C75',
    oddBgColorActive: '#F9F503',
    oddBgColorInactive: '#093054',
    oddTextColorActive: '#09243D',
    oddBgColorDisabled: '#093054',
    separatorBgColor: '#093054',
    betslipFooterSeparatorBgColor: '#1D4161',
    checkboxBgSelected: '#F9F503',
    checkboxCheckColor: '#09243D',
    leagueMenuItemBgColor: '#093054',
    headerBgColor: '#003C75',
    modalHeaderBgColor: '#09243D',
    headerBottomBorder: '#093054',
    dialogMobileContentBgColor: '#09243D',
    userProfileWidgetBgColor: '#09243D',
    userProfileWidgetBgColorMobile: '#093054',
    userProfileLogoutButtonBgColor: '#09243D',
    userProfileMobileLogoutButtonBgColor: '#003C75',
    userProfileMobileApplyButtonBgColor: '#003C75',
    userProfileDialogBgColor: '#003C75',
    showMorePanelShadeColor: '#09243D',
    showMorePanelShadeColorOpacity: 'rgba(9, 36, 61, 0.0)',
    passwordStrengthWeakColor: '#D95448',
    passwordStrengthAverageColor: '#DB8504',
    passwordStrengthStrongColor: '#0468DB',
    passwordStrengthExcellentColor: '#0AB300',
    betslipHistoryMobileItemBgColor: '#093054',
    betslipHistoryMobileItemDetailsBgColor: '#093054',
    closeFiltersButtonBgColor: '#003C75',
    antPickerBgColor: '#093054',
    antPickerMobileBgColor: '#1D4161',
    selectContentBgColor: '#1D4161',
    filtersInputMobileBgColor: '#1D4161',
    selectContentSeparatorBgColor: '#295987',
    userFileUploadCardRowBgColor: '#1D4161',
    userSessionItemBgColor: '#1D4161',
    openBetItemBgColor: '#093054',
    historyFiltersResetButtonDesktopBgColor: '#003C75',
    dropdownMenuContentBgColor: '#003C75',
    dropdownMenuContentBorderColor: '#295987',
    authSecondaryButtonBgColor: '#003C75',
    authSecondaryButtonTextColor: '#F3F3F3',
    authTabInactiveBgColor: '#1D4161',
    inputVerticalSeparatorColor: '#1D4161',
    authWidgetBgColor: '#093054',
    numberCodeFieldInputBgColor: '#003C75',
    getAgainButtonDisabledBgColor: '#003C75',
    getAgainButtonBgColor: '#F9F503',
    getAgainButtonTextColor: '#09243D',
    getAgainButtonTextDisabledColor: '#F3F3F3',
    popoverMainBgColor: '#09243D',
    popoverMainBorderColor: '#093054',
    popoverSecondaryBgColor: '#09243D',
    popoverSecondaryBorderColor: '#093054',
    liveEventCardBgColor: '#072138',
    liveEventCardBgActiveColor: '#0E2B44',
    success: '#048531',
  },
  shadows: {
    // boxShadows
    buttonBorderNormal: 'inset 0 0 0 1px transparent',
    buttonBorderHover: 'inset 0 0 0 1px #00FFFF',
    iconButtonBorderHover: 'inset 0 0 0 1px #295987',
    iconButtonBorderActive: 'inset 0 0 0 1px #295987',
    containerBorderActive: 'inset 0 0 0 1px #F9F503',
    buttonBorderActive: 'inset 0 0 0 2px #00FFFF',
    buttonBorderInactive: 'inset 0 0 0 1px #879BAD',
    inputBorderFocus: 'inset 0 0 0 1px #003C75',
    progressBarShadow: 'inset 0 0 0 1px #B3940E',
    toggleMarkets: 'inset 0 0 0 1px #08090D',
    checkboxBorderColorNormal: 'inset 0 0 0 1px #879BAD',
    checkboxBorderColorHover: 'inset 0 0 0 1px #F3F3F3',
    checkboxBorderColorError: 'inset 0 0 0 1px #CC302B',
    checkboxBorderColorSelectedHover: 'inset 0 0 0 1px #00FFFF',
    // textShadows
    buttonText: 'none', //
    generalTextShadow: '0px 2px 4px rgba(10, 15, 20, 0.40)',
  },
});

const { styled, getCssText, globalCss, theme } = stitches;

export const config = stitches.config;

// stitches types
export type StitchesConfig = typeof config;

export type Colors = typeof colors;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type VariantProps<T extends { [key: string]: any; [key: symbol]: any }> =
  Stitches.VariantProps<T>;

export type PropertyValue<T extends keyof Stitches.CSSProperties> =
  Stitches.PropertyValue<T>;

export type ScaleValue<T> = Stitches.ScaleValue<T>;

export type CSSProperties = Stitches.CSSProperties;

export type CSS = Stitches.CSS<StitchesConfig>;

export type COLOR_KEYS = keyof typeof theme.colors;

const injectGlobalStyles = () => {
  baseStyles();
  antStylesOverwrite();
};

const baseStyles = globalCss({
  '*': { boxSizing: 'border-box' },
  html: {
    backgroundColor: '$htmlBgColor',
    // '@xs_sm': {
    //   height: '100%',
    //   overflow: 'hidden',
    // },
  },
  body: {
    margin: 0,
    p: 0,
    fontFamily: 'Poppins',
  },
  'body, #root': {
    '@xs_sm': {
      height: '100%',
    },
  },
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    background: '$grayMedium',
    borderRadius: '10px',
    border: '1px solid transparent',
  },
});

const antStylesOverwrite = globalCss({
  '& .ant-picker-drp[': { display: 'none !important' },
  '& .ant-picker-dropdown': { pointerEvents: 'auto !important' },
  '.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner:before':
    {
      content: '""',
      position: 'absolute',
      top: 0,
      insetInlineEnd: 0,
      bottom: 0,
      insetInlineStart: 0,
      zIndex: 1,
      border: '1px solid transparent',
      borderRadius: '$4',
    },
  '& .rc-virtual-list-scrollbar-thumb': {
    backgroundColor: '$grayMedium !important',
  },
  '& .ant-select-selector': {
    width: '100%',
    backgroundColor: '$gray !important',
    border: 'none !important',
  },
  '& .ant-select-item-option-selected': {
    backgroundColor: '$grayDark !important',
  },
  '& .ant-select-selection-search-input': {
    color: '$white !important',
  },
  '& .ant-select-selection-item': {
    color: '$white !important',
  },
  '& .ant-select-selection-placeholder': {
    color: '$grayMedium !important',
  },
  '& .ant-select-clear': {
    backgroundColor: '$gray !important',
    color: '$grayMedium !important',
  },
  '& .ant-select-dropdown': {
    pointerEvents: 'auto !important',
    backgroundColor: '$grayDark',
    boxShadow: '$inputBorderFocus',
    color: '$grayMedium !important',
  },
  '& .ant-select-item': {
    backgroundColor: '$grayDark',
    color: '$white !important',
  },
  '.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner:before':
    {
      content: '""',
      position: 'absolute',
      top: 0,
      insetInlineEnd: 0,
      bottom: 0,
      insetInlineStart: 0,
      zIndex: 1,
      border: '1px solid transparent',
      borderRadius: '$4',
    },
  '.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner':
    {
      background: '$green !important',
    },
  '.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner': {
    background: '$green !important',
  },
  '.ant-picker-time-panel': {
    display: 'none !important',
  },
  '.ant-picker-now': {
    display: 'none !important',
  },
  '.ant-picker': {
    backgroundColor: '$antPickerBgColor !important',
    '&:hover': {
      backgroundColor: '$antPickerBgColor !important',
    },
    '@xs_sm': {
      backgroundColor: '$antPickerMobileBgColor !important',
      '&:hover': {
        backgroundColor: '$antPickerMobileBgColor !important',
      },
    },
  },
  '.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner':
    {
      borderRadius: '$4 !important',
    },
  '.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner':
    {
      borderRadius: '$4 !important',
    },
  '.ant-btn-primary': {
    color: '$buttonTextColor !important',
  },
  '.ant-slider': {
    minWidth: '260px',
  },
  '.ant-input-number-handler-wrap': {
    display: 'none !important',
  },
  '.ant-input-number': {
    background: 'transparent !important',
    color: '$white',
    border: 'none !important',
  },
  '.ant-input-number-input-wrap > input': {
    color: '$white !important',
  },
  '.ant-slider-handle::after': {
    boxShadow: '$green !important',
    background: '$green !important',
    border: '1px solid $white',
  },
  '.ant-slider-handle::before': {},
  '.ant-slider-rail': {
    background: '$grayDark !important',
  },
  '.ant-slider-track': {
    background: '$green !important',
  },
  '.ant-input-number-input': {
    padding: '4px 0 !important',
  },
  '.ant-input-number-input > input': {
    padding: '4px 0 !important',
  },
});

const { fontSizes, fontWeights, colors, space, radii, lineHeights } = theme;

export {
  colors,
  fontSizes,
  fontWeights,
  getCssText,
  globalCss,
  injectGlobalStyles,
  lineHeights,
  radii,
  space,
  styled,
  theme,
};
