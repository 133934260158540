import { useEffect, useLayoutEffect } from 'react';

import { useLazyGetMenuContent } from '~api/content/contentQueries';
import { useGetLikedGames, useGetPopularGames } from '~api/games/gamesQueries';
import { useLazySettings } from '~api/partner/partnerQueries';
import { UserProfileData } from '~api/user/types';
import { useLazyGetUserProfile } from '~api/user/userQueries';
import { SIGNAL_R_SOCKET_NAMES } from '~constants/signalR';
import { THEMES } from '~constants/ui';
import { useListenTokens } from '~hooks/useListenTokens';
import { useTransactionsUpdate } from '~hooks/useTransactionsUpdate';
import {
  startTokenRefreshScheduler,
  stopTokenRefreshScheduler,
} from '~services/auth/tokenRefreshScheduler';
import { useAppDispatch, useAppSelector } from '~store';
import { setLikedGames, setPopular } from '~store/slices/gamesSlice';
import { setAvailablePaymentSettings } from '~store/slices/paymentsSlice';
import { setWebsiteSettings } from '~store/slices/settingsSlice';
import {
  startSignalRAuthedSocket,
  stopSignalRAuthedSocket,
} from '~store/slices/signalRSocketsSlice';
import {
  login,
  logout,
  setIsUserProfileLoaded,
  setProfile,
} from '~store/slices/userSlice';
import { setMenuContents } from '~store/slices/userUISlice';
import { COOKIES_NAMES, getCookie } from '~utils/cookies';
import { getTheme } from '~utils/getTheme';

import { semaBetTheme } from '../../stitches.config';
import BetonlyFaviconIcon from '../assets/betonly-logo.png';
import SemabetFaviconIcon from '../assets/semabet-logo.png';

export const useOnAppInit = () => {
  const dispatch = useAppDispatch();
  const { lazyGetUserProfileQuery } = useLazyGetUserProfile();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { lazySettingsQuery } = useLazySettings();
  const { lazyGetMenuContentQuery } = useLazyGetMenuContent();
  const { getPopularGamesQuery } = useGetPopularGames();
  const { getLikedGamesQuery } = useGetLikedGames();

  const loadProfile = async () => {
    try {
      const { data } = await lazyGetUserProfileQuery();

      if (data) {
        dispatch(setProfile(data as UserProfileData));
      } else {
        dispatch(logout());
      }
    } catch (error) {
      console.error('Error while loading profile', error);
      dispatch(logout());
    }
  };

  const loadSettings = async () => {
    const websiteSettings = await lazySettingsQuery().unwrap();
    const { paymentProviderSettings } = websiteSettings;

    dispatch(setWebsiteSettings(websiteSettings));

    if (paymentProviderSettings.length) {
      dispatch(setAvailablePaymentSettings(paymentProviderSettings));
    }
  };

  const loadMostLikedGames = async () => {
    try {
      const data = await getPopularGamesQuery().unwrap();

      dispatch(setPopular(data));
    } catch (e) {
      console.log(e);
    }
  };

  const loadLikedGames = async () => {
    try {
      const data = await getLikedGamesQuery().unwrap();

      dispatch(setLikedGames(data));
    } catch (e) {
      console.log(e);
    }
  };

  const loadMenuContentData = async () => {
    const data = await lazyGetMenuContentQuery(
      '?names=footer&names=header',
    ).unwrap();

    dispatch(setMenuContents(data));
  };

  useTransactionsUpdate();
  useListenTokens();

  useLayoutEffect(() => {
    const accessToken = getCookie(COOKIES_NAMES.ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      dispatch(login());
    }

    dispatch(setIsUserProfileLoaded(true));
  }, []);

  useLayoutEffect(() => {
    const theme = getTheme();

    if (theme === THEMES.SEMA_BET) {
      document.documentElement.classList.add(semaBetTheme.className);
    }
  }, []);

  useEffect(() => {
    loadSettings();
    loadMostLikedGames();
    loadMenuContentData();
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      startTokenRefreshScheduler();
      loadProfile();
      loadLikedGames();

      setTimeout(() => {
        dispatch(
          startSignalRAuthedSocket([SIGNAL_R_SOCKET_NAMES.NOTIFICATIONS]),
        );
      }, 5000);
    } else {
      dispatch(stopSignalRAuthedSocket());
      stopTokenRefreshScheduler();
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    function getFaviconUrl() {
      const hostname = window.location.hostname;

      if (
        hostname.includes('semabet.ug') ||
        hostname.includes('semabet.co.mz') ||
        hostname.includes('semabet.co.tz')
      ) {
        return SemabetFaviconIcon;
      }

      if (hostname.includes('betonly')) {
        return BetonlyFaviconIcon;
      }

      return null;
    }

    if (document && document.querySelector("link[rel='icon']")) {
      const element = document.querySelector("link[rel='icon']");

      if (element) {
        const favicon = getFaviconUrl();

        if (favicon) {
          element.setAttribute('href', favicon);
        }
      }
    }
  }, []);
};
