import React, { forwardRef } from 'react';
import * as CheckboxUI from '@radix-ui/react-checkbox';
import { styled } from 'stitches.config';

import { CheckedGreenIcon } from '~icons';

import { Box } from './Box';
import { Label } from './Label';
import { Text } from './Typography';

interface CheckboxProps {
  label: React.ReactNode;
  checked: boolean;
  id?: string;
  labelPosition?: 'left' | 'right';
  defaultChecked?: boolean;
  onCheckedChange?: (checked: CheckboxUI.CheckedState) => void;
  additionalLabel?: string;
  isError?: boolean;
}

const CheckboxRoot = styled(CheckboxUI.Root, {
  all: 'unset',
  borderRadius: '$4',
  backgroundColor: '$green',
  border: 'none',
  width: '$5',
  height: '$5',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&[data-state=checked]': {
    lineHeight: 0,
  },
  '&[data-state=unchecked]': {
    boxShadow: '$checkboxBorderColorNormal',
    backgroundColor: 'inherit',
    '&:hover': {
      boxShadow: '$checkboxBorderColorHover',
    },
  },
  '&:hover': {
    boxShadow: '$checkboxBorderColorSelectedHover',
  },
  variants: {
    error: {
      true: {
        boxShadow: '$checkboxBorderColorError',
        '&[data-state=unchecked]': {
          boxShadow: '$checkboxBorderColorError',
          backgroundColor: 'inherit',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        '&:hover': {
          boxShadow: '$checkboxBorderColorError',
        },
      },
    },
  },
});

const ForwardedCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      id,
      defaultChecked,
      labelPosition = 'right',
      onCheckedChange,
      additionalLabel,
      isError,
      ...rest
    },
    ref,
  ) => {
    const isPositionReversed = labelPosition === 'left';

    return (
      <Box flexRow alignCenter gap={3} ref={ref}>
        <CheckboxRoot
          {...rest}
          id={id}
          defaultChecked={defaultChecked}
          error={isError}
          onCheckedChange={onCheckedChange}
          css={{ order: isPositionReversed ? 2 : 1 }}
        >
          <CheckboxUI.Indicator>
            <CheckedGreenIcon />
          </CheckboxUI.Indicator>
        </CheckboxRoot>
        <Label
          noMargin
          htmlFor={id}
          css={{ order: isPositionReversed ? 1 : 2 }}
        >
          {label}
          {additionalLabel && <Text>{additionalLabel}</Text>}
        </Label>
      </Box>
    );
  },
);

export const Checkbox = ForwardedCheckbox;
Checkbox.displayName = 'Checkbox';
