import React, { useEffect, useState } from 'react';

import { Categories, Games } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { ScrollableBox } from '~components/atoms/Skeletons/styled.components';
import { Text } from '~components/atoms/Typography';
import { GamesGrid } from '~components/molecules/Games';
import { SearchField } from '~components/molecules/GamesModalFilters/SearchField';
import { GamesSlider } from '~components/molecules/GamesSlider';
import { ProvidersList } from '~components/molecules/ProvidersList';
import { GAME_PROVIDERS } from '~constants/providers';
import { useDebouncedEffect } from '~hooks/useDebouncedEffect';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  selectGames,
  selectMostLikedGames,
  selectMostSearchedGames,
  selectNotEmptyCategories,
  selectSortedProviders,
  setSelectedCategoryId,
} from '~store/slices/gamesSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { Providers } from '~types/providers';
import { getSanitizedHTML } from '~utils/htmlUtils';

export const Search = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const {
    isMobile,
    isMobileOrTablet,
    isDesktop,
    isLaptop,
    isLargeDesktop,
    isLaptopOrDesktop,
  } = useMedia();
  const providers = useAppSelector(selectSortedProviders);
  const categories = useAppSelector(selectNotEmptyCategories);
  const games = useAppSelector(selectGames);

  const mostSearchedGames = useAppSelector(selectMostSearchedGames);
  const mostLikedGames = useAppSelector(selectMostLikedGames);
  const [searchValue, setSearchValue] = useState('');
  const [foundCategories, setFoundCategories] = useState<Categories>([]);
  const [foundGames, setFoundGames] = useState<Games>([]);
  const [foundProviders, setFoundProviders] = useState<Providers>([]);
  const [isEmptyResult, setIsEmptyResult] = useState(false);
  const [filteredDefaultProviders, setFilteredDefaultProviders] =
    useState<Providers>([]);

  const handleCategoryClick = (id: string) => {
    dispatch(setSelectedCategoryId(id));
    dispatch(closeDialog());
  };

  useDebouncedEffect(
    () => {
      if (!searchValue.length) return;

      const foundCategories = categories.filter((category) => {
        return category.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      setFoundCategories(foundCategories);

      const foundGames = games.filter((game) => {
        return game.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      setFoundGames(foundGames);
      const foundProviders = providers.filter((provider) => {
        return provider.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      setFoundProviders(foundProviders);

      const isEmptyResult =
        !foundCategories.length && !foundGames.length && !foundProviders.length;

      setIsEmptyResult(isEmptyResult);
    },
    [searchValue, categories, games, providers],
    300,
  );

  useEffect(() => {
    type ProvidersCountsMap = Record<GAME_PROVIDERS, number>;
    const gamesCountsMap: ProvidersCountsMap = {} as ProvidersCountsMap;

    games.forEach((game) => {
      if (!gamesCountsMap[game.gameProviderId]) {
        gamesCountsMap[game.gameProviderId] = 1;
      } else {
        gamesCountsMap[game.gameProviderId]++;
      }
    });

    const filteredProviders = providers.filter(
      (provider) => gamesCountsMap[provider.id] > 0,
    );

    setFilteredDefaultProviders(
      filteredProviders.map((provider) => ({
        ...provider,
        gameCount: gamesCountsMap[provider.id],
      })),
    );
  }, [games]);

  const popularSearchsSlidesPerView = isMobile
    ? 3
    : isDesktop || isLargeDesktop
    ? 8
    : 6;
  const popularSearchsSlideHeight = isMobile ? 104 : 136;

  const mostLikedSlidesPerView = isMobile
    ? 1.5
    : isDesktop || isLargeDesktop
    ? 4
    : 3;
  const mostLikedSearchsSlideHeight = isMobile
    ? 104
    : isDesktop || isLargeDesktop
    ? 148
    : 136;

  return (
    <Box
      flexCol
      css={{
        overflow: 'hidden',
        '@xs_sm': {
          background: '$dialogBgColor',
        },
      }}
    >
      <SearchField value={searchValue} onChange={setSearchValue} />
      <Separator
        verticalSpace={0}
        css={{
          background: '$dialogBorderColor',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <ScrollableBox
        css={{
          height: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          mb: '$4',
          '@xs_sm': {
            paddingBottom: '90px',
          },
        }}
      >
        {!searchValue.length ? (
          <>
            <GamesSlider
              css={{
                p: '$4 $5',
                '@md': {
                  p: '$3 $4',
                },
                '@xs_sm': {
                  p: '$4 $5',
                },
              }}
              titleLevel={isMobileOrTablet ? '14-20' : '16-28'}
              games={mostSearchedGames}
              slidesPerView={popularSearchsSlidesPerView}
              slideHeight={popularSearchsSlideHeight}
              label={localized('casino.mostPupularSearches')}
              withContainer={!isMobileOrTablet}
              withDots={isMobileOrTablet}
              gameCardVariant={
                isLaptopOrDesktop || isLargeDesktop ? 'xs' : undefined
              }
            />
            <Separator
              verticalSpace={0}
              css={{
                background: '$dialogBorderColor',
                width: 'calc(100% + 56px)',
                ml: '-28px',
              }}
            />
            <Box
              css={{
                p: '$4 $5',
                '@md': {
                  p: '$3 $4',
                },
                '@xs_sm': {
                  p: '$4 $5',
                },
              }}
            >
              <Text
                level={isMobileOrTablet ? '14-20' : '16-28'}
                textTransform="uppercase"
                css={{
                  pl: '$3',
                  pb: '$4',
                  '@md': {
                    pb: '$3',
                  },
                }}
              >
                {localized('casino.popularProviders')}
              </Text>
              <ProvidersList providers={filteredDefaultProviders} />
            </Box>
            <Separator
              verticalSpace={0}
              css={{
                background: '$dialogBorderColor',
                width: 'calc(100% + 56px)',
                ml: '-28px',
              }}
            />
            <GamesSlider
              css={{
                p: '$4 $5',
                '@md': {
                  p: '$3 $4',
                },
                '@xs_sm': {
                  p: '$4 $5',
                },
              }}
              titleLevel={isMobileOrTablet ? '14-20' : '16-28'}
              games={mostLikedGames}
              slidesPerView={mostLikedSlidesPerView}
              slideHeight={mostLikedSearchsSlideHeight}
              label={localized('casino.mostLiked')}
              withContainer={!isMobileOrTablet}
              withDots={isMobileOrTablet}
              gameCardVariant={
                isLaptopOrDesktop || isLargeDesktop ? 'xs' : undefined
              }
              colSpan={2}
            />
          </>
        ) : isEmptyResult ? (
          <Box
            flexCol
            fullWidth
            alignCenter
            justifyCenter
            css={{
              pt: '123px',
              '@xs_sm': {
                pt: '80px',
              },
            }}
          >
            <Text
              level={isMobileOrTablet ? '12-20' : '14-24'}
              textAlign={'center'}
              color="grayMedium"
            >
              {localized('casino.noResultTitle')}
            </Text>
            <Text
              level={isMobileOrTablet ? '12-20' : '14-24'}
              textAlign={'center'}
              color="grayMedium"
            >
              {localized('casino.noResultDescription')}
            </Text>
            <Text
              level={isMobileOrTablet ? '12-20' : '14-24'}
              textAlign={'center'}
              color="grayMedium"
            >
              {localized('casino.noResultDescription2')}
            </Text>
          </Box>
        ) : (
          <>
            {!!foundCategories.length && (
              <>
                <Box
                  css={{
                    p: '$4 $5',
                    '@md': {
                      p: '$3 $4',
                    },
                    '@xs_sm': {
                      p: '$4 $5',
                    },
                  }}
                >
                  <Box
                    flexRow
                    alignCenter
                    fullWidth
                    justifyContentBetween
                    css={{
                      pl: '$3',
                    }}
                  >
                    <Text
                      level={isMobileOrTablet ? '14-20' : '16-28'}
                      textTransform="uppercase"
                    >
                      {localized('casino.categories')}
                    </Text>
                    <Text
                      level={isMobileOrTablet ? '14-20' : '16-28'}
                      color="grayMedium"
                    >
                      {foundCategories.length}
                    </Text>
                  </Box>
                  <Box
                    flexCol
                    gap={3}
                    css={{
                      pt: '$4',
                    }}
                  >
                    {foundCategories.map(({ id, name, iconSvg }) => (
                      <Box
                        flexRow
                        alignCenter
                        key={id}
                        fullWidth
                        gap={4}
                        css={{
                          cursor: 'pointer',
                          p: '$3',
                          borderRadius: '$10',
                          background: '$blueDark',
                          '@xs_sm': {
                            p: '$2',
                          },
                        }}
                        onClick={() => handleCategoryClick(id)}
                      >
                        <Box
                          flexRow
                          alignCenter
                          justifyCenter
                          css={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '$10',
                            background: '$black',
                            color: '$grayMedium',
                            '& svg': {
                              width: '2rem',
                              height: '2rem',
                            },
                            '@xs_sm': {
                              width: '48px',
                              height: '48px',
                              '& svg': {
                                width: '1.5rem',
                                height: '1.5rem',
                              },
                            },
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                iconSvg &&
                                getSanitizedHTML(
                                  iconSvg.replace(
                                    /fill="#[0-9a-fA-F]+"/g,
                                    'fill="currentColor"',
                                  ),
                                ),
                            }}
                          />
                        </Box>
                        <Text level="14-24">{name}</Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Separator
                  css={{
                    background: '$dialogBorderColor',
                    width: 'calc(100% + 56px)',
                    ml: '-28px',
                    mt: '$5',
                    mb: '$3',
                  }}
                />
              </>
            )}
            {!!foundProviders.length && (
              <>
                <Box
                  flexCol
                  css={{
                    p: '$4 $5',
                    '@md': {
                      p: '$3 $4',
                    },
                    '@xs_sm': {
                      p: '$4 $5',
                    },
                  }}
                >
                  <Box
                    flexRow
                    alignCenter
                    fullWidth
                    justifyContentBetween
                    css={{
                      pb: '$4',
                      pl: '$3',
                    }}
                  >
                    <Text
                      level={isMobileOrTablet ? '14-20' : '16-28'}
                      textTransform="uppercase"
                    >
                      {localized('casino.providers')}
                    </Text>
                    <Text
                      level={isMobileOrTablet ? '14-20' : '16-28'}
                      color="grayMedium"
                    >
                      {foundProviders.length}
                    </Text>
                  </Box>
                  <ProvidersList providers={foundProviders} />
                </Box>
                <Separator
                  css={{
                    background: '$dialogBorderColor',
                    width: 'calc(100% + 56px)',
                    ml: '-28px',
                    mt: '$5',
                    mb: '$3',
                  }}
                />
              </>
            )}
            {!!foundGames.length && (
              <Box
                flexCol
                css={{
                  p: '$4 $5',
                  '@md': {
                    p: '$3 $4',
                  },
                  '@xs_sm': {
                    p: '$4 $5',
                  },
                }}
              >
                <Box
                  flexRow
                  alignCenter
                  fullWidth
                  justifyContentBetween
                  css={{
                    pb: '$4',
                    pl: '$3',
                  }}
                >
                  <Text
                    level={isMobileOrTablet ? '14-20' : '16-28'}
                    textTransform="uppercase"
                  >
                    {localized('casino.games')}
                  </Text>
                  <Text
                    level={isMobileOrTablet ? '14-20' : '16-28'}
                    color="grayMedium"
                  >
                    {foundGames.length}
                  </Text>
                </Box>
                <Box
                  css={
                    !isMobileOrTablet
                      ? {
                          p: '$2',
                          borderRadius: '$10',
                          background: '$blueDark',
                        }
                      : {}
                  }
                >
                  <GamesGrid
                    games={foundGames}
                    tabletLayout={isDesktop || isLaptop}
                    isFromSearch={true}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </ScrollableBox>
    </Box>
  );
};
