import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledTimeDisplayContainer = styled(Box, {
  color: '$white',
  textAlign: 'center',
  fontWeight: '$medium',
  lineHeight: '$24',
});

export const StyledTimePart = styled('span', {
  textAlign: 'center',
  variants: {
    type: {
      digit: {
        width: '1.25rem',
      },
      colon: {
        width: '0.5rem',
      },
      ampm: {
        width: '1.25rem',
        ml: '6px',
      },
    },
  },
});
