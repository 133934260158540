import React, { forwardRef, ReactNode } from 'react';
import * as PopoverUI from '@radix-ui/react-popover';
import { CSS, styled } from 'stitches.config';

import { BasicButton } from '~components/atoms/Button';
import { zIndex } from '~utils/zIndex';

export const PopoverTrigger = PopoverUI.Trigger;

export const InvisibleTrigger = styled(BasicButton, {
  visibility: 'hidden',
  pointerEvents: 'none',
  position: 'absolute',
  height: 0,
  width: 0,
  variants: {
    side: {
      right: {
        right: 0,
        bottom: '50%',
        p: 0,
      },
      bottom: {
        bottom: 0,
        left: '50%',
        p: 0,
      },
    },
  },
  defaultVariants: {
    side: 'right',
  },
});

const StyledPopoverContent = styled(PopoverUI.Content, {
  borderRadius: '$8',
  p: '$3 $4 $4 $4',
  width: 260,
  variants: {
    tone: {
      main: {
        backgroundColor: '$popoverMainBgColor',
        border: '1px solid $popoverMainBorderColor',
      },
      secondary: {
        backgroundColor: '$popoverSecondaryBgColor',
        border: '1px solid $popoverSecondaryBorderColor',
      },
    },
  },
  defaultVariants: {
    tone: 'main',
  },
});

export const PopoverArrow = styled(PopoverUI.Arrow, {
  transform: 'scale(2.4) translateY(-0.9px)',
  transformOrigin: 'top',
  strokeWidth: '1px',
  variants: {
    tone: {
      main: {
        fill: '$popoverMainBgColor',
      },
      secondary: {
        fill: '$popoverSecondaryBgColor',
      },
    },
  },
  defaultVariants: {
    tone: 'main',
  },
});

export const PopoverArrowBorder = styled(PopoverUI.Arrow, {
  transform: 'scale(2.4) translateY(-0.4px)',
  transformOrigin: 'top',
  variants: {
    tone: {
      main: {
        fill: '$popoverMainBorderColor',
      },
      secondary: {
        fill: '$popoverSecondaryBorderColor',
      },
    },
  },
  defaultVariants: {
    tone: 'main',
  },
});

interface PopoverContentProps {
  children: ReactNode;
  sideOffset?: number;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  isAutoFocus?: boolean;
  tone?: 'main' | 'secondary';
  width?: number | string;
  noArrow?: boolean;
  css?: CSS;
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  (
    {
      children,
      sideOffset,
      side,
      align,
      isAutoFocus = false,
      tone = 'main',
      width = 260,
      noArrow = false,
      css,
      ...props
    },
    ref,
  ) => {
    return (
      <PopoverUI.Portal>
        <StyledPopoverContent
          {...props}
          sideOffset={sideOffset}
          align={align}
          side={side}
          tone={tone}
          ref={ref}
          onOpenAutoFocus={(event) => {
            if (!isAutoFocus) {
              event.preventDefault();
            }
          }}
          css={{
            ...css,
            zIndex: zIndex.popover,
            width,
          }}
        >
          {children}
          {!noArrow && (
            <>
              <PopoverArrowBorder tone={tone} />
              <PopoverArrow tone={tone} />
            </>
          )}
        </StyledPopoverContent>
      </PopoverUI.Portal>
    );
  },
);

interface PopoverProps {
  children: React.ReactNode;
  isOpen: boolean;
  defaultOpen?: boolean;
  ariaLabel?: string;
  handleOpenChange?: (isOpen: boolean) => void;
}

export const Popover = ({
  children,
  defaultOpen = false,
  isOpen = false,
  handleOpenChange,
  ...props
}: PopoverProps & { css?: CSS }) => (
  <PopoverUI.Root
    open={isOpen}
    onOpenChange={handleOpenChange}
    defaultOpen={defaultOpen}
    {...props}
  >
    {children}
  </PopoverUI.Root>
);

Popover.displayName = 'Popover';
