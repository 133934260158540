import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { MobileCategoriesList } from '~components/molecules/GamesFilters/MobileCategoriesList';
import { StyledFilterButton } from '~components/molecules/GamesFilters/styled.components';
import { useTranslation } from '~hooks/useTranslation';
import { FilteredIcon, FilterIcon, ProvidersIcon, SearchIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setFilteredGames,
  setFilterType,
  setSelectedCategoryId,
} from '~store/slices/gamesSlice';
import { openDialog } from '~store/slices/globalDialogSlice';
import { FILTER_TYPE } from '~types/games';

export const GamesFiltersMobile = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { filteredGames } = useAppSelector((state) => state.games);
  const isFiltered = filteredGames.length > 0;

  const handleFilterClick = (key: FILTER_TYPE) => {
    if (isFiltered) {
      dispatch(setSelectedCategoryId(null));
      dispatch(setFilteredGames([]));
    } else {
      dispatch(openDialog(DIALOGS.GAMES_FILTERS));
      dispatch(setFilterType(key));
    }
  };

  return (
    <Box
      flexCol
      css={{
        background: '$grayDark',
        py: '$1',
        width: 'calc(100% + 40px)',
        marginLeft: '-20px',
      }}
      gap={1}
    >
      <Box
        flexRow
        justifyContentBetween
        css={{
          p: '$2 $4',
          background: '$blueDark',
        }}
      >
        <Box flexRow gap={2}>
          <StyledFilterButton
            mobile
            onClick={() => handleFilterClick(FILTER_TYPE.SEARCH)}
          >
            <SearchIcon />
          </StyledFilterButton>
          <StyledFilterButton
            mobile
            isActive={isFiltered}
            onClick={() => handleFilterClick(FILTER_TYPE.FILTERS)}
          >
            {isFiltered ? <FilteredIcon /> : <FilterIcon />}
          </StyledFilterButton>
        </Box>
        <StyledFilterButton
          mobile
          onClick={() => handleFilterClick(FILTER_TYPE.PROVIDERS)}
        >
          <ProvidersIcon />
          <Text level="12-20">{localized('casino.providersTitle')}</Text>
        </StyledFilterButton>
      </Box>
      <MobileCategoriesList />
    </Box>
  );
};
