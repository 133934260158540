import { useEffect } from 'react';

import { UserProfileData } from '~api/user/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { CURRENCY_CODES } from '~constants/common';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_METHODS,
} from '~constants/payments';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  resetCardDepositState,
  setSelectedPayment,
} from '~store/slices/paymentsSlice';

import { DepositMethod } from '../components/DepositMethod';

import { DepositForm } from './DepositForm';

interface PaymentMethodProps {
  isNotVerified: boolean;
}

export const PaymentMethod = ({ isNotVerified }: PaymentMethodProps) => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.settings);
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { profile } = useAppSelector((state) => state.userState);

  const { idNumber } = profile || ({} as UserProfileData);

  const isNigeria = currency === CURRENCY_CODES.NGN;
  const isDeposit = contentTab === USER_PAYMENT_TABS.DEPOSIT;

  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();
  const { addingNewPaymentMethodFormStatus } = useAppSelector(
    (state) => state.payments,
  );

  const handleOpenVerifyTab = () => {
    dispatch(openDialog(DIALOGS.VERIFY_PERSONAL_DETAILS));
  };

  useEffect(() => {
    return () => {
      dispatch(resetCardDepositState());
    };
  }, []);

  useEffect(() => {
    if (contentTab === USER_PAYMENT_TABS.WITHDRAW) {
      dispatch(setSelectedPayment(PAYMENT_METHODS.INSTANT_BANK));
    }
  }, [contentTab]);

  return (
    <Box
      css={{
        '@xs_sm': {
          mb: '0',
          borderRadius: '$6',
          background: '$black',
        },
      }}
    >
      <Box
        fullWidth
        css={{
          backgroundColor: '$grayDark',
          border: '1px solid transparent',
          borderRadius: '$8',
          '@xs_sm': {
            backgroundColor: '$userProfileWidgetBgColorMobile',
          },
        }}
      >
        {addingNewPaymentMethodFormStatus ===
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE && (
          <Box css={{ borderBottom: '1px solid $gray' }} fullHeight>
            {isNigeria && isDeposit && <DepositMethod />}
            {isNigeria && contentTab === USER_PAYMENT_TABS.WITHDRAW && (
              <Box
                css={{
                  p: '$2 $3',
                }}
              >
                <Text color="grayMedium" level="12-20" fontWeight="medium">
                  {localized('withdrawInfo.title')}
                </Text>
                <Text color="grayMedium" level="12-20">
                  {localized('withdrawInfo.description')}
                </Text>
              </Box>
            )}
          </Box>
        )}
        {isNotVerified && isNigeria && !isDeposit ? (
          <Box
            fullWidth
            flexCol
            gap={4}
            alignCenter
            justifyCenter
            css={{ p: '$4' }}
          >
            <Text
              textTransform="uppercase"
              level={isMobileOrTablet ? '14-20' : '17-24'}
            >
              {localized('payments.verifyAccountTitle')}
            </Text>
            {!idNumber ? (
              <Button onClick={handleOpenVerifyTab}>
                <Text
                  textTransform="uppercase"
                  css={{
                    color: '$buttonTextColor',
                  }}
                  level={isMobileOrTablet ? '14-20' : '17-24'}
                >
                  {localized('payments.verifyAccount')}
                </Text>
              </Button>
            ) : (
              <Text
                level={isMobileOrTablet ? '14-20' : '17-24'}
                css={{
                  color: '$grayMedium',
                }}
              >
                {localized('payments.verifyAccountContactSupport')}
              </Text>
            )}
          </Box>
        ) : (
          <DepositForm />
        )}
      </Box>
    </Box>
  );
};
