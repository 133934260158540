import React, { ComponentType } from 'react';

import { Box } from '~components/atoms/Box';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Text } from '~components/atoms/Typography';
import { StyledLinkContentMenuText } from '~components/organisms/Footer/styled.components';
import {
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/Header/useHeaderMenu';
import { useTranslation } from '~hooks/useTranslation';
import {
  AviatorIcon,
  CasinoIcon,
  InplayIcon,
  JackpotIcon,
  LiveCasinoIcon,
  PromotionIcon,
  SportsIcon,
  VirtualSportsIcon,
} from '~icons/menu';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { getIsMenuItemActive } from '~utils/menu';

export const MobileMenu = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { menu } = useHeaderMenu();

  type IconsMap = {
    [key in MENU_ICON_TYPES]: ComponentType;
  };

  const iconsMap: IconsMap = {
    [MENU_ICON_TYPES.Aviator]: AviatorIcon,
    [MENU_ICON_TYPES.Casino]: CasinoIcon,
    [MENU_ICON_TYPES.Inplay]: InplayIcon,
    [MENU_ICON_TYPES.LiveCasino]: LiveCasinoIcon,
    [MENU_ICON_TYPES.Sports]: SportsIcon,
    [MENU_ICON_TYPES.VirtualSports]: VirtualSportsIcon,
    [MENU_ICON_TYPES.Promotion]: PromotionIcon,
    [MENU_ICON_TYPES.Jackpot]: JackpotIcon,
  };

  return (
    <DialogMobileContent isContentState={false}>
      <Box
        css={{
          p: '$2 $1',
        }}
      >
        <Text
          level="18-24"
          textTransform="uppercase"
          css={{
            mb: '$2',
            ml: '$4',
          }}
        >
          {localized('menu')}
        </Text>
        <Box
          flexCol
          gap={4}
          css={{
            p: '$3 $3',
            borderRadius: '$8',
            border: '1px solid $iconButtonBorderHover',
            background: '$htmlBgColor',
          }}
        >
          {menu.map(({ name, url, icon }) => {
            const Icon = iconsMap[icon as MENU_ICON_TYPES];
            const isActive = getIsMenuItemActive(
              icon as MENU_ICON_TYPES,
              url || '',
            );
            const color = isActive ? 'yellow' : 'white';
            const fontWeight = isActive ? '$medium' : '$normal';

            return (
              <Box
                css={{
                  background: '$inplayMarketsAccordionContentBgColor',
                  p: '$3',
                  borderRadius: '$8',
                }}
              >
                <NavigationLink
                  to={url || ''}
                  key={name}
                  onClick={() => {
                    dispatch(closeDialog());
                  }}
                >
                  <StyledLinkContentMenuText as="span">
                    <Box
                      flexRow
                      alignCenter
                      gap={2}
                      css={{
                        color: `${color}`,
                      }}
                    >
                      {Icon && <Icon />}
                      <Text
                        css={{
                          fontWeight,
                          color,
                        }}
                        level={'16-20'}
                      >
                        {name}
                      </Text>
                    </Box>
                  </StyledLinkContentMenuText>
                </NavigationLink>
              </Box>
            );
          })}
        </Box>
      </Box>
    </DialogMobileContent>
  );
};
