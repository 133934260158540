import React, { useEffect, useRef, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { DOT } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { KeyboardDeleteIcon } from '~icons';
import { useAppDispatch } from '~store';
import { setKeyboardVisibility } from '~store/slices/userUISlice';
import { commaSeparatedNumber } from '~utils/numberUtils';
import { removeNumberLastSymbol } from '~utils/stringUtils';

import { numbers1To10 } from './constants';
export const KEYBOARD_HEIGHT = 224;

export enum KEYBOARD_TYPES {
  NUMBER = 'number',
}

const typesMap = {
  [KEYBOARD_TYPES.NUMBER]: numbers1To10,
};

interface KeyboardProps {
  type: KEYBOARD_TYPES;
  text: number | string;
  isOpen: boolean;
  onChange: (value: string) => void;
  setIsOpen: (value: boolean) => void;
  additionalKeyboard?: number[];
}

export const Keyboard = ({
  type,
  text,
  isOpen,
  onChange,
  setIsOpen,
  additionalKeyboard = [],
}: KeyboardProps) => {
  const { isMobile } = useMedia();
  const dispatch = useAppDispatch();
  const keyboardRef = useRef<HTMLDivElement | null>(null);

  const handleButtonClick = (value: string) => {
    if (value === DOT && text.toString().includes(DOT)) return;
    if (additionalKeyboard.includes(+value)) {
      onChange(value);

      return;
    }

    const nextValue = text === 0 ? value : `${text}${value}`;

    onChange(nextValue);
  };

  const handleRemoveClick = () => {
    const nextValue = removeNumberLastSymbol(text);

    onChange(nextValue.toString());
  };

  const [touchStartY, setTouchStartY] = useState<null | number>(null);
  const [touchMoveY, setTouchMoveY] = useState<null | number>(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    function handleTouchStart(e: TouchEvent) {
      setTouchStartY(e.touches[0] ? e.touches[0].clientY : null);
      setTouchMoveY(null);
      setIsScrolling(false);
    }

    const handleTouchMove = (e: TouchEvent) => {
      setTouchMoveY(e.touches[0] ? e.touches[0].clientY : null);
      setIsScrolling(true);
    };

    const handleTouchEnd = (e: TouchEvent) => {
      if (isScrolling && touchStartY !== null && touchMoveY !== null) {
        const deltaY = Math.abs(touchMoveY - touchStartY);

        if (deltaY > 10) {
          return;
        }
      }

      if (
        keyboardRef.current &&
        !keyboardRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
        dispatch(setKeyboardVisibility(false));
      }

      setTouchStartY(null);
      setTouchMoveY(null);
      setIsScrolling(false);
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [touchStartY, touchMoveY, isScrolling]);

  useEffect(() => {
    return () => {
      dispatch(setKeyboardVisibility(false));
    };
  }, []);

  if (!isMobile || !isOpen) return null;

  return (
    <Box
      fullWidth
      gap={2}
      ref={keyboardRef}
      justifyContentBetween
      css={{
        bottom: 0,
        zIndex: 1,
        p: '$4',
        left: 0,
        right: 0,
        gridGap: '8px',
        display: 'grid',
        position: 'fixed',
        backgroundColor: '$grayDark',
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
    >
      {typesMap[type].map((item: number) => (
        <Button
          variant="secondary"
          key={`option-${item}`}
          onClick={() => handleButtonClick(item.toString())}
          css={{
            lineHeight: '$32',
            '&:hover': {
              boxShadow: '$iconButtonBorderActive',
              color: '$white',
            },
          }}
        >
          <Text level="sm-6" textAlign="center">
            {commaSeparatedNumber(item, true)}
          </Text>
        </Button>
      ))}
      <Button
        variant="secondary"
        onClick={() => handleButtonClick(DOT)}
        css={{
          p: '$1 $5',
          boxShadow: 'none',
          lineHeight: '$32',
          '&:hover': {
            boxShadow: '$iconButtonBorderActive',
            color: '$white',
          },
        }}
      >
        {DOT}
      </Button>
      <Button
        variant="secondary"
        key={`option-0`}
        onClick={() => handleButtonClick('0')}
        css={{
          lineHeight: '$32',
          '&:hover': {
            boxShadow: '$iconButtonBorderActive',
            color: '$white',
          },
        }}
      >
        <Text level="sm-6" textAlign="center">
          {0}
        </Text>
      </Button>
      <Button
        variant="secondary"
        onClick={() => handleRemoveClick()}
        css={{
          p: '$1 $5',
          boxShadow: 'none',
          lineHeight: '$32',
          '&:hover': {
            boxShadow: '$iconButtonBorderActive',
            color: '$white',
          },
        }}
      >
        <KeyboardDeleteIcon />
      </Button>
      <Button
        variant="secondary"
        key={`option-10000`}
        onClick={() => handleButtonClick('10000')}
        css={{
          lineHeight: '$32',
          '&:hover': {
            boxShadow: '$iconButtonBorderActive',
            color: '$white',
          },
        }}
      >
        <Text level="sm-6" textAlign="center">
          {commaSeparatedNumber(10000, true)}
        </Text>
      </Button>
    </Box>
  );
};
