import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetCasino } from '~api/games/gamesQueries';
import { Game } from '~api/games/types';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setCategories,
  setCurrentVersion,
  setGames,
  setGamesLoaded,
  setLaunchUrl,
} from '~store/slices/gamesSlice';

export const useGamesGrid = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { games, filteredGames, gamesLoaded, currentVersion, categories } =
    useAppSelector((state) => state.games);

  const { getCasinoQuery } = useGetCasino();

  const loadGames = async () => {
    try {
      const {
        currentVersion: responseCurrentVersion,
        games,
        categories,
      } = (await getCasinoQuery(currentVersion).unwrap()) || {
        currentVersion: null,
        games: null,
      };

      if (games) {
        dispatch(setCategories(categories));
        dispatch(setGames(games));
        dispatch(setCurrentVersion(responseCurrentVersion));
      }

      dispatch(setGamesLoaded(true));
    } catch (e) {
      console.log(e);
      dispatch(setGamesLoaded(true));
    }
  };

  const handleGameClick = (game: Game) => {
    const { name, id } = game;

    dispatch(setLaunchUrl(null));
    navigate(`/games/${id || name}`);
  };

  useEffect(() => {
    dispatch(setLaunchUrl(null));

    loadGames();

    return () => {
      dispatch(setGamesLoaded(false));
    };
  }, []);

  const stateGames = filteredGames.length ? filteredGames : games;

  return {
    games: stateGames,
    categories,
    gamesLoaded,
    currentVersion,
    handleGameClick,
  };
};
