import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { BannerGroup } from '~api/content/types';
import { Box } from '~components/atoms/Box';
import { getSanitizedHTML } from '~utils/htmlUtils';

interface BannerListProps {
  group: BannerGroup;
}

export const BannerItem = ({ group }: BannerListProps) => {
  if (!group) {
    return null;
  }

  const { rotationTime, banners } = group;

  if (!banners || banners.length === 0) {
    return null;
  }

  return (
    <Box
      css={{
        position: 'relative',
        borderRadius: '$8',
        height: '100%',
        '& div': {
          height: '100%',
          borderRadius: '$8',
        },
        img: {
          height: 'inherit',
        },
        '& .slider-wrapper': {
          borderRadius: '$8',
        },
        '& ul': {
          height: '100%',
        },
        '*': {
          p: 0,
          m: 0,
        },
        '& .control-dots': {
          bottom: '1rem',
          height: '1rem',
        },
        '& .dot': {
          opacity: '1 !important',
          width: '2rem !important',
          height: '.5rem !important',
          borderRadius: '4px !important',
          background: 'transparent !important',
          border: '1px solid $yellow',
        },
        '& .dot.selected': {
          background: '$yellow !important',
        },
      }}
    >
      <Carousel
        autoPlay
        stopOnHover
        infiniteLoop
        dynamicHeight
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={true}
        interval={rotationTime}
      >
        {banners.map((banner, index) => {
          return (
            <Box
              key={index}
              css={{
                overflow: 'hidden',
                display: 'inline-block',
                width: '100%',
                mt: '$1',
                '&:first-child': {
                  mt: 0,
                },
              }}
              dangerouslySetInnerHTML={{
                __html: getSanitizedHTML(banner),
              }}
            />
          );
        })}
      </Carousel>
    </Box>
  );
};
