import React, { useEffect, useState } from 'react';

import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import {
  StyledTimeDisplayContainer,
  StyledTimePart,
} from './styled.components';

export const TimeDisplay = () => {
  const [time, setTime] = useState(
    formatDateTime(
      new Date().toISOString(),
      TIME_FORMATS.TIME_12H_WITH_SECONDS,
    ),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(
        formatDateTime(
          new Date().toISOString(),
          TIME_FORMATS.TIME_12H_WITH_SECONDS,
        ),
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <StyledTimeDisplayContainer flexRow justifyContentBetween>
      {time.split(' ').map((part, index) => {
        let type: 'ampm' | 'colon' | 'digit';

        if (part === 'AM' || part === 'PM') {
          type = 'ampm';
        } else if (part === ':') {
          type = 'colon';
        } else {
          type = 'digit';
        }

        return (
          <StyledTimePart key={index} type={type}>
            {part}
          </StyledTimePart>
        );
      })}
    </StyledTimeDisplayContainer>
  );
};
