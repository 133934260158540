import React from 'react';

import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { formatNumber } from '~utils/numberUtils';

import { COLOR_KEYS } from '../../../stitches.config';

const MILLION = 1_000_000;
const HUNDRED_THOUSAND = 100_000;
const TEN_THOUSAND = 10_000;

const thresholds = [
  { limit: MILLION, divisor: MILLION, suffix: 'M' },
  { limit: HUNDRED_THOUSAND, divisor: 1_000, suffix: 'K' },
  { limit: TEN_THOUSAND, divisor: 1_000, suffix: 'K' },
];

const findThreshold = (balance: number) =>
  thresholds.find(({ limit }) => balance >= limit) || {
    divisor: 1,
    suffix: '',
  };

const formatBalance = (balance: number | undefined) => {
  if (!balance) return '0';
  const { divisor, suffix } = findThreshold(balance);

  return (formatNumber(balance / divisor) + suffix).replace('.00', '');
};

interface BalanceInfoProps {
  balance: number | undefined;
  size?: 'small' | 'medium';
  textAlign?: 'left' | 'center' | 'right';
  isBonusBalance?: boolean;
  color?: string;
}

export const BalanceInfo = ({
  balance,
  size = 'medium',
  textAlign = 'left',
  isBonusBalance,
  color = 'white',
}: BalanceInfoProps) => {
  let formattedBalance = '0';

  if (balance) {
    formattedBalance =
      balance?.toFixed(1).length > 8
        ? formatBalance(balance)
        : (+balance.toFixed(1)).toLocaleString('en-US');
  }

  const { currency } = useAppSelector((state) => state.settings);
  const { isMobileOrTablet } = useMedia();
  const { isBalanceVisible } = useAppSelector((state) => state.userUIState);

  const level =
    size === 'medium'
      ? isMobileOrTablet
        ? '12-20'
        : '16-24'
      : isMobileOrTablet
      ? '14-20'
      : '14-24';

  return (
    <Text
      level={level}
      textAlign={textAlign}
      ellipsis
      color={color as COLOR_KEYS}
      css={{
        minWidth: '100px',
        '@xs_sm': {
          minWidth: '50px',
        },
      }}
    >
      {!isBalanceVisible
        ? '*****'
        : `${formattedBalance}  ${
            isBonusBalance ? '' : CURRENCY_SYMBOLS[currency] || ''
          }`}
    </Text>
  );
};
