import { Dialog, DialogContent } from '~components/atoms/Modal';
import { ResetPasswordContent } from '~components/molecules/Authentication/components/reset-password/ResetPasswordContent';
import { SignInContent } from '~components/molecules/Authentication/components/sign-in/SignInContent';
import { SignUpContent } from '~components/molecules/Authentication/components/sign-up/SignUpContent';
import { VerifyEmailToken } from '~components/molecules/Authentication/components/verify-email-token/VerifyEmailToken';
import { VerifyPersonalDetails } from '~components/molecules/Authentication/components/VerifyPersonalDetails';
import { DeactivatedAccount } from '~components/molecules/DeactivatedAccount';
import { GamesModalFilters } from '~components/molecules/GamesModalFilters/GamesModalFilters';
import { MobileMenu } from '~components/molecules/MobileMenu';
import { UserProfileDialog } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { UserProfileDialogMobile } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { SharedRefProvider } from '~contexts/SharedRefContext';
import { useAppDispatch, useAppSelector } from '~store';
import { toggleDialog } from '~store/slices/globalDialogSlice';

export enum DIALOGS {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  RESET_PASSWORD = 'resetPassword',
  USER_PROFILE = 'userProfile',
  USER_PROFILE_MOBILE = 'userProfileMobile',
  VERIFY_EMAIL_TOKEN = 'verifyEmailToken',
  VERIFY_PERSONAL_DETAILS = 'verifyPersonalDetails',
  MENU = 'menu',
  DEACTIVATED_ACCOUNT = 'deactivatedAccount',
  GAMES_FILTERS = 'gamesFilters',
}

const notFullScreenModals = [DIALOGS.DEACTIVATED_ACCOUNT];

export const AbsoluteDialogs = () => {
  const dispatch = useAppDispatch();
  const { isOpen, content } = useAppSelector((state) => state.globalDialog);

  const isNotFullScreen = notFullScreenModals.includes(content as DIALOGS);

  if (!isOpen || !content) return null;

  const contentsMap = {
    [DIALOGS.SIGN_IN]: <SignInContent />,
    [DIALOGS.SIGN_UP]: <SignUpContent />,
    [DIALOGS.RESET_PASSWORD]: <ResetPasswordContent />,
    [DIALOGS.USER_PROFILE]: <UserProfileDialog />,
    [DIALOGS.USER_PROFILE_MOBILE]: (
      <>
        <SharedRefProvider>
          <UserProfileDialogMobile />
        </SharedRefProvider>
      </>
    ),
    [DIALOGS.VERIFY_EMAIL_TOKEN]: <VerifyEmailToken />,
    [DIALOGS.VERIFY_PERSONAL_DETAILS]: <VerifyPersonalDetails />,
    [DIALOGS.MENU]: <MobileMenu />,
    [DIALOGS.DEACTIVATED_ACCOUNT]: <DeactivatedAccount />,
    [DIALOGS.GAMES_FILTERS]: <GamesModalFilters />,
  };

  const Component = contentsMap[content];

  const handleToggleDialog = (value: boolean) => {
    dispatch(toggleDialog(value));

    setTimeout(() => {
      if (!value) {
        const body = document.body;

        body.style.pointerEvents = 'auto';
      }
    }, 100);
  };

  return (
    <Dialog open={isOpen} toggleDialog={handleToggleDialog}>
      <DialogContent isNotFullScreen={isNotFullScreen} hasOverlay>
        {Component}
      </DialogContent>
    </Dialog>
  );
};
