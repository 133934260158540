import React, { useEffect, useState } from 'react';

import { useLazyGetBannerByGroupName } from '~api/content/contentQueries';
import { BannerGroup } from '~api/content/types';
import { CHANNEL_TYPES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';

import { BannerItem } from './BannerItem';

export enum BANNER_GROUP_NAMES {
  CASINO_HEADER = 'header_casino',
  LIVE_CASINO_HEADER = 'header_live_casino',
  VIRTUAL_CASINO_HEADER = 'header_virtual',
}

interface BannerListProps {
  names: string[];
}

export const BannerList = ({ names }: BannerListProps) => {
  const { isMobile, isTablet } = useMedia();
  const { lazyGetBannerByGroupNameQuery } = useLazyGetBannerByGroupName();
  const [bannerData, setBannerData] = useState<BannerGroup>();

  useEffect(() => {
    const loadBannerGroups = async () => {
      try {
        const channelType = isMobile
          ? CHANNEL_TYPES.MOBILE
          : isTablet
          ? CHANNEL_TYPES.TABLET
          : CHANNEL_TYPES.WEB;

        const { data: groups } = await lazyGetBannerByGroupNameQuery({
          names,
          channelType: channelType,
        });

        const foundBannerData = groups?.find(
          (group) => group.name === names[0],
        );

        if (foundBannerData) {
          setBannerData(foundBannerData);
        }
      } catch (error) {
        console.error('Failed getting banners', error);
      }
    };

    loadBannerGroups();
  }, [isMobile, isTablet]);

  return names.map((name) => {
    return bannerData && <BannerItem group={bannerData} key={name} />;
  });
};
