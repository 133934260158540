import { initReactI18next } from 'react-i18next';
import i18next, { use } from 'i18next';

import { LANGUAGE } from '~constants/common';

import common from './en/common.json';
import error from './en/error.json';

export const defaultNS = 'common';

use(initReactI18next).init({
  lng: LANGUAGE.EN, // if you're using a language detector, do not define the lng option
  debug: false,
  resources: {
    en: {
      common,
      error,
    },
  },
  ns: ['common', 'error'],
  defaultNS,
});

export default i18next;
