import {
  ComponentPropsWithRef,
  forwardRef,
  Ref,
  useEffect,
  useState,
} from 'react';
import { NumberFormatValues, PatternFormat } from 'react-number-format';
import { styled } from 'stitches.config';

import { Flag } from '~components/atoms/Flag';
import {
  PHONE_FIELD_COUNTRY_CODES,
  PHONE_FIELD_MASK,
  PHONE_FIELD_MASKS,
  PHONE_FIELD_PLACEHOLDERS,
} from '~constants/auth';
import { CURRENCY_CODES } from '~constants/common';
import { currencyCountryMap } from '~constants/flag';
import { usePhoneNumber } from '~hooks/usePhoneNumber';
import { EditIcon, GreenCheckIcon } from '~icons';
import { useAppSelector } from '~store';

import { Box } from './Box';
import { Input } from './Input';
import { Text } from './Typography';

interface PhoneNumberFieldProps {
  phoneNumberId: string;
  phoneNumber: string;
  isValidPhoneNumber: boolean;
  setPhoneNumber?: (input: string) => void;
  setIsValidPhoneNumber?: (input: boolean) => void;
  readonly?: boolean;
  editable?: boolean;
  isFullView?: boolean;
  autoFocus?: boolean;
  protectedPhoneNumber?: boolean;
  withMask?: boolean;
  isLoginForm?: boolean;
  setValue?: (value: string) => void;
}

const Separator = styled(Box, {
  border: '1px solid $inputVerticalSeparatorColor',
});

const PhoneNumberInput = (
  props: ComponentPropsWithRef<'input'> & {
    hasError: boolean;
    isFullView: boolean;
    autoFocus: boolean;
    protectedPhoneNumber?: boolean;
  },
) => {
  const {
    hasError,
    isFullView,
    readOnly,
    autoFocus,
    protectedPhoneNumber = false,
    ...rest
  } = props;

  return (
    <Input
      {...rest}
      personalDetail={!isFullView}
      phoneNumber
      readOnly={readOnly}
      css={{
        color: hasError
          ? '$red'
          : !isFullView && !readOnly
          ? undefined
          : '$white',
        height: !isFullView ? '32px' : undefined,
        paddingLeft: !isFullView ? '64px' : undefined,
        pt: !isFullView ? '2px' : undefined,
      }}
      autoFocus={autoFocus}
      protectedPhoneNumber={protectedPhoneNumber}
    />
  );
};

export const PhoneNumberField = forwardRef(
  (
    {
      phoneNumberId,
      phoneNumber,
      setPhoneNumber,
      setIsValidPhoneNumber,
      readonly,
      protectedPhoneNumber,
      isValidPhoneNumber,
      editable = false,
      isFullView = true,
      autoFocus = false,
      withMask,
      isLoginForm,
      setValue,
      ...props
    }: PhoneNumberFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { getPhoneNumberWithoutCode, getPhoneNumberWithCode } =
      usePhoneNumber();

    const { currency } = useAppSelector((state) => state.settings);
    const [isEditing, setIsEditing] = useState(false);
    const [number, setNumber] = useState(phoneNumber);

    const handleValueChange = (values: NumberFormatValues) => {
      setNumber(getPhoneNumberWithCode(values.value || ''));

      if (!isValidPhoneNumber && setIsValidPhoneNumber) {
        setIsValidPhoneNumber(true);
      }
    };

    const handleInputValueChange = (value: string) => {
      const filteredValue = value.replace(/[^0-9]/g, '');

      const phoneFormatted = getPhoneNumberWithCode(filteredValue);

      setNumber(phoneFormatted);
      setValue && setValue(phoneFormatted);
    };

    const handleBlur = () => {
      if (setPhoneNumber) {
        const phoneNumber = number.startsWith('+')
          ? getPhoneNumberWithoutCode(number)
          : number;

        setPhoneNumber(phoneNumber);
      }
    };

    const countryName = currencyCountryMap[currency as CURRENCY_CODES];

    useEffect(() => {
      setNumber(
        phoneNumber.startsWith('+')
          ? getPhoneNumberWithoutCode(phoneNumber)
          : phoneNumber,
      );
    }, [phoneNumber]);

    return (
      <Box flexRow css={{ position: 'relative' }} gap={3} ref={ref}>
        <Box
          css={{
            position: 'absolute',
            left: 0,
            top: isFullView ? '$2' : '7px',
            p: '0 $3',
          }}
          flexRow
          justifyCenter
          gap={3}
        >
          {isFullView && <Flag countryName={countryName} size="sm" />}
          {isFullView && (
            <Separator
              css={{
                height: '$5',
                borderWidth: '1px',
              }}
            />
          )}
          <Box flexRow>
            <Text level="14-20" color={isFullView ? 'grayMedium' : 'white'}>
              {PHONE_FIELD_COUNTRY_CODES[currency as CURRENCY_CODES]
                ? `(${PHONE_FIELD_COUNTRY_CODES[currency as CURRENCY_CODES]})`
                : ''}
            </Text>
          </Box>
        </Box>
        {isLoginForm ? (
          <Input
            css={{
              paddingLeft: '112px',
            }}
            {...props}
            maxLength={11}
            value={
              number.startsWith('+')
                ? getPhoneNumberWithoutCode(number)
                : number
            }
            onChange={(e) => handleInputValueChange(e.target.value)}
            type={'tel'}
          />
        ) : (
          <PatternFormat
            id={phoneNumberId}
            format={
              !withMask
                ? PHONE_FIELD_MASKS[currency || CURRENCY_CODES.UGX]
                : PHONE_FIELD_MASK
            }
            value={
              number.startsWith('+')
                ? getPhoneNumberWithoutCode(number)
                : number
            }
            customInput={PhoneNumberInput}
            mask={' '}
            placeholder={
              PHONE_FIELD_PLACEHOLDERS[currency || CURRENCY_CODES.UGX]
            }
            onBlur={handleBlur}
            hasError={!isValidPhoneNumber}
            isFullView={isFullView}
            onValueChange={handleValueChange}
            valueIsNumericString={true}
            readOnly={editable && (readonly || !isEditing)}
            autoFocus={autoFocus && !readonly}
            protectedPhoneNumber={protectedPhoneNumber}
          />
        )}
        {editable && !readonly && (
          <Box
            css={{
              position: 'absolute',
              right: 0,
              top: '$2',
              p: '0 $3',
              cursor: 'pointer',
              color: '$grayMedium',
            }}
            role="button"
            onClick={() => setIsEditing(!isEditing)}
          >
            {!isEditing ? <EditIcon /> : <GreenCheckIcon />}
          </Box>
        )}
      </Box>
    );
  },
);
