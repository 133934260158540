import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setTransactionStatus,
  setUpdatedTransactionId,
} from '~store/slices/paymentsSlice';
import { updateTransactionStatus } from '~store/slices/transactionHistorySlice';
import { updateProfile } from '~store/slices/userSlice';
import { TRANSACTION_STATUS } from '~types/transactions';

export const useTransactionsUpdate = () => {
  const { authedSockets, sockets } = useAppSelector(
    (state) => state.signalRSockets,
  );
  const [socket, setSocket] = useState<HubConnection | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const transactionsSocket =
      authedSockets?.[SIGNAL_R_SOCKET_NAMES.NOTIFICATIONS]?.socket;

    if (transactionsSocket) {
      setSocket(transactionsSocket);
    }
  }, [authedSockets, sockets]);

  useEffect(() => {
    if (socket) {
      socket.on(
        SIGNAL_R_SOCKET_MESSAGES.ON_PAYMENT_STATUS_UPDATE,
        (response) => {
          const { TransactionId, Status } = JSON.parse(response);

          dispatch(setUpdatedTransactionId(TransactionId));
          switch (Status) {
            case 'success':
              dispatch(setTransactionStatus(TRANSACTION_STATUS.SUCCESS));
              break;
            case 'failed':
              dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
              break;
            default:
              dispatch(setTransactionStatus(TRANSACTION_STATUS.PENDING));
              break;
          }

          let resultStatus: TRANSACTION_STATUS;

          switch (Status) {
            case 'success':
              resultStatus = TRANSACTION_STATUS.SUCCESS;
              break;
            case 'failed':
              resultStatus = TRANSACTION_STATUS.FAILED;
              break;
            default:
              resultStatus = TRANSACTION_STATUS.PENDING;
              break;
          }

          dispatch(
            updateTransactionStatus({
              id: TransactionId,
              status: resultStatus,
            }),
          );
        },
      );
      socket.on(SIGNAL_R_SOCKET_MESSAGES.ON_BALANCE_UPDATE, (response) => {
        dispatch(updateProfile({ balance: response }));
      });
    }
  }, [socket]);
};
