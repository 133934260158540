import React, { ReactNode, useEffect, useMemo, useRef } from 'react';

import { ActiveBonusesContentMobile } from '~components/molecules/Bonuses/components/ActiveBonusesContent';
import { BonusHistoryMobile } from '~components/molecules/Bonuses/components/BonusHistoryMobile';
import {
  PAYMENT_TYPES,
  Payments,
} from '~components/molecules/UserProfile/components/Payments';
import { RecentSessionsContentMobile } from '~components/molecules/UserProfile/components/UserProfileContentMobile/RecentSessionsContentMobile';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import {
  MobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';

import { PaymentAccountsMobile } from '../Payments/components/PaymentAccounts/PaymentAccountsMobile';
import { TransactionHistoryMobile } from '../Payments/components/TransactionHistory';
import {
  AccountSettingsContentMobile,
  PersonalDetailsContentMobile,
} from '../UserProfileContentMobile';
import {
  USER_BONUSES_TABS,
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
  USER_PROFILE_TABS,
} from '../UserProfileDialog';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '../UserProfileDialogMobile';

export const UserProfileContentMobile = () => {
  const { mobileUserProfileDialogActiveContent } = useAppSelector(
    (state) => state.mobileState,
  );
  const trail = useAppSelector((state) => state.breadcrumb.trail);
  const trailRef = useRef(trail);
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();

  const getBreadcrumbBackLabel = useMemo(() => {
    switch (mobileUserProfileDialogActiveContent) {
      case USER_PROFILE_TABS.PERSONAL_DETAILS:
      case USER_PROFILE_TABS.ACCOUNT_SETTINGS:
        return localized('userProfile.menuTitles.personalProfile');
      case USER_PAYMENT_TABS.DEPOSIT:
      case USER_PAYMENT_TABS.WITHDRAW:
      case USER_PAYMENT_TABS.PAYMENT_ACCOUNTS:
      case USER_PAYMENT_TABS.TRANSACTION_HISTORY:
        return localized('userProfile.menuTitles.balanceManagement');
      case USER_BONUSES_TABS.ACTIVE_BONUSES:
      case USER_BONUSES_TABS.BONUS_HISTORY:
        return localized('userProfile.menuTitles.bonuses');
      case USER_PROFILE_NAVIGATION_TABS.MESSAGES:
      default:
        return localized('mobile.breadcrumbs.back');
    }
  }, [mobileUserProfileDialogActiveContent]);

  const componentsMap: Record<MobileUserProfileDialogActiveContent, ReactNode> =
    {
      [USER_PROFILE_TABS.PERSONAL_DETAILS]: <PersonalDetailsContentMobile />,
      [USER_PROFILE_TABS.ACCOUNT_SETTINGS]: <AccountSettingsContentMobile />,
      [USER_PROFILE_TABS.RECENT_SESSIONS]: <RecentSessionsContentMobile />,
      [USER_PAYMENT_TABS.DEPOSIT]: (
        <Payments paymentType={PAYMENT_TYPES.DEPOSIT} />
      ),
      [USER_PAYMENT_TABS.WITHDRAW]: (
        <Payments paymentType={PAYMENT_TYPES.WITHDRAW} />
      ),
      [USER_PAYMENT_TABS.PAYMENT_ACCOUNTS]: <PaymentAccountsMobile />,
      [USER_PAYMENT_TABS.TRANSACTION_HISTORY]: <TransactionHistoryMobile />,
      [USER_PROFILE_NAVIGATION_TABS.BONUSES]: null,
      [USER_BONUSES_TABS.ACTIVE_BONUSES]: <ActiveBonusesContentMobile />,
      [USER_BONUSES_TABS.BONUS_HISTORY]: <BonusHistoryMobile />,
      [USER_PROFILE_NAVIGATION_TABS.MESSAGES]: null,
      [USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY]: (
        <TransactionHistoryMobile isAllTransactions />
      ),
    };

  useEffect(() => {
    dispatch(
      setBreadcrumbTrail([
        {
          label: getBreadcrumbBackLabel,
          onClickCallback: () => {
            dispatch(
              setMobileUserProfileDialogState(
                USER_PROFILE_DIALOG_MOBILE_STATE.SIDEBAR,
              ),
            );
          },
        },
        {
          label: localized(mobileUserProfileDialogActiveContent!),
        },
      ]),
    );
  }, [mobileUserProfileDialogActiveContent]);

  useEffect(() => {
    return () => {
      dispatch(setBreadcrumbTrail(trailRef.current));
    };
  }, []);

  return mobileUserProfileDialogActiveContent
    ? componentsMap[mobileUserProfileDialogActiveContent]
    : null;
};
