export function conditionalAdd<T, K extends keyof T>(
  obj: T,
  key: K,
  value?: T[K] | string | number,
): T {
  if (value !== undefined) {
    obj[key] = value as T[K];
  }

  return obj;
}

export const areTwoObjectsEqual = <T>(obj1: T, obj2: T): boolean => {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);

  return str1 === str2;
};

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};
