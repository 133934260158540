import React, { createElement } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { WaBetonly } from '~icons/shareSocialMedias';

interface WhatsappLoginButtonProps {
  setShowWhatsapp: (show: boolean) => void;
  showWhatsapp: boolean;
  register?: boolean;
}

const StyledWhatsappButton = styled(Button, {
  width: '100%',
  gap: '$2',
  alignItems: 'center',
  color: '$white',
  backgroundColor: '$success !important',
});

const StyledWhatsappText = styled(Text, {
  fontSize: '20px !important',
  lineHeight: '40px !important',
  fontWeight: 'bold !important',
  textShadow: '0px 2px 4px rgba(10, 15, 20, 0.40)',
});

export const WhatsappLoginButton = ({
  setShowWhatsapp,
  showWhatsapp,
  register = false,
}: WhatsappLoginButtonProps) => {
  const { localized } = useTranslation();

  return (
    <StyledWhatsappButton onClick={() => setShowWhatsapp(!showWhatsapp)}>
      <Box flexRow alignCenter>
        {createElement(WaBetonly)}
      </Box>
      <StyledWhatsappText>
        {localized(
          register ? 'signUp.registerViaWhatsApp' : 'signUp.usingWhatsApp',
        )}
      </StyledWhatsappText>
    </StyledWhatsappButton>
  );
};
