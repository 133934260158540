import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ChevronDownIcon } from '~icons';

export const StyledUserProfileBarContainer = styled(Box, {
  borderRadius: '$6',
  border: '1px solid $green',
  display: 'flex',
  alignItems: 'center',
  p: '$1',
  pr: 0,
  '@xs_sm': {
    p: '2px',
    pr: 0,
  },
});

export const StyledUserProfileInfoContainer = styled(Box, {
  height: '$6',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '$2',
  px: '$3',
  '&:active *': {
    '@xs_sm': {
      color: '$green',
    },
  },
  '@xs_sm': {
    gap: '$1',
    px: '$3',
  },
});

export const StyledChevron = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '&[data-state="open"]': {
    transform: 'rotate(180deg)',
    fill: '$white',
    stroke: '$white',
  },
  '&:hover path, &[data-state="open"] path': {
    fill: '$white',
    stroke: '$white',
  },
});

export const styledDepositButtonCSS = {
  width: '160px',
  lineHeight: '$24',
  py: '$2',
  borderRadius: '$4 !important',
  color: '$buttonTextColor',
  '& >  svg': {
    color: '$buttonTextColor !important',
    width: '2rem',
    height: '2rem',
  },
  '@xs_sm': {
    lineHeight: '2rem',
    fontSize: '$xxs',
    p: '$1 !important',
    height: '2rem',
  },
  '@xs': {
    width: 'unset',
  },
  '@sm': {
    width: 'unset',
  },
};
