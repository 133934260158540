import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLazyGetInfoPage } from '~api/content/contentQueries';
import { Box } from '~components/atoms/Box';
import { NotFoundPage } from '~components/atoms/NotFoundPage';
import { CHANNEL_TYPES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';

export const InfoPage = () => {
  const { pathname } = useLocation();
  const name = pathname.split('/').pop();

  const { isDesktop, isLargeDesktop } = useMedia();
  const {
    lazyGetInfoQuery,
    lazyGetInfoData,
    lazyGetInfoQueryError,
    lazyGetInfoQueryIsFetching,
  } = useLazyGetInfoPage();
  const htmlCode = lazyGetInfoData?.content || '';
  const { isMobileOrTablet } = useMedia();

  useEffect(() => {
    if (name) {
      lazyGetInfoQuery({
        name,
        channelType: isMobileOrTablet
          ? CHANNEL_TYPES.MOBILE
          : CHANNEL_TYPES.WEB,
      });
    }
  }, [name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  if ((lazyGetInfoQueryError && !lazyGetInfoQueryIsFetching) || !name) {
    return <NotFoundPage />;
  }

  return (
    <Box
      flexCol
      alignCenter
      justifyContentBetween
      css={{
        maxWidth: '100%',
        backgroundColor: '$blackMain',
        '*': {
          color: 'white',
        },
        color: 'white',
        padding: '$4 $5',
      }}
    >
      <Box
        css={{
          gap: '$3',
          flexDirection: 'row',
          alignItems: 'center',
          width: isDesktop || isLargeDesktop ? '1280px' : '100%',
          figure: {
            display: 'flex',
            justifyContent: 'center',
          },
          img: {
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
      </Box>
    </Box>
  );
};
