import { DependencyList, useEffect } from 'react';

type EffectFunction = () => void;

export const useDebouncedEffect = (
  fn: EffectFunction,
  deps: DependencyList,
  time: number,
) => {
  const dependencies = [...deps, fn, time];

  useEffect(() => {
    const timeout = setTimeout(fn, time);

    return () => {
      clearTimeout(timeout);
    };
  }, dependencies);
};
