import { Box } from '~components/atoms/Box';

import { navigationItems } from '../../constants/navigationItems';
import { NavigationItem } from '../NavigationItem';

export const UserProfileNavigation = () => {
  return (
    <Box
      flexCol
      alignStart
      gap={3}
      css={{
        p: '$3',
        borderRadius: '$8',
        backgroundColor: '$grayDark',
        color: '$grayMedium',
      }}
    >
      {navigationItems.map(({ icon, title, value }) => (
        <NavigationItem
          key={title}
          value={value}
          icon={icon}
          title={title}
          showActive
        />
      ))}
    </Box>
  );
};
