import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSS } from '@stitches/react';
import psl from 'psl';

import BetOnlyLogo from '~assets/logos/bet-only/bet-only-logo.png';
import SemaBetLogo from '~assets/logos/sema-bet/sema-bet-logo.png';
import { StyledLogo } from '~components/atoms/Logo/styled.components';
import { CLIENTS } from '~constants/clients';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { THEMES } from '~constants/ui';
import { useMedia } from '~hooks/useMedia';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch } from '~store';
import { getTheme } from '~utils/getTheme';

import { Box } from '../Box';
import { Text } from '../Typography';

export const clientsLogoMap: Record<CLIENTS, string | null> = {
  [CLIENTS.SEMA_BET]: SemaBetLogo,
  [CLIENTS.NONE]: BetOnlyLogo,
};

const containerCssMap: Record<string, CSS> = {
  sm: {
    p: '10px 26px',
  },
  md: {
    p: '$5 70px',
  },
  md2: {
    width: '100%',
    py: '$5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@xs_sm': {
      py: '$3',
    },
  },
  lg: {
    width: '100%',
    p: '38px 123px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const textPropsMap: Record<string, Record<string, string>> = {
  sm: {
    level: 'sm-3',
  },
  md: {
    level: 'xl-4',
  },
  md2: {
    level: '28-24',
  },
};

const textCssMap: Record<string, CSS> = {
  sm: {},
  md: {},
  lg: {
    fontSize: '$xxl2',
    lineHeight: '$24',
  },
};

interface LogoProps {
  size?: 'sm' | 'md' | 'md2' | 'lg';
  type?: 'header' | 'auth';
  css?: CSS;
}

export const Logo = memo(({ size = 'sm', type, css }: LogoProps) => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { updateQueryParams } = useRouterQuery();

  const tenant: CLIENTS =
    getTheme() === THEMES.SEMA_BET ? CLIENTS.SEMA_BET : CLIENTS.NONE;

  const onLogoClick = useCallback(() => {
    // eslint-disable-next-line import/no-named-as-default-member
    const pslData = psl.parse(window.location.hostname);

    if ('domain' in pslData && !window.location.host.includes('localhost')) {
      window.location.replace(('https://' + pslData.domain) as string);
    } else {
      navigate({ pathname: ROUTE_TAB_NAMES.BASE });
    }
  }, [isMobileOrTablet, updateQueryParams, dispatch, navigate]);

  const logoBackgroundImage = useMemo(() => {
    return clientsLogoMap[tenant]
      ? `url(${clientsLogoMap[tenant]})`
      : undefined;
  }, [tenant]);

  return logoBackgroundImage ? (
    <StyledLogo
      type={type}
      css={{
        backgroundImage: logoBackgroundImage,
        backgroundSize: 'contain',
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={onLogoClick}
    />
  ) : (
    <Box
      flexRow
      alignCenter
      justifyCenter
      css={{
        cursor: 'pointer',
        backgroundColor: '$grayDark',
        borderRadius: '$4',
        width: 'fit-content',
        ...containerCssMap[size],
        ...css,
      }}
      onClick={onLogoClick}
    >
      <Text
        textTransform="uppercase"
        css={{ ...textCssMap[size], width: '100%', textAlign: 'center' }}
        {...textPropsMap[size]}
      >
        logo
      </Text>
    </Box>
  );
});
