import React from 'react';
import * as TabsUI from '@radix-ui/react-tabs';
import { CSS, styled } from 'stitches.config';

import { useTranslation } from '~hooks/useTranslation';

export const StyledTabsRoot = styled(TabsUI.Root, {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    fullHeight: {
      true: {
        height: '100%',
      },
    },
  },
});

export const StyledTabsList = styled(TabsUI.List, {
  display: 'flex',
  gap: '$6',
});

export const StyledTabsTrigger = styled(TabsUI.Trigger, {
  all: 'unset',
  display: 'flex',
  color: '$white',
  lineHeight: '$28',
  '&:hover': { color: '$yellow', cursor: 'pointer' },
  '&[data-state="active"]': {
    color: '$yellow',
    fontWeight: '$medium',
    '&:hover': { cursor: 'default' },
  },
  '&.capitalized': {
    textTransform: 'uppercase',
  },
});

export const StyledTabsContent = styled(TabsUI.Content, {
  flexGrow: 1,
  outline: 'none',
  height: '100%',
});

interface TabsProps {
  children?: React.ReactNode;
  value?: string;
  onValueChange?: (value: string) => void;
  defaultTab?: string;
  fullHeight?: boolean;
}

export const Tabs = ({
  defaultTab,
  children,
  fullHeight,
  onValueChange,
}: TabsProps) => {
  return (
    <StyledTabsRoot
      fullHeight={fullHeight}
      defaultValue={defaultTab}
      onValueChange={onValueChange}
    >
      {children}
    </StyledTabsRoot>
  );
};

interface TabsListProps {
  tabs: string[] | number[];
  ariaLabel?: string;
  isCapitalized?: boolean;
  css?: CSS;
}

export const TabsList = ({
  tabs,
  ariaLabel,
  isCapitalized,
  css,
}: TabsListProps) => {
  const { localized } = useTranslation();

  return (
    <StyledTabsList aria-label={ariaLabel} css={css}>
      {tabs.map((tab) => (
        <StyledTabsTrigger
          value={tab.toString()}
          key={tab}
          className={isCapitalized ? 'capitalized' : ''}
        >
          {localized(tab.toString())}
        </StyledTabsTrigger>
      ))}
    </StyledTabsList>
  );
};
