import { ChangeEvent, useState } from 'react';

import { useSubscribeEmail } from '~api/email/emailMutations';
import { Box } from '~components/atoms/Box';
import { Logo } from '~components/atoms/Logo/Logo';
import { PureIconButton } from '~components/atoms/PureIconButton';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { SettingsIcon } from '~icons';
import { validateEmail } from '~utils/validateEmail';

import {
  StyledInput,
  StyledSubscribeButton,
  StyledSubscribeEmailContainer,
  StyledSubscribeText,
  StyledThanksForSubscription,
} from './styled.components';

interface SubscribeEmailProps {
  showSettings?: boolean;
}

export const SubscribeEmail = ({ showSettings }: SubscribeEmailProps) => {
  const { localized } = useTranslation();
  const { subscribeEmail, subscribeEmailIsLoading, subscribeEmailIsSuccess } =
    useSubscribeEmail();
  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const { isMobileOrTablet } = useMedia();

  const onSubscribeHandler = () => {
    const isValidated = validateEmail(email);

    if (!isValidated) {
      setIsEmailError(true);
    }

    if (email && isValidated) {
      subscribeEmail(email);
    }
  };

  const onEmailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEmailError(false);
    setEmail(e.target.value);
  };

  return (
    <Box
      flexCol
      alignCenter
      gap={4}
      css={{
        flexShrink: 0,
        width: isMobileOrTablet ? '100%' : '432px',
        position: 'relative',
      }}
    >
      {isMobileOrTablet && showSettings && (
        <PureIconButton
          css={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '$grayMedium',
            p: '$2',
            top: '-$1',
            right: '-6px',
          }}
        >
          <SettingsIcon width={20} height={20} />
        </PureIconButton>
      )}
      <Box
        fullWidth
        gap={1}
        justifyCenter
        css={{
          display: 'flex',
          flexDirection: isMobileOrTablet ? 'column' : 'row',
          alignItems: isMobileOrTablet ? 'center' : 'flex-start',
        }}
      >
        <StyledSubscribeText as="span">
          {localized('footer.stayInTouch')}
        </StyledSubscribeText>
        <StyledSubscribeText as="span">
          {localized('footer.subscribeToNewsletter')}
        </StyledSubscribeText>
      </Box>
      <StyledSubscribeEmailContainer>
        {!subscribeEmailIsSuccess ? (
          <>
            <StyledInput
              placeholder={localized('footer.emailAddress')}
              onChange={onEmailChangeHandler}
              css={{
                color: isEmailError ? '$red' : '$white',
                backgroundColor: 'transparent',
              }}
            />
            <StyledSubscribeButton
              onClick={onSubscribeHandler}
              disabled={!email}
              isLoading={subscribeEmailIsLoading}
            >
              {localized('footer.subscribe')}
            </StyledSubscribeButton>
          </>
        ) : (
          <StyledThanksForSubscription>
            <Text level="18-24" fontWeight="bold" textTransform="uppercase">
              {localized('footer.thankYouForSubscription')}
            </Text>
          </StyledThanksForSubscription>
        )}
      </StyledSubscribeEmailContainer>
      <Logo size="md2" />
    </Box>
  );
};
