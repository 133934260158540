import { CURRENCY_CODES } from '~constants/common';
import { UUID } from '~types/uuid';

export type AllowedPasswordCharacters = 1 | 2 | 4 | 8 | 15;

export interface SettingsPasswordRequirement {
  partnerId: UUID;
  minLength: number;
  maxLength: number | null;
  requireSymbol: boolean;
  requireNumber: boolean;
  requireUpperCase: boolean;
  requireLowerCase: boolean;
  allowedCharacters: AllowedPasswordCharacters;
  requirePasswordLength?: boolean;
  allowSymbol: boolean;
  allowUpperCase: boolean;
  allowLowerCase: boolean;
  allowNumber: boolean;
}

export interface MobileSetting {
  id: string;
  countryIsoCode: string;
  countryAlpha2Code: string;
  mobileCode: string;
}

export interface PaymentProvider {
  favoriteDepositAmounts: number[];
  maxDepositAmount: number;
  maxWithdrawAmount: number;
  minDepositAmount: number;
  minWithdrawAmount: number;
  paymentProvider: number;
}

export enum AUTH_FIELD_TYPE {
  USER_NAME = 1,
  EMAIL = 2,
  PHONE_NUMBER = 3,
}

export type PaymentProviders = PaymentProvider[];

export interface PaymentSettings {
  withdrawalLimitMin: number;
  withdrawalLimitMax: number;
  favoriteWithdrawAmounts: number[];
  depositLimitMin: number;
  depositLimitMax: number;
  favoriteDepositAmounts: number[];
}

export interface WebsiteSettingsResponse {
  maxWinAmount: number;
  maxStake: number;
  authBy: string | null;
  currency: CURRENCY_CODES;
  minimumAge: number;
  mobileSettings: MobileSetting[];
  paymentProviderSettings: PaymentProviders;
  paymentSettings: PaymentSettings;
  authField: AUTH_FIELD_TYPE;
  passwordRequirement: SettingsPasswordRequirement;
}
