import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  USER_BONUSES_TABS,
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
  USER_PROFILE_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';

export type MobileUserProfileDialogActiveContent =
  | USER_PROFILE_TABS
  | USER_BONUSES_TABS
  | USER_PAYMENT_TABS
  | Exclude<
      USER_PROFILE_NAVIGATION_TABS,
      | USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE
      | USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT
    >;

export interface MobileState {
  mobileHeaderHeight: number;
  isSwipeOpen: boolean;
  mobileHeaderInDialogHeight: number;
  mobileUserProfileDialogState: USER_PROFILE_DIALOG_MOBILE_STATE;
  mobileUserProfileDialogActiveContent: MobileUserProfileDialogActiveContent | null;
  locationScrollPositions: Record<string, number>;
}

const initialState: MobileState = {
  mobileHeaderHeight: 0,
  isSwipeOpen: false,
  mobileHeaderInDialogHeight: 0,
  mobileUserProfileDialogState: USER_PROFILE_DIALOG_MOBILE_STATE.SIDEBAR,
  mobileUserProfileDialogActiveContent: null,
  locationScrollPositions: {},
};

export const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setMobileHeaderHeight: (state, action: PayloadAction<number>) => {
      state.mobileHeaderHeight = action.payload;
    },
    setMobileHeaderInDialogHeight: (state, action: PayloadAction<number>) => {
      state.mobileHeaderInDialogHeight = action.payload;
    },
    setMobileUserProfileDialogState: (
      state,
      action: PayloadAction<USER_PROFILE_DIALOG_MOBILE_STATE>,
    ) => {
      state.mobileUserProfileDialogState = action.payload;
    },
    setMobileUserProfileDialogActiveContent: (
      state,
      action: PayloadAction<MobileUserProfileDialogActiveContent | null>,
    ) => {
      state.mobileUserProfileDialogActiveContent = action.payload;
    },
    addLocationScrollPosition: (
      state,
      action: PayloadAction<{ path: string; scroll: number }>,
    ) => {
      const { path, scroll } = action.payload;

      state.locationScrollPositions[path] = scroll;
    },
    removeLocationScrollPosition: (state, action: PayloadAction<string>) => {
      delete state.locationScrollPositions[action.payload];
    },
  },
});

export const {
  setMobileHeaderHeight,
  setMobileHeaderInDialogHeight,
  setMobileUserProfileDialogState,
  setMobileUserProfileDialogActiveContent,
  addLocationScrollPosition,
  removeLocationScrollPosition,
} = mobileSlice.actions;

export default mobileSlice.reducer;
