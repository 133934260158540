import { authApi } from '~api/auth/authApi';
import { REFRESH_TOKEN_SECONDS_BEFORE_EXPIRATION } from '~constants/auth';
import { store } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { logout } from '~store/slices/userSlice';
import { COOKIES_NAMES, getCookie } from '~utils/cookies';

let refreshInterval: NodeJS.Timeout | null = null;

const refreshTokenInitiate = () => {
  const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN)!;
  const accessToken = getCookie(COOKIES_NAMES.ACCESS_TOKEN)!;

  try {
    store.dispatch(
      authApi.endpoints.refreshToken.initiate({
        refreshToken,
        accessToken,
      }),
    );
  } catch (err) {
    console.error('Failed to refresh token', err);
  }
};

export const stopTokenRefreshScheduler = () => {
  if (refreshInterval) {
    clearInterval(refreshInterval);
    refreshInterval = null;
  }
};

export const startTokenRefreshScheduler = () => {
  // Clear any previous interval
  stopTokenRefreshScheduler();

  const refreshTokenExpirationISO = getCookie(
    COOKIES_NAMES.ACCESS_TOKEN_EXPIRATION,
  );
  const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN)!;

  if (!refreshToken) {
    store.dispatch(logout());
    store.dispatch(closeDialog());
  }

  if (refreshTokenExpirationISO) {
    const refreshTokenExpiration = new Date(refreshTokenExpirationISO);
    const currentTime = new Date();
    const timeUntilExpiration =
      refreshTokenExpiration.getTime() - currentTime.getTime();

    // if access token isn't expired yet
    if (timeUntilExpiration > 0) {
      // Schedule the refresh just before the refresh token expires
      refreshInterval = setTimeout(
        () => refreshTokenInitiate(),
        timeUntilExpiration - REFRESH_TOKEN_SECONDS_BEFORE_EXPIRATION * 1000,
      );
      // if access token is expired but refresh token isn't
    } else {
      refreshTokenInitiate();
    }
  }
};
