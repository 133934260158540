import { useEffect, useState } from 'react';

import {
  BaseMediaQueries,
  combinedMediaQueries,
  MEDIA_QUERIES,
  mediaQueries,
  MediaQueryResults,
} from '~constants/mediaQueries';

export const useMedia = (): MediaQueryResults => {
  const [isSmallMobile, setSmallMobile] = useState<boolean>(
    () => window.matchMedia(mediaQueries[MEDIA_QUERIES.XXS]).matches,
  );
  const [isMobile, setMobile] = useState<boolean>(
    () => window.matchMedia(mediaQueries[MEDIA_QUERIES.XS]).matches,
  );
  const [isTablet, setTablet] = useState<boolean>(
    () => window.matchMedia(mediaQueries[MEDIA_QUERIES.SM]).matches,
  );
  const [isLaptop, setLaptop] = useState<boolean>(
    () => window.matchMedia(mediaQueries[MEDIA_QUERIES.MD]).matches,
  );
  const [isDesktop, setDesktop] = useState<boolean>(
    () => window.matchMedia(mediaQueries[MEDIA_QUERIES.LG]).matches,
  );
  const [isLargeDesktop, setLargeDesktop] = useState<boolean>(
    () => window.matchMedia(mediaQueries[MEDIA_QUERIES.XL]).matches,
  );

  useEffect(() => {
    const handlers: Record<BaseMediaQueries, (e: MediaQueryListEvent) => void> =
      {
        [MEDIA_QUERIES.XXS]: (e) => setSmallMobile(e.matches),
        [MEDIA_QUERIES.XS]: (e) => setMobile(e.matches),
        [MEDIA_QUERIES.SM]: (e) => setTablet(e.matches),
        [MEDIA_QUERIES.MD]: (e) => setLaptop(e.matches),
        [MEDIA_QUERIES.LG]: (e) => setDesktop(e.matches),
        [MEDIA_QUERIES.XL]: (e) => setLargeDesktop(e.matches),
      };

    Object.values(MEDIA_QUERIES).forEach((key) => {
      if (Object.keys(combinedMediaQueries).includes(key)) {
        return;
      }

      const mql = window.matchMedia(mediaQueries[key as BaseMediaQueries]);

      mql.addEventListener('change', handlers[key as BaseMediaQueries]);

      return () => {
        mql.removeEventListener('change', handlers[key as BaseMediaQueries]);
      };
    });
  }, []);

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isMobileOrTablet: isMobile || isTablet,
    isLaptopOrDesktop: isLaptop || isDesktop,
    isLargeDesktop,
  };
};
