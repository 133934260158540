import { useMemo } from 'react';
import zxcvbn from 'zxcvbn';

import { useAppSelector } from '~store';

import { useTranslation } from './useTranslation';

export interface PasswordCharacteristics {
  hasMinimalLength: boolean;
  haxMaximumLength: boolean;
  hasLowerCase: boolean;
  hasUpperCase: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
  requirePasswordLength?: boolean;
}

export type PartialPasswordCharacteristics = Partial<PasswordCharacteristics>;

export type PasswordCharacteristicsItem = {
  label: string;
  satisfies: boolean;
};

const enum SCORES {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
}

export const enum SCORE_WORDS {
  WEAK = 'weak',
  AVERAGE = 'average',
  STRONG = 'strong',
  EXCELLENT = 'excellent',
}

const scoreToStrengthMap = {
  [SCORES.ZERO]: SCORE_WORDS.WEAK,
  [SCORES.ONE]: SCORE_WORDS.WEAK,
  [SCORES.TWO]: SCORE_WORDS.AVERAGE,
  [SCORES.THREE]: SCORE_WORDS.STRONG,
  [SCORES.FOUR]: SCORE_WORDS.EXCELLENT,
};

export const checkPasswordStrength = (password: string) => {
  const result = zxcvbn(password);

  return scoreToStrengthMap[result.score];
};

export const usePasswordCharacteristics = (password: string) => {
  const { localized } = useTranslation();
  const { passwordRequirement } = useAppSelector((state) => state.settings);

  const getPasswordCharacteristics = () => {
    const { minLength, maxLength } = passwordRequirement || {};

    const characteristics: PartialPasswordCharacteristics = {};

    if (minLength > 0 && minLength === maxLength) {
      characteristics.requirePasswordLength = password.length === minLength;
    } else {
      if (minLength) {
        characteristics.hasMinimalLength = password.length >= minLength;
      }

      if (maxLength) {
        characteristics.haxMaximumLength = password.length <= maxLength;
      }
    }

    characteristics.hasLowerCase = /[a-z]/.test(password);
    characteristics.hasUpperCase = /[A-Z]/.test(password);
    characteristics.hasNumber = /\d/.test(password);
    characteristics.hasSymbol = /[!@#$%^&*()_+\-=[\]{}|;':",.<>/?]/.test(
      password,
    );

    return characteristics;
  };

  const passwordCharacteristics = getPasswordCharacteristics();

  const localizedCharacteristicsItems: PasswordCharacteristicsItem[] =
    useMemo(() => {
      const requirements = [];

      if (!passwordRequirement) {
        return [];
      }

      if (
        !passwordRequirement.allowUpperCase &&
        !passwordRequirement.allowLowerCase
      ) {
        requirements.push({
          label: localized('password.notAllowUpperCaseAndLowerCase'),
          satisfies:
            !passwordCharacteristics.hasUpperCase &&
            !passwordCharacteristics.hasLowerCase,
        });
      } else {
        if (passwordRequirement.requireLowerCase) {
          requirements.push({
            label: localized('password.hasLowerCase'),
            satisfies: !!passwordCharacteristics.hasLowerCase,
          });
        }

        if (passwordRequirement.requireUpperCase) {
          requirements.push({
            label: localized('password.hasUpperCase'),
            satisfies: !!passwordCharacteristics.hasUpperCase,
          });
        }
      }

      if (passwordRequirement.allowSymbol) {
        if (passwordRequirement.requireSymbol) {
          requirements.push({
            label: localized('password.hasSymbol'),
            satisfies: Boolean(passwordCharacteristics.hasSymbol),
          });
        }
      } else {
        requirements.push({
          label: localized('password.notAllowSymbol'),
          satisfies: !passwordCharacteristics.hasSymbol,
        });
      }

      if (passwordRequirement.allowNumber) {
        if (passwordRequirement.requireNumber) {
          requirements.push({
            label: localized('password.hasNumber'),
            satisfies: Boolean(passwordCharacteristics.hasNumber),
          });
        }
      } else {
        requirements.push({
          label: localized('password.notAllowNumber'),
          satisfies: !passwordCharacteristics.hasNumber,
        });
      }

      const { minLength, maxLength } = passwordRequirement || {};

      if (minLength > 0 && minLength === maxLength) {
        requirements.push({
          label: localized('password.requirePasswordLength', {
            n: passwordRequirement.minLength,
          }),
          satisfies: !!passwordCharacteristics.requirePasswordLength,
        });
      } else {
        if (passwordRequirement.minLength) {
          requirements.push({
            label: localized('password.hasMinimalLength', {
              n: passwordRequirement.minLength,
            }),
            satisfies: !!passwordCharacteristics.hasMinimalLength,
          });
        }

        if (passwordRequirement.maxLength) {
          requirements.push({
            label: localized('password.hasMaximumLength', {
              n: passwordRequirement.maxLength,
            }),
            satisfies: !!passwordCharacteristics.haxMaximumLength,
          });
        }
      }

      return requirements;
    }, [passwordCharacteristics, passwordRequirement, localized]);

  const isValidPassword = useMemo(() => {
    const {
      allowUpperCase,
      allowLowerCase,
      allowNumber,
      allowSymbol,
      maxLength,
      minLength,
    } = passwordRequirement || {};
    const { hasUpperCase, hasLowerCase, hasNumber, hasSymbol } =
      passwordCharacteristics;

    if (
      password.length < minLength ||
      (maxLength && password.length > maxLength)
    ) {
      return false;
    }

    if (!allowUpperCase && hasUpperCase) {
      return false;
    }

    if (!allowLowerCase && hasLowerCase) {
      return false;
    }

    if (!allowNumber && hasNumber) {
      return false;
    }

    if (!allowSymbol && hasSymbol) {
      return false;
    }

    return true;
  }, [passwordCharacteristics, passwordRequirement, password]);

  return {
    isValidPassword,
    passwordCharacteristics,
    passwordRequirements: passwordRequirement,
    passwordStrength: checkPasswordStrength(password),
    localizedCharacteristicsItems,
  };
};
