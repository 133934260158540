import { useEffect, useState } from 'react';

import { useBankDepositFlutterwave } from '~api/flutterwave/flutterwaveMutations';
import { useBankDepositPaystack } from '~api/paystack/paystackMutations';
import {
  useRelworxDeposit,
  useRelworxWithdraw,
} from '~api/relworx/relworxMutations';
import { useMakeWithdrawal } from '~api/transaction/transactionMutations';
import { SavedBank, WithdrawalPayload } from '~api/transaction/types';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { CURRENCY_CODES } from '~constants/common';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_PROVIDER,
} from '~constants/payments';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNewPaymentMethodFormStatus,
  setIsLoading,
  setTransactionErrorStatusCode,
  setTransactionId,
  setTransactionStatus,
} from '~store/slices/paymentsSlice';
import { TRANSACTION_STATUS } from '~types/transactions';

export const useBankSubmitButton = () => {
  const dispatch = useAppDispatch();
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { bankDepositPaystackMutation, bankDepositPaystackIsLoading } =
    useBankDepositPaystack();
  const { bankDepositFlutterwaveMutation, bankDepositFlutterwaveIsLoading } =
    useBankDepositFlutterwave();
  const { relworxDepositMutation, relworxDepositIsLoading } =
    useRelworxDeposit();
  const { relworxWithdrawMutation, relworxWithdrawIsLoading } =
    useRelworxWithdraw();
  const { makeWithdrawalMutation } = useMakeWithdrawal();
  const [isDisabled, setIsDisabled] = useState(false);
  const { profile } = useAppSelector((state) => state.userState);
  const {
    addingNewCard,
    selectedPaymentMethod,
    activePaymentTab,
    paymentMethodAmount,
    cardHolderEmail,
    bankCode,
    bankNumber,
    paymentSettings,
    saveCard,
    selectedNigeriaDepositProvider,
    selectedNigeriaMethod,
  } = useAppSelector((state) => state.payments);
  const { currency, paymentSettings: paymentPartnerSettings } = useAppSelector(
    (state) => state.settings,
  );

  const isUganda = currency === CURRENCY_CODES.UGX;

  const getMinValue = () => {
    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      if (paymentSettings) {
        const { minDepositAmount } = paymentSettings;

        if (minDepositAmount) return minDepositAmount;
      }

      if (paymentPartnerSettings) {
        const { depositLimitMin } = paymentPartnerSettings;

        if (depositLimitMin !== null) return depositLimitMin;
      }
    }

    if (paymentSettings) {
      const { minWithdrawAmount } = paymentSettings;

      if (minWithdrawAmount !== null) return minWithdrawAmount;
    }

    if (paymentPartnerSettings) {
      const { withdrawalLimitMin } = paymentPartnerSettings;

      if (withdrawalLimitMin !== null) return withdrawalLimitMin;
    }

    return 0;
  };

  const getMaxValue = () => {
    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      if (paymentSettings) {
        const { maxDepositAmount } = paymentSettings;

        if (maxDepositAmount) return maxDepositAmount;
      }

      if (paymentPartnerSettings) {
        const { depositLimitMax } = paymentPartnerSettings;

        if (depositLimitMax !== null) return depositLimitMax;
      }
    }

    if (paymentSettings) {
      const { maxWithdrawAmount } = paymentSettings;

      if (maxWithdrawAmount !== null) return maxWithdrawAmount;
    }

    if (paymentPartnerSettings) {
      const { withdrawalLimitMax } = paymentPartnerSettings;

      if (withdrawalLimitMax !== null) return withdrawalLimitMax;
    }

    return Infinity;
  };

  const handleDeposit = async () => {
    try {
      const email = profile?.email || cardHolderEmail;

      if (!isUganda) {
        if (!selectedNigeriaMethod) return;
        const typesMap: Record<string, number> = {
          card: 1,
          bank: 2,
          transfer: 3,
          qr: 4,
          ussd: 5,
          eNaira: 6,
        };

        const payload = {
          email,
          amount: +paymentMethodAmount,
          type: typesMap[selectedNigeriaMethod] || 1,
        };

        if (selectedNigeriaDepositProvider === 'flutterwave') {
          const response =
            await bankDepositFlutterwaveMutation(payload).unwrap();
          const {
            data: { link },
          } = response;

          window.location.href = link;
        } else {
          const response = await bankDepositPaystackMutation(payload).unwrap();
          const {
            data: { link },
          } = response;

          window.location.href = link;
        }
      } else {
        const response = await relworxDepositMutation({
          amount: +paymentMethodAmount,
          currency: CURRENCY_CODES.UGX,
        });

        if ('data' in response) {
          const { transactionId, success } = response.data;

          dispatch(setTransactionId(transactionId));

          if (success) {
            dispatch(setTransactionStatus(TRANSACTION_STATUS.PENDING));
          } else {
            dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
          }
        } else {
          dispatch(setTransactionId('notProcessed'));
          dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
        }

        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
          ),
        );
        dispatch(setIsLoading(false));
      }
    } catch (e) {
      dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
      dispatch(
        setAddingNewPaymentMethodFormStatus(
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
        ),
      );
      dispatch(setIsLoading(false));
      console.error('Deposit failed: ' + e);
    }
  };

  const handleWithdraw = async () => {
    try {
      if (activePaymentTab !== PAYMENT_PROVIDER.RELWORX) {
        let payload: WithdrawalPayload;

        if (selectedPaymentMethod) {
          const { bankId, accountNumber, provider } =
            selectedPaymentMethod as SavedBank;

          payload = {
            amount: +paymentMethodAmount,
            accountNumber,
            provider,
            bankId,
            shouldSaveBank: false,
          } as unknown as WithdrawalPayload;
        } else {
          payload = {
            amount: +paymentMethodAmount,
            accountNumber: bankNumber,
            bankId: +bankCode,
            provider: activePaymentTab,
            shouldSaveBank: saveCard,
          } as unknown as WithdrawalPayload;
        }

        const { transactionId } =
          await makeWithdrawalMutation(payload).unwrap();

        if (transactionId) {
          dispatch(setTransactionId(transactionId));
          dispatch(setTransactionStatus(TRANSACTION_STATUS.PENDING));
        } else {
          dispatch(setTransactionId('notProcessed'));
          dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
        }

        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
          ),
        );

        dispatch(setIsLoading(false));
      } else if (activePaymentTab === PAYMENT_PROVIDER.RELWORX) {
        const response = await relworxWithdrawMutation({
          amount: +paymentMethodAmount,
          currency: CURRENCY_CODES.UGX,
        });

        if ('data' in response) {
          const { transactionId, success } = response.data;

          if (success) {
            dispatch(setTransactionId(transactionId));
            dispatch(setTransactionStatus(TRANSACTION_STATUS.PENDING));
          } else {
            dispatch(setTransactionId('notProcessed'));
            dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
          }
        } else {
          dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
        }

        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
          ),
        );
        dispatch(setIsLoading(false));
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ('status' in e) {
        dispatch(setTransactionErrorStatusCode(e.status as number));
      }

      dispatch(setTransactionId('notProcessed'));
      dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
      dispatch(
        setAddingNewPaymentMethodFormStatus(
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
        ),
      );
      dispatch(setIsLoading(false));
      console.error('Withdraw failed: ' + e);
    }
  };

  const handleBankChangeClick = async () => {
    dispatch(setIsLoading(true));
    dispatch(setTransactionId(''));
    dispatch(setTransactionErrorStatusCode(0));
    if (contentTab === USER_PAYMENT_TABS.WITHDRAW) {
      await handleWithdraw();
    } else {
      await handleDeposit();
    }
  };

  useEffect(() => {
    const isDeposit = contentTab === USER_PAYMENT_TABS.DEPOSIT;

    if (!addingNewCard && !selectedPaymentMethod && !isDeposit) {
      setIsDisabled(true);

      return;
    }

    if (
      +paymentMethodAmount < getMinValue() ||
      +paymentMethodAmount > getMaxValue()
    ) {
      setIsDisabled(true);

      return;
    }

    if (activePaymentTab !== PAYMENT_PROVIDER.RELWORX && !isDeposit) {
      if ((!bankCode || !bankNumber) && !selectedPaymentMethod) {
        setIsDisabled(true);

        return;
      }
    }

    setIsDisabled(false);
  }, [
    contentTab,
    paymentSettings,
    addingNewCard,
    selectedPaymentMethod,
    paymentMethodAmount,
    activePaymentTab,
    profile,
    activePaymentTab,
    cardHolderEmail,
    bankCode,
    bankNumber,
    selectedNigeriaMethod,
    selectedNigeriaDepositProvider,
  ]);

  return {
    isDeposit: contentTab === USER_PAYMENT_TABS.DEPOSIT,
    isLoading:
      bankDepositPaystackIsLoading ||
      bankDepositFlutterwaveIsLoading ||
      relworxDepositIsLoading ||
      relworxWithdrawIsLoading,
    isDisabled,
    handleBankChangeClick,
  };
};
