import React, { useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { CSS } from '@stitches/react';

import { Game } from '~api/games/types';
import { Text } from '~components/atoms/Typography';
import { GameActions } from '~components/molecules/Games/GameCard/GameActions';
import { StyledGameContainer } from '~components/molecules/Games/GamesGrid/styled.components';

export type GameCardVariant = 'xs' | 'xl' | 'sm' | undefined;

export interface GameCardProps {
  game: Game;
  colSpan?: number;
  isFromSearch?: boolean;
  customStyles?: CSS;
  noAspectRatio?: boolean;
  variant?: GameCardVariant;
  sliderCard?: boolean;
}

export const GameCard = ({
  game,
  colSpan = 1,
  isFromSearch,
  noAspectRatio,
  variant,
  sliderCard,
}: GameCardProps) => {
  const [showGameActions, setShowGameActions] = useState(false);
  const { id, name, smallImageUrl, largeImageUrl } = game;

  const imgUrl = colSpan === 1 ? smallImageUrl : largeImageUrl;

  return (
    <StyledGameContainer
      key={id}
      data-col={colSpan}
      onMouseEnter={() => setShowGameActions(true)}
      onMouseLeave={() => setShowGameActions(false)}
      onClick={() => !isBrowser && setShowGameActions(!showGameActions)}
      noAspectRatio={noAspectRatio}
      sliderCard={sliderCard}
    >
      {imgUrl ? (
        <img src={imgUrl} alt={name} loading="lazy" />
      ) : (
        <Text>{name}</Text>
      )}
      {showGameActions && (
        <GameActions
          variant={variant}
          game={game}
          colSpan={colSpan}
          isFromSearch={isFromSearch}
        />
      )}
    </StyledGameContainer>
  );
};
