import { createElement } from 'react';
import { styled } from 'stitches.config';

export const Label = styled(
  ({ ...props }: JSX.IntrinsicElements['label']) => {
    return createElement('label', {
      ...props,
    });
  },
  {
    fontWeight: '$normal',
    fontSize: '$16',
    lineHeight: '$24',
    display: 'block',
    color: '$white',
    mb: '$1',
    marginLeft: '$3',
    variants: {
      noMargin: {
        true: {
          margin: 0,
        },
      },
      smallFont: {
        true: {
          fontSize: '$xxs',
        },
      },
      inheritColors: {
        true: {
          color: 'inherit',
        },
      },
    },
  },
);

Label.displayName = 'Label';
