import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  USER_BONUSES_TABS,
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
  USER_PROFILE_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog/constants';

export type ContentTabType =
  | USER_PROFILE_TABS
  | USER_PAYMENT_TABS
  | USER_BONUSES_TABS
  | null;

export interface PersonalDetailsState {
  mainTab: USER_PROFILE_NAVIGATION_TABS | null;
  contentTab: ContentTabType;
}

export interface SetPersonalDetailsState {
  mainTab?: USER_PROFILE_NAVIGATION_TABS | null;
  contentTab?: ContentTabType;
}

const initialState: PersonalDetailsState = {
  mainTab: null,
  contentTab: null,
};

export const personalDetailsSlice = createSlice({
  name: 'personalDetails',
  initialState,
  reducers: {
    setTabsState: (state, action: PayloadAction<SetPersonalDetailsState>) => {
      const { mainTab, contentTab } = action.payload;

      if (mainTab) {
        state.mainTab = mainTab;
      }

      if (contentTab) {
        state.contentTab = contentTab;
      }
    },
    setMainTab: (
      state,
      action: PayloadAction<{
        tab: USER_PROFILE_NAVIGATION_TABS | null;
        subtab?: USER_PROFILE_TABS | USER_PAYMENT_TABS;
      }>,
    ) => {
      const { tab, subtab } = action.payload;

      state.mainTab = tab;
      if (tab === USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT) {
        state.contentTab = subtab || USER_PAYMENT_TABS.WITHDRAW;
      }

      if (tab === USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE) {
        state.contentTab = subtab || USER_PROFILE_TABS.PERSONAL_DETAILS;
      }
    },
    setContentTab: (state, action: PayloadAction<ContentTabType>) => {
      state.contentTab = action.payload;
    },
    resetPersonalDetailsState: (state) => {
      state.mainTab = null;
      state.contentTab = null;
    },
  },
});

export const {
  setTabsState,
  setMainTab,
  setContentTab,
  resetPersonalDetailsState,
} = personalDetailsSlice.actions;

export default personalDetailsSlice.reducer;
