import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AUTH_FIELD_TYPE, WebsiteSettingsResponse } from '~api/partner/types';
import { SUPPORTED_COUNTRY_CODE } from '~components/molecules/Authentication/types';
import { CURRENCY_CODES, LEGAL_AGE } from '~constants/common';

export type SettingsState = WebsiteSettingsResponse;

const initialState: SettingsState = {
  authBy: '',
  currency: null as unknown as CURRENCY_CODES,
  minimumAge: LEGAL_AGE,
  mobileSettings: [],
  maxWinAmount: 0,
  paymentProviderSettings: [],
  paymentSettings: {
    withdrawalLimitMin: 0,
    withdrawalLimitMax: 0,
    favoriteWithdrawAmounts: [],
    depositLimitMin: 0,
    depositLimitMax: 0,
    favoriteDepositAmounts: [],
  },
  maxStake: 0,
  passwordRequirement: {
    partnerId: '',
    minLength: 0,
    maxLength: 0,
    requireSymbol: false,
    requireNumber: false,
    requireUpperCase: false,
    requireLowerCase: false,
    allowNumber: false,
    allowSymbol: false,
    allowUpperCase: false,
    allowLowerCase: false,
    allowedCharacters: 15,
  },
  authField: AUTH_FIELD_TYPE.USER_NAME,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setWebsiteSettings: (
      state,
      action: PayloadAction<WebsiteSettingsResponse>,
    ) => {
      const {
        authBy,
        mobileSettings,
        minimumAge,
        passwordRequirement,
        authField,
        maxWinAmount,
        paymentSettings,
        maxStake,
      } = action.payload;

      const currencyCountryMap: {
        [key in SUPPORTED_COUNTRY_CODE]: CURRENCY_CODES;
      } = {
        [SUPPORTED_COUNTRY_CODE.NGN]: CURRENCY_CODES.NGN,
        [SUPPORTED_COUNTRY_CODE.UGX]: CURRENCY_CODES.UGX,
        [SUPPORTED_COUNTRY_CODE.MZ]: CURRENCY_CODES.MZ,
        [SUPPORTED_COUNTRY_CODE.UA]: CURRENCY_CODES.UA,
        [SUPPORTED_COUNTRY_CODE.TZ]: CURRENCY_CODES.TZ,
      };

      state.maxWinAmount = maxWinAmount;
      state.maxStake = maxStake;
      state.authBy = authBy;
      state.mobileSettings = mobileSettings;
      state.paymentSettings = paymentSettings;
      state.minimumAge = minimumAge || LEGAL_AGE;
      state.passwordRequirement = passwordRequirement;
      const { countryAlpha2Code } = mobileSettings[0] || {};

      if (countryAlpha2Code) {
        state.currency =
          currencyCountryMap[countryAlpha2Code as SUPPORTED_COUNTRY_CODE];
      }

      state.authField = authField;
    },
  },
});

export const selectGamesState = (state: { settings: SettingsState }) =>
  state.settings;

export const { setWebsiteSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
