import * as SelectUI from '@radix-ui/react-select';
import { styled } from 'stitches.config';

import { ellipsis } from '~utils/cssUtils';
import { zIndex } from '~utils/zIndex';

export const StyledSelectUIValue = styled(SelectUI.Value, {
  ...ellipsis,
});

export const StyledBaseSelectTrigger = styled(SelectUI.SelectTrigger, {
  all: 'unset',
  pointerEvents: 'auto',
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '$6',
  p: 0,
  fontSize: '$sm',
  lineHeight: '$32',
  gap: '$2',
  backgroundColor: '$grayDarkMain2',
  color: '$grayMedium',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '$inputBorderFocus',
  },
  '&:hover svg': {
    fill: '$white',
  },
  '&[data-state="open"]': {
    boxShadow: '$inputBorderFocus',
  },
  '& .trigger': {
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    display: 'flex',
    transform: 'rotate(180deg)',
    color: '$grayMedium',
  },
  '&:hover .trigger': {
    color: '$white',
  },
  '&[data-state="open"] .trigger': {
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    transform: 'rotate(0deg)',
    color: '$white',
  },
  '&[data-placeholder]': {
    color: '$grayMedium',
  },
  variants: {
    isCombinedWithContent: {
      true: {
        cursor: 'pointer',
        backgroundColor: '$gray',
        boxShadow: 'unset !important',
        pl: '2px',
        '&:hover': {
          boxShadow: 'unset !important',
        },
      },
    },
    centeredValue: {
      true: {
        justifyContent: 'center',
      },
    },
  },
});

export const StyledSelectTriggerContent = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: '$2',
  paddingRight: '28px',
  gap: '$2',
  ...ellipsis,
  '& > span': {
    ...ellipsis,
  },
  variants: {
    centeredValue: {
      true: {
        justifyContent: 'center',
      },
    },
    isValueSelected: {
      true: {
        color: '$white',
      },
    },
  },
});

export const StyledSelectContent = styled(SelectUI.Content, {
  overflow: 'hidden',
  backgroundColor: '$selectContentBgColor',
  boxShadow: '$inputBorderFocus',
  p: '$3 $3 $3 $4',
  borderRadius: '$6',
  width: 'var(--radix-select-trigger-width)',
  zIndex: zIndex.dropdown,
  variants: {
    isCustomContentWidth: {
      true: {
        width: 'unset',
      },
    },
    isCombinedWithContent: {
      true: {
        backgroundColor: '$gray',
        marginLeft: '-1px !important',
        boxShadow: 'unset !important',
        border: '1px solid $gray1',
        borderTop: 'none',
      },
    },
    isCustomChildren: {
      true: {
        backgroundColor: '$gray',
        p: '$2',
        borderRadius: '$8',
      },
    },
    noContentXPadding: {
      true: {
        px: '0',
      },
    },
  },
});

export const StyledSelectViewport = styled(SelectUI.Viewport, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  maxHeight: '200px',
  overflowY: 'auto',
  variants: {
    isCombinedWithContent: {
      true: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        p: '$3 $3 $2',
        '&:before': {
          content: '',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderTop: '1px solid $gray',
        },
      },
    },
    isNoMaxHeight: {
      true: {
        maxHeight: 'unset',
      },
    },
    noContentPadding: {
      true: {
        p: '0',
        gap: '$3',
      },
    },
    withSeparators: {
      true: {
        gap: '$2',
        px: '$3',
      },
    },
  },
});

export const StyledItem = styled(SelectUI.Item, {
  fontSize: '$sm',
  lineHeight: '$20',
  color: '$white',
  borderRadius: '$6',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  userSelect: 'none',
  outline: 'none',
  '&[data-highlighted][data-state="unchecked"]': {
    color: '$grayMedium',
    cursor: 'pointer',
  },
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '$6',
  backgroundColor: '$grayMedium',
  color: '$black',
  cursor: 'default',
};

export const StyledSelectScrollUpButton = styled(
  SelectUI.ScrollUpButton,
  scrollButtonStyles,
);

export const StyledSelectScrollDownButton = styled(
  SelectUI.ScrollDownButton,
  scrollButtonStyles,
);

export const StyledItemIndicator = styled(SelectUI.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: '14px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledSelectIcon = styled(SelectUI.SelectIcon, {
  position: 'absolute',
  right: '$2',
  '@xs_sm': {
    right: '$3',
  },
});
