import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import Cookies from 'js-cookie';
import { Middleware } from 'redux';

import {
  SIGNAL_R_SOCKET_ENDPOINTS,
  SIGNAL_R_SOCKET_NAMES,
  SOCKET_URLS,
} from '~constants/signalR';
import {
  setAuthedSocketConnected,
  startSignalRAuthedSocket,
} from '~store/slices/signalRSocketsSlice';
import { COOKIES_NAMES } from '~utils/cookies';

const getSocketUrl = (name: SIGNAL_R_SOCKET_NAMES) => {
  const url = SOCKET_URLS[name];
  const path = SIGNAL_R_SOCKET_ENDPOINTS[name];

  return url + path;
};

const SocketMiddleware: Middleware = (store) => {
  let socket: import('@microsoft/signalr').HubConnection | undefined;

  return (next) => async (action) => {
    if (startSignalRAuthedSocket.match(action)) {
      if (action.payload.length) {
        for (const socketName of action.payload) {
          const options = {
            transport: HttpTransportType.WebSockets,
            skipNegotiation: true,
            accessTokenFactory: () => {
              return Cookies.get(COOKIES_NAMES.ACCESS_TOKEN) || '';
            },
          };

          socket = new HubConnectionBuilder()
            .withUrl(getSocketUrl(socketName), options)
            .build();

          await socket.start();
          store.dispatch(setAuthedSocketConnected({ socketName, socket }));
        }
      }
    }

    next(action);
  };
};

export default SocketMiddleware;
